import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import moment from "moment";
import "moment/locale/nl";
import dayjs from "dayjs";

import ReactPaginate from "react-paginate";
import { RotateLoader } from "react-spinners";

import {
  FaSyncAlt,
  FaTimes,
  FaExternalLinkAlt,
  FaExclamationTriangle,
  FaPrint,
} from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { IoFilter } from "react-icons/io5";
import { BsShopWindow, BsCalendarDate } from "react-icons/bs";
import { AiOutlineSortAscending } from "react-icons/ai";
import { AuthContext } from "../../context/main/auth-context";
import "../../css/orders/orders.css";

import { downloadBlobFile } from "../../utils/download-blob-file";

import flagNetherlands from "../../images/main/flag-netherlands.svg";
import flagBelgium from "../../images/main/flag-belgium.svg";
import worldGlobe from "../../images/main/world-globe.svg";
import postnlBolMailbox from "../../images/shipping/POSTNL-BOL-MAILBOX.png";
import postnlBolParcel from "../../images/shipping/POSTNL-BOL-PARCEL.png";
import postnlBolParcelPickup from "../../images/shipping/POSTNL-BOL-PARCEL-PICKUP.png";
import postnlBolParcelNight from "../../images/shipping/POSTNL-BOL-PARCEL-NIGHT.png";
import dhlBolMailbox from "../../images/shipping/DHL-BOL-MAILBOX.png";
import dhlBolParcel from "../../images/shipping/DHL-BOL-PARCEL.png";
import dhlBolParcelPickup from "../../images/shipping/DHL-BOL-PARCEL-PICKUP.png";
import dhlBolParcelNight from "../../images/shipping/DHL-BOL-PARCEL-NIGHT.png";

import getAllOpenOrdersFbrDbb from "../../components/bol/get-all-open-orders-fbr-dbb";
import getMultipleShippingLabelsPdf from "../../components/bol/get-multiple-shipping-labels-pdf";
import OrdersHeader from "../../components/orders/orders-header";
import refetchOpenOrders from "../../components/orders/refetch-all-open-orders";
import OrderListItemOpen from "../../components/orders/order-list-item-open";
import getProductBySku from "../../api/mongodb/get-product-by-sku";
import generatePdfPackingSlip from "../../api/backend/generate-pdf-packingslip";
import AssistantToggleButton from "../../components/main/assistant-toggle-button";
import shipOrderItem from "../../components/bol/ship-order-item";

const OrdersFbrDbb = (props) => {
  const { accountSaleNumber } = useParams();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const startOfToday = dayjs().startOf("day").format();
  const endOfToday = dayjs().endOf("day").format();
  const startOfTomorrow = dayjs().startOf("day").add(1, "day").format();
  const begin2021 = dayjs().year(2021).startOf("year").format();
  const end2099 = dayjs().year(2099).endOf("year").format();

  const sortingNameHandler = (input) => {
    if (input === `{"orderItems.offer.reference":1}`) {
      return "Productgroep (SKU)";
    } else if (input === `{"orderPlacedDateTime":1}`) {
      return "Besteldatum (Oud - Nieuw)";
    } else {
      return "Besteldatum (Nieuw - Oud)";
    }
  };
  const sortingHandler = () => {
    let sortingOrders = JSON.parse(sessionStorage.getItem("openOrdersSorting"));
    if (sortingOrders) {
      return sortingOrders;
    } else {
      return `{"orderPlacedDateTime":-1}`;
    }
  };

  const accountFilterHandler = () => {
    let accountFilter = JSON.parse(
      sessionStorage.getItem("openOrdersFilterByAccount")
    );
    if (accountFilter) {
      return accountFilter;
    } else {
      return `{"$exists":true}`;
    }
  };

  const sortOrders = (sorting) => {
    if (sorting) {
      sessionStorage.setItem("openOrdersSorting", JSON.stringify(sorting));
    }
    setSortOrdersFilter(sorting);
  };

  const filterOrdersByAccount = (accountSaleNumber) => {
    if (accountSaleNumber) {
      sessionStorage.setItem(
        "openOrdersFilterByAccount",
        JSON.stringify(accountSaleNumber)
      );
      setAccountFilter(accountSaleNumber);
    }
  };

  const filetDateNameHandler = (dateRange) => {
    if (dateRange.startDate === begin2021 && dateRange.endDate === endOfToday) {
      return "T/m vandaag";
    } else if (
      dateRange.startDate === startOfTomorrow &&
      dateRange.endDate === end2099
    ) {
      return "Vanaf morgen";
    } else {
      return "Alle orders";
    }
  };

  const dateFilterHandler = () => {
    let dateFilter = JSON.parse(
      sessionStorage.getItem("openOrdersFilterByDate")
    );
    if (dateFilter) {
      return dateFilter;
    } else {
      return { startDate: begin2021, endDate: endOfToday };
    }
  };

  const filterOrdersByDate = (dateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
      sessionStorage.setItem(
        "openOrdersFilterByDate",
        JSON.stringify({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        })
      );
      setDateFilter({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    }
  };

  const [errorMessage, setErrorMessage] = useState(false);
  const [openOrders, setOpenOrders] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [refetchRemainingTime, setRefetchRemainingTime] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [ordersToShow, setOrdersToShow] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [shipOrdersConfirmDetails, setShipOrdersConfirmDetails] =
    useState(false);
  const [recountOrders, setRecountOrders] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrdersFilter, setSortOrdersFilter] = useState(sortingHandler);
  const [accountFilter, setAccountFilter] = useState(accountFilterHandler);
  const [dateFilter, setDateFilter] = useState(dateFilterHandler);
  const [toggleRefetchOrders, setToggleRefetchOrders] = useState(false);
  const [showAlertText, setShowAlertText] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingState("Bestellingen worden geladen");

      let openOrders = await getAllOpenOrdersFbrDbb(
        auth.token,
        sortOrdersFilter,
        accountFilter,
        dateFilter
      );

      if (openOrders && openOrders.orders && openOrders.orders.length > 0) {
        const slicedOrders = openOrders.orders.slice(offset, offset + perPage);
        setPageCount(Math.ceil(openOrders.orders.length / perPage));
        setOffset(0);
        setOrdersToShow(slicedOrders);
        setOpenOrders(openOrders.orders);
        setLoadingState(false);
        setErrorMessage(false);
      } else {
        setOpenOrders(0);
        setErrorMessage("Geen openstaande orders gevonden");
        setOrdersToShow(false);
        setLoadingState(false);
      }
    })();
  }, [
    auth.token,
    sortOrdersFilter,
    accountFilter,
    dateFilter,
    toggleRefetchOrders,
  ]);

  const handlePageClick = async (e) => {
    let allCheckboxes = Array.from(
      document.querySelectorAll(".o-li-item input, .checkbox-all-orders  input")
    );
    allCheckboxes.map((checkbox) => {
      if (checkbox.checked === true) {
        checkbox.click();
      }
    });
    setCheckedOrders([]);
    setLoadingState("Bestellingen worden geladen");
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(offset);
    const slicedOrders = openOrders.slice(offset, offset + perPage);
    setOrdersToShow(slicedOrders);
    setLoadingState(false);
    setSearchQuery("");
  };

  const refetchOpenOrdersHandler = async () => {
    setLoadingState("Nieuwe Bestellingen ophalen");

    let fetchedTime = Number(
      sessionStorage.getItem("refetchAllOpenOrdersTime")
    );
    if (fetchedTime && fetchedTime !== null) {
      if (Date.now() - fetchedTime > 60000) {
        setRefetchRemainingTime(false);
        sessionStorage.setItem("refetchAllOpenOrdersTime", Date.now());
        const refetch = await refetchOpenOrders(auth.token);
        if (refetch && refetch.status === 200) {
          window.location.reload(true);
        }
      } else {
        const remainingTime = 60 - (Date.now() - fetchedTime) / 1000;
        setRefetchRemainingTime(remainingTime.toFixed(0));
      }
      setLoadingState(false);
    } else {
      setRefetchRemainingTime(false);
      sessionStorage.setItem("refetchAllOpenOrdersTime", Date.now());
      const refetch = await refetchOpenOrders(auth.token);
      if (refetch && refetch.status === 200) {
        window.location.reload(true);
      }
      setLoadingState(false);
    }
  };

  useEffect(() => {
    if (!refetchRemainingTime) {
      return;
    }
    const interval = setInterval(() => {
      let fetchedTime = Number(
        sessionStorage.getItem("refetchAllOpenOrdersTime")
      );
      const remainingTime = 60 - (Date.now() - fetchedTime) / 1000;
      if (remainingTime >= 0) {
        setRefetchRemainingTime(remainingTime.toFixed(0));
      } else {
        setRefetchRemainingTime(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [refetchRemainingTime]);

  const selectionText = () => {
    if (checkedOrders.length === 0) {
      return "(0)";
    } else {
      return "(" + checkedOrders.length + ")";
    }
  };

  const handleCheckedAll = (e) => {
    let allCheckboxes = Array.from(
      document.querySelectorAll(".o-li-item input:not([disabled])")
    );

    if (e.target.checked) {
      allCheckboxes.forEach((checkbox) => {
        if (checkbox.checked === false) {
          checkbox.click();
        }
      });
      setCheckedOrders(ordersToShow);
    } else if (!e.target.checked) {
      allCheckboxes.forEach((checkbox) => {
        if (checkbox.checked === true) {
          checkbox.click();
        }
      });
      setCheckedOrders([]);
    }
  };

  const handleChecked = (checked, order, orderItem) => {
    let newArr = [...checkedOrders];
    if (!checked) {
      let orderFound = newArr.find((o) => o.id === order.id);
      if (!orderFound) {
        order.orderItems.forEach((item) => {
          if (item.fulfilment.distributionParty === "BOL") {
            item.checked = true;
          }
        });
        setCheckedOrders((prevArr) => [...prevArr, order]);
      } else {
        order.orderItems.forEach((item) => {
          if (item.fulfilment.distributionParty === "BOL") {
            item.checked = true;
          }
        });
        setCheckedOrders(newArr);
      }
    } else {
      let newArr = [...checkedOrders];
      let i = newArr.findIndex((o) => o.id === order.id);
      if (i !== -1) {
        if (newArr[i].orderItems.length <= 1) {
          newArr.splice(i, 1);
        } else {
          newArr[i].orderItems.forEach((item, j) => {
            item.checked = false;
          });

          let allUnchecked = Object.values(newArr[i].orderItems).every(
            (item) => {
              return item.checked === false;
            }
          );
          allUnchecked && newArr.splice(i, 1);
        }
        setCheckedOrders(newArr);
      }
    }
  };

  const handleCanceledOrderItem = async (order, orderItem) => {
    let newOpenOrders = [...openOrders];

    newOpenOrders.forEach((o, index) => {
      if (o.orderId === order.orderId) {
        o.orderItems.forEach((oI, indexJ) => {
          if (oI.orderItemId === orderItem.orderItemId) {
            newOpenOrders[index].orderItems.splice(indexJ, 1);
          }
        });
        if (o.orderItems.length === 0) {
          newOpenOrders.splice(index, 1);
        }
      }
    });

    const slicedOrders = newOpenOrders.slice(offset, offset + perPage);

    for (let order of slicedOrders) {
      if (order.orderItems && order.orderItems.length > 0) {
        for (let orderItem of order.orderItems) {
          let res = await getProductBySku(
            orderItem.offer.reference,
            auth.token
          );
          if (!res) {
            orderItem.productData = false;
          } else {
            orderItem.productData = res.productData;
          }
        }
      }
    }

    setPageCount(Math.ceil(newOpenOrders.length / perPage));
    setOffset(0);
    setOrdersToShow(slicedOrders);
    setOpenOrders(newOpenOrders);
    setRecountOrders(!recountOrders);
  };

  const handleSingleShipOrderConfirmDetails = (orderX) => {
    orderX.orderItems.forEach((item) => {
      if (item.fulfilment.distributionParty === "BOL") {
        item.checked = true;
      }
    });

    setShipOrdersConfirmDetails(orderX);
  };

  const searchHandler = (e, array) => {
    let searchArray = [...array];
    let searchArrayString = searchArray.map((order, i) => {
      return (order = JSON.stringify(order));
    });
    const matchedArray = searchArrayString.filter((element) => {
      if (element.toLowerCase().includes(e.target.value.toLowerCase())) {
        return true;
      }
      return false;
    });
    let searchArrayResult = matchedArray.map((order, i) => {
      return (order = JSON.parse(order));
    });
    setSearchQuery(e.target.value);
    setOrdersToShow(searchArrayResult);
  };

  const downloadMultipleShippingLabelsHandler = async () => {
    setLoadingState("Verzendzegels worden opgehaald");
    if (checkedOrders.length <= 0) {
      return;
    }
    let labels = [];

    console.log(checkedOrders);

    for (let checkedOrder of checkedOrders) {
      console.log(checkedOrder.deliveryOptions[0].chosenOption);
      if (
        checkedOrder.deliveryOptions &&
        checkedOrder.deliveryOptions.length > 0
      ) {
        checkedOrder.deliveryOptions.forEach((shipment) => {
          if (shipment && shipment.orderItemIds) {
            if (
              shipment.orderItemIds.includes(
                checkedOrder.orderItems[0].orderItemId
              )
            ) {
              if (shipment.chosenOption && shipment.chosenOption.entityId) {
                labels.push({
                  account: checkedOrder.origin.account,
                  labelEntityId: shipment.chosenOption.entityId,
                });
              }
            }
          }
        });
      }
    }

    let multipleLabelsPdf = await getMultipleShippingLabelsPdf(
      labels,
      auth.token
    );
    setLoadingState(false);
    downloadBlobFile(multipleLabelsPdf, moment().format("L-HH-mm"));
  };

  const updateOrdersWithDeliveryOption = async (
    updateDeliveryOption,
    order
  ) => {
    let newOrdersToShow = [...ordersToShow];
    for (let o of newOrdersToShow) {
      for (let dO of o.deliveryOptions) {
        if (dO.orderItemIds.includes(order.orderItems[0].orderItemId)) {
          dO.chosenOption = updateDeliveryOption;
        }
      }
      setOrdersToShow(newOrdersToShow);
    }
  };

  const updateCheckedOrdersWithDeliveryOption = async (
    updateDeliveryOption,
    order
  ) => {
    let newCheckedOrders = checkedOrders;

    for (let o of newCheckedOrders) {
      for (let dO of o.deliveryOptions) {
        if (dO.orderItemIds.includes(order.orderItems[0].orderItemId)) {
          dO.chosenOption = updateDeliveryOption;
        }
      }
    }
    setCheckedOrders(newCheckedOrders);
  };

  const deliveryOptionImgHandler = (deliveryOption) => {
    switch (deliveryOption) {
      case "PostNL & bol.com - Brievenbus":
        return <img src={postnlBolMailbox} alt="PostNLBol Brievenbus" />;
      case "PostNL Premium & bol.com - Brievenbus":
        return <img src={postnlBolMailbox} alt="PostNLBol Brievenbus Premium" />;
      case "PostNL & bol.com - Pakket":
        return <img src={postnlBolParcel} alt="PostNLBol Pakket" />;
      case "PostNL Premium & bol.com - Pakket":
          return <img src={postnlBolParcel} alt="PostNLBol Pakket Premium" />;
      case "PostNL & bol.com - Avond pakket":
        return <img src={postnlBolParcelNight} alt="PostNLBol Pakket Avond" />;
      case "PostNL & bol.com - Afhaalpunt pakket":
        return (
          <img src={postnlBolParcelPickup} alt="PostNLBol Pakket Afhaalpunt" />
        );
      case "DHL & bol.com - Brievenbus":
        return <img src={dhlBolMailbox} alt="DhlBol Brievenbus" />;
      case "DHL & bol.com - Pakket":
        return <img src={dhlBolParcel} alt="DhlBol Pakket" />;
      case "DHL & bol.com - Avond pakket":
        return <img src={dhlBolParcelNight} alt="DhlBol Pakket Avond" />;
      case "DHL & bol.com - Afhaalpunt pakket":
        return <img src={dhlBolParcelPickup} alt="DhlBol Pakket Afhaalpunt" />;
      default:
      // code block
    }
  };

  const shipOrdersHandler = async (ordersToProcess) => {
    let handledOrderItems = 0;
    let orderItems = 0;
    ordersToProcess.forEach((order) => {
      order.frontEndChosenDeliveryOption =
        order.deliveryOptions[0].chosenOption;
      orderItems += order.deliveryOptions[0].orderItemIds.length;
    });

    setLoadingState(`${handledOrderItems}/${orderItems} Bestelregels verwerkt`);

    for (let order of ordersToProcess) {
      for (let orderItem of order.orderItems) {
        if (orderItem.checked) {
          // orderItem.shippedSuccesful = false;
          let account = order.origin.account;
          if (!account) return;
          let res = await shipOrderItem(account, auth, order, orderItem);
          console.log(res);
          if (res.response.status === 202) {
            // orderItem.shippedSuccesful = true;
            handledOrderItems += 1;
            setLoadingState(
              `${handledOrderItems}/${orderItems} Bestelregels verwerkt`
            );
          }
        }
      }
    }
    setLoadingState(false);
    setToggleRefetchOrders(!toggleRefetchOrders);

    let allCheckboxes = Array.from(
      document.querySelectorAll(".o-li-item input")
    );

    setCheckedOrders([]);
    allCheckboxes.forEach((checkbox) => {
      if (checkbox.checked === true) {
        checkbox.click();
      }
    });
    if (handledOrderItems > 0 && handledOrderItems === orderItems) {
      setShowAlertText("Bestellingen succesvol verzonden");
      setTimeout(() => setShowAlertText(false), 3000);
    }
  };

  const createPackingListPDF = async (orders) => {
    setLoadingState("Paklijst wordt aangemaakt");
    let generatePackingSlip = await generatePdfPackingSlip(auth.token, orders);
    setLoadingState(false);
  };

  return (
    <React.Fragment>
      <div className="title-header title-header-open-orders">
        <span>Bestellingen</span>
        <div>
          <div className="header-refetch-container">
            <Button
              className={refetchRemainingTime && "disableRefetchButton"}
              onClick={() => refetchOpenOrdersHandler()}
            >
              {refetchRemainingTime ? (
                <div className="header-refetch-timer">
                  {refetchRemainingTime}s
                </div>
              ) : (
                <FaSyncAlt />
              )}
            </Button>
          </div>
        </div>
      </div>
      <OrdersHeader recountOrders={recountOrders} />

      <div className="orders-sub-header">
        <div className="orders-sub-header-filter-count">
          ({openOrders ? openOrders.length : 0}){" "}
          <span>bestellingen gefilterd</span>{" "}
        </div>

        <div className="orders-search-wrapper">
          <label className="input-search-icon">
            <GoSearch />
          </label>
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="search-input"
            placeholder="Zoek bestelling..."
            value={searchQuery}
            onChange={(e) => searchHandler(e, openOrders)}
          />
        </div>
        <div className="orders-sub-header-filter-options">
          <DropdownButton
            className="filter-open-orders-dropdown"
            align="end"
            id="dropdown-filter-button"
            title={<BsShopWindow />}
            defaultValue={perPage}
            value={perPage}
            onSelect={(e) => {
              filterOrdersByAccount(e);
            }}
          >
            <Dropdown.Item eventKey={`{"$exists":true}`}>
              Alle accounts
            </Dropdown.Item>
            {props.bolAccounts &&
              props.bolAccounts.map((bolAccount, i) => {
                return (
                  <Dropdown.Item eventKey={bolAccount.accountSaleNumber}>
                    {bolAccount.accountName}
                  </Dropdown.Item>
                );
              })}
          </DropdownButton>
          <DropdownButton
            className="filter-open-orders-dropdown"
            align="end"
            id="dropdown-filter-date-button"
            title={<BsCalendarDate />}
            defaultValue={perPage}
            value={perPage}
            onSelect={(dateRangeString) => {
              let dateRange = JSON.parse(dateRangeString);
              filterOrdersByDate({
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
              });
            }}
          >
            <Dropdown.Item
              eventKey={JSON.stringify({
                startDate: begin2021,
                endDate: end2099,
              })}
            >
              Alles
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={JSON.stringify({
                startDate: begin2021,
                endDate: endOfToday,
              })}
            >
              T/m vandaag
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={JSON.stringify({
                startDate: startOfTomorrow,
                endDate: end2099,
              })}
            >
              Vanaf morgen
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            className="filter-open-orders-dropdown"
            align="end"
            id="dropdown-sorting-button"
            title={<AiOutlineSortAscending />}
            defaultValue={perPage}
            value={perPage}
            onSelect={(e) => {
              sortOrders(e);
            }}
          >
            <Dropdown.Item eventKey={'{"orderItems.offer.reference":1}'}>
              Productgroep (SKU)
            </Dropdown.Item>
            <Dropdown.Item eventKey={'{"orderPlacedDateTime":-1}'}>
              Besteldatum (Nieuw - Oud)
            </Dropdown.Item>
            <Dropdown.Item eventKey={'{"orderPlacedDateTime":1}'}>
              Besteldatum (Oud - Nieuw)
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      {((accountFilter && accountFilter !== `{"$exists":true}`) ||
        (dateFilter &&
          JSON.stringify(dateFilter) !==
            `{"startDate":"2021-01-01T00:00:00+01:00","endDate":"2099-12-31T23:59:59+01:00"}`)) && (
        <div className="orders-sub-header-filters">
          <div className="orders-sub-header-filters-title">Filters:</div>

          {accountFilter &&
            accountFilter !== `{"$exists":true}` &&
            props &&
            props.bolAccounts &&
            props.bolAccounts.map((bolAccount) => {
              if (bolAccount.accountSaleNumber === accountFilter) {
                return (
                  <div className="orders-sub-header-filters-item">
                    <div>{bolAccount.accountName}</div>
                    <div className="orders-sub-header-filters-item-close">
                      <FaTimes
                        onClick={() => {
                          sessionStorage.removeItem(
                            "openOrdersFilterByAccount"
                          );
                          setAccountFilter(`{"$exists":true}`);
                        }}
                      />
                    </div>
                  </div>
                );
              }
            })}
          {dateFilter && filetDateNameHandler(dateFilter) !== "Alle orders" && (
            <div className="orders-sub-header-filters-item">
              <div>{filetDateNameHandler(dateFilter)}</div>
              <div className="orders-sub-header-filters-item-close">
                <FaTimes
                  onClick={() => {
                    console.log(dateFilter);
                    sessionStorage.removeItem("openOrdersFilterByAccount");
                    setDateFilter({ startDate: begin2021, endDate: end2099 });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {sortOrdersFilter && sortOrdersFilter !== `{"orderPlacedDateTime":-1}` && (
        <div className="orders-sub-header-filters">
          <div className="orders-sub-header-filters-title">Sortering:</div>
          <div className="orders-sub-header-filters-item">
            <div>{sortingNameHandler(sortOrdersFilter)}</div>
            <div className="orders-sub-header-filters-item-close">
              <FaTimes
                onClick={() => {
                  sessionStorage.removeItem("openOrdersSorting");
                  setSortOrdersFilter(`{"orderPlacedDateTime":-1}`);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {ordersToShow && (
        <div className="orders-container-header open-orders-container-header orders-container-header-dbb">
          <div className="checkbox-all-orders">
            <Form.Check
              type="checkbox"
              label={selectionText()}
              onClick={handleCheckedAll}
              disabled={!openOrders}
            />
          </div>
          <div className="orders-container-header-dbb-actions">
            <div className="orders-container-header-dbb-actions-buttons">
              {ordersToShow && checkedOrders.length > 0 && (
                <div className="orders-container-header-actions">
                  <Button
                    onClick={() => {
                      downloadMultipleShippingLabelsHandler();
                    }}
                  >
                    Download verzendzegels
                  </Button>
                </div>
              )}
              {ordersToShow && checkedOrders.length > 0 && (
                <div className="orders-container-header-actions">
                  <Button
                    onClick={() => {
                      console.log(checkedOrders);
                      setShipOrdersConfirmDetails(true);
                    }}
                  >
                    {checkedOrders.length}{" "}
                    {checkedOrders.length === 1
                      ? "Bestelling "
                      : "Bestellingen "}
                    verwerken
                  </Button>
                </div>
              )}
            </div>
            <div className="orders-container-header-dbb-actions-packagelist">
              <DropdownButton
                className="packinglist-dropdown filter-open-orders-dropdown"
                align="end"
                id="dropdown-pickinglist-button"
                title={[<FaPrint />, " Picklijsten"]}
              >
                {checkedOrders && checkedOrders.length > 0 && (
                  <Dropdown.Item
                    onClick={() => {
                      createPackingListPDF(checkedOrders);
                    }}
                  >
                    <FaPrint /> Picklijst geselecteerde orders
                  </Dropdown.Item>
                )}

                {ordersToShow && ordersToShow.length > 0 && (
                  <Dropdown.Item
                    onClick={() => {
                      createPackingListPDF(ordersToShow);
                    }}
                  >
                    <FaPrint />
                    Picklijst huidige pagina
                  </Dropdown.Item>
                )}

                {openOrders && openOrders.length > 0 && (
                  <Dropdown.Item
                    onClick={() => {
                      createPackingListPDF(openOrders);
                    }}
                  >
                    <FaPrint />
                    Picklijst alle orders
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </div>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="noOrdersContainer">
          <div className="noOrdersText">
            <div className="no-result-search">
              <GoSearch />
              {errorMessage}
            </div>
          </div>
        </div>
      )}
      <div className="orders-container open-orders-container">
        {ordersToShow &&
          ordersToShow.length > 0 &&
          ordersToShow.map((order, i) => {
            return (
              <div
                key={i + i}
                className={
                  "o-li-item-container o-li-item-container-dbb" +
                  (order.orderItems && order.orderItems.length > 1
                    ? " multiOrderItems"
                    : "")
                }
              >
                <OrderListItemOpen
                  key={i}
                  order={order}
                  distributionParty="DBB"
                  handleChecked={handleChecked}
                  cancelOrderItem={handleCanceledOrderItem}
                  setSingleShipOrderConfirmDetails={
                    handleSingleShipOrderConfirmDetails
                  }
                  update={(updateDeliveryOption) => {
                    updateOrdersWithDeliveryOption(updateDeliveryOption, order);
                    updateCheckedOrdersWithDeliveryOption(
                      updateDeliveryOption,
                      order
                    );
                  }}
                />
              </div>
            );
          })}
        {ordersToShow && ordersToShow.length === 0 && (
          <div className="noOrdersContainer">
            <div className="noOrdersText">
              {searchQuery === "" ? (
                "Alle orders zijn ingepakt, goed zo!"
              ) : (
                <div className="no-result-search">
                  <GoSearch />
                  Geen resultaat
                </div>
              )}
            </div>
          </div>
        )}
        {loadingState && (
          <div className="loading-container">
            <div className="loading-container-title">{loadingState}</div>
            <RotateLoader
              size={100}
              width={100}
              height={10}
              color={"#fff"}
              loading={loadingState}
            />
          </div>
        )}
      </div>
      {ordersToShow && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={5}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      )}
      {shipOrdersConfirmDetails &&
        (() => {
          let ordersToProcess = [...checkedOrders];

          if (
            shipOrdersConfirmDetails &&
            typeof shipOrdersConfirmDetails !== "boolean"
          ) {
            ordersToProcess = [shipOrdersConfirmDetails];
          }

          return (
            <div className="deliveryOptionsContainer">
              <div className="deliveryOptions">
                <div
                  className="deliveryOptionsCloseButton"
                  onClick={() => {
                    setShipOrdersConfirmDetails(false);
                  }}
                >
                  <FaTimes />
                </div>
                <h1>
                  {ordersToProcess.length}{" "}
                  {ordersToProcess.length === 1
                    ? "Bestelling "
                    : "Bestellingen "}
                  verwerken
                </h1>
                <h5>
                  {(() => {
                    if (ordersToProcess.length === 1) {
                      return (
                        <ol className="orderItemsList">
                          <li>
                            {ordersToProcess[0].orderItems.map((oI, i) => {
                              console.log(oI);
                              if (oI.productData && oI.productData.title) {
                                return (
                                  <div>
                                    <span>{i + 1}</span>
                                    {oI.productData.title}
                                  </div>
                                );
                              } else {
                                return (
                                  <div>
                                    <span>{i + 1}</span>
                                    {oI.product.title}
                                  </div>
                                );
                              }
                            })}
                          </li>
                        </ol>
                      );
                    }
                  })()}
                </h5>

                <div className="confirmDeliveryAlertMessageContainer">
                  <div className="confirmDeliveryAlertMessageIconContainer">
                    <FaExclamationTriangle />
                  </div>
                  <div className="confirmDeliveryAlertMessageText">
                    Voor een of meerdere bestellingen verwacht de klant de
                    bestelling op een specifieke datum te ontvangen. Wacht met
                    inleveren van de bestelling tot de aangegeven
                    overdrachtsdatum. <br />
                    <br />
                    Na bevestiging stuurt bol.com de klant een e-mail met de
                    aangegeven leverdatum, de bezorgdienst en het Track & Trace
                    nummer.
                    {/* {cancelReqInOrders && (
                <div>
                  <br />
                  <br />
                  <div className="watchOutMessage">
                    LET OP: Klant heeft annuleringsverzoek ingediend!
                  </div>
                </div>
              )} */}
                  </div>
                </div>
                {ordersToProcess.map((order) => {
                  order.orderItems.map((orderItem, i) => {
                    return (
                      <div className="deliveryOptionsProduct">
                        <div className="deliveryOptionsProductTitle">
                          {orderItem.product.title}
                        </div>
                        <div className="deliveryOptionsProductDetails">
                          <span>
                            EAN: {orderItem.product.ean}{" "}
                            <a
                              href={
                                "https://www.bol.com/nl/nl/s/?searchtext=" +
                                orderItem.product.ean
                              }
                              target="_noblank"
                            >
                              <FaExternalLinkAlt />
                            </a>
                          </span>
                          <span>|</span>
                          <span>{orderItem.offer.reference}</span>
                          <span>|</span>
                          <span>
                            {orderItem.quantity}
                            {orderItem.quantity === 1 ? " stuk" : " stuks"}
                          </span>
                          <span>|</span>
                          <span>
                            € {orderItem.unitPrice * orderItem.quantity}
                          </span>
                        </div>
                      </div>
                    );
                  });
                })}

                <div className="deliveryOptionsHeadBalk">
                  <h1 className="labelTextH1">Verzendzegels</h1>
                  <div>Aantal</div>
                  <div>Prijs</div>
                </div>

                {(() => {
                  let shipmentChoices = [];

                  for (let cOrder of ordersToProcess) {
                    for (let dOption of cOrder.deliveryOptions) {
                      let labelName = dOption.chosenOption.labelDisplayName;
                      if (shipmentChoices.length > 0) {
                        let foundShipmentChoice = false;
                        shipmentChoices.map((deliveryOption, i) => {
                          if (deliveryOption.hasOwnProperty(labelName)) {
                            foundShipmentChoice = true;
                            shipmentChoices[i][labelName].quantity += 1;
                            shipmentChoices[i][labelName].totalPrice +=
                              dOption.chosenOption.labelPrice.totalPrice;
                          }
                        });

                        if (foundShipmentChoice === false) {
                          shipmentChoices.push({
                            [labelName]: {
                              quantity: 1,
                              totalPrice:
                                dOption.chosenOption.labelPrice.totalPrice,
                              packageRestrictions:
                                dOption.chosenOption.packageRestrictions,
                            },
                          });
                        }
                      } else {
                        shipmentChoices.push({
                          [labelName]: {
                            quantity: 1,
                            totalPrice:
                              dOption.chosenOption.labelPrice.totalPrice,
                            packageRestrictions:
                              dOption.chosenOption.packageRestrictions,
                          },
                        });
                      }
                    }
                  }
                  return shipmentChoices.map((shipmentChoice) => {
                    let shipmentChoiceData;
                    for (var choice in shipmentChoice) {
                      shipmentChoiceData = shipmentChoice[choice];
                    }

                    return (
                      <div
                        key={Object.keys(shipmentChoice)[0]}
                        className={"radio-toolbar orderConfirm"}
                      >
                        <Form.Check
                          type="radio"
                          name="chooseDeliveryOptions"
                          label={
                            <div className="deliveryOptionLabelInfoMulti">
                              <div className="deliveryOptionLabelImg">
                                {deliveryOptionImgHandler(
                                  Object.keys(shipmentChoice)[0]
                                )}
                              </div>
                              <div className="deliveryOptionData">
                                <div className="deliveryOptionName">
                                  {Object.keys(shipmentChoice)[0]}
                                </div>
                                <div className="deliveryOptionMeasurements">
                                  <div>
                                    {"< " +
                                      shipmentChoiceData.packageRestrictions
                                        .maxWeight}
                                  </div>
                                  <div className="divider">|</div>
                                  <div>
                                    {"< " +
                                      shipmentChoiceData.packageRestrictions
                                        .maxDimensions}
                                  </div>
                                </div>
                              </div>
                              <div className="deliveryOptionQuantity">
                                {shipmentChoiceData.quantity}
                              </div>
                              <div className="deliveryOptionPrice">
                                €{" "}
                                {shipmentChoiceData.totalPrice
                                  .toFixed(2)
                                  .replace(".", ",")}
                                *
                              </div>
                            </div>
                          }
                        />
                      </div>
                    );
                  });
                })()}
                <div className="deliveryOptionLabelExtraInfo">
                  * Excl. eventueel verschuldigde btw. De zegelkosten verrekenen
                  we automatisch met je opbrengst.
                </div>
                <div className="deliveryOptionButtons">
                  <Button
                    onClick={() => {
                      setShipOrdersConfirmDetails(false);
                    }}
                  >
                    Nee, ga terug
                  </Button>
                  <Button
                    onClick={(e) => {
                      shipOrdersHandler(ordersToProcess);
                      setShipOrdersConfirmDetails(false);
                    }}
                  >
                    Ja, verwerk!
                  </Button>
                </div>
              </div>
            </div>
          );
        })()}
      {showAlertText && <div className="snackbar">{showAlertText}</div>}
      <AssistantToggleButton />
    </React.Fragment>
  );
};

export default OrdersFbrDbb;
