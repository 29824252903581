import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { RotateLoader } from "react-spinners";

import { FaSyncAlt, FaTimes } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { BsShopWindow } from "react-icons/bs";
import { AiOutlineSortAscending } from "react-icons/ai";
import { AuthContext } from "../../context/main/auth-context";
import "../../css/orders/orders.css";

import flagNetherlands from "../../images/main/flag-netherlands.svg";
import flagBelgium from "../../images/main/flag-belgium.svg";
import worldGlobe from "../../images/main/world-globe.svg";

import getAllOpenOrdersShopify from "../../components/shopify/get-all-open-orders-shopify";
import OrdersHeader from "../../components/orders/orders-header";
import refetchOpenOrders from "../../components/orders/refetch-all-open-orders";
import OrderListItemOpen from "../../components/orders/order-list-item-open";
import AssistantToggleButton from "../../components/main/assistant-toggle-button";

import getProductBySku from "../../api/mongodb/get-product-by-sku";

const OrdersFbrDbr = (props) => {
  const { accountSaleNumber } = useParams();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const sortingNameHandler = (input) => {
    if (input === `{"orderItems.offer.reference":1}`) {
      return "Productgroep (SKU)";
    } else if (input === `{"orderPlacedDateTime":1}`) {
      return "Besteldatum (Oud - Nieuw)";
    } else {
      return "Besteldatum (Nieuw - Oud)";
    }
  };
  const sortingHandler = () => {
    let sortingOrders = JSON.parse(sessionStorage.getItem("openOrdersSorting"));
    if (sortingOrders) {
      return sortingOrders;
    } else {
      return `{"orderPlacedDateTime":-1}`;
    }
  };

  const accountFilterHandler = () => {
    let accountFilter = JSON.parse(
      sessionStorage.getItem("openOrdersFilterByAccount")
    );
    if (accountFilter) {
      return accountFilter;
    } else {
      return `{"$exists":true}`;
    }
  };

  const sortOrders = (sorting) => {
    if (sorting) {
      sessionStorage.setItem("openOrdersSorting", JSON.stringify(sorting));
    }
    setSortOrdersFilter(sorting);
  };

  const filterOrdersByAccount = (accountSaleNumber) => {
    if (accountSaleNumber) {
      sessionStorage.setItem(
        "openOrdersFilterByAccount",
        JSON.stringify(accountSaleNumber)
      );
    }
    setAccountFilter(accountSaleNumber);
  };

  const [errorMessage, setErrorMessage] = useState(false);
  const [openOrders, setOpenOrders] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [refetchRemainingTime, setRefetchRemainingTime] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [ordersToShow, setOrdersToShow] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [recountOrders, setRecountOrders] = useState(false);
  const [cancelOrderItemModal, setCancelOrderItemModal] = useState(false);
  const [cancelResponse, setCancelResponse] = useState();
  const [loadingPage, setLoadingPage] = useState(false);
  const [showAlertText, setShowAlertText] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrdersFilter, setSortOrdersFilter] = useState(sortingHandler);
  const [accountFilter, setAccountFilter] = useState(accountFilterHandler);
  const [filterCountry, setFilterCountry] = useState("ALL");
  const [filterCountryImg, setFilterCountryImg] = useState(worldGlobe);

  useEffect(() => {
    (async () => {
      setLoadingState("Bestellingen worden geladen");

      let openOrders = await getAllOpenOrdersShopify(
        auth.token,
        sortOrdersFilter,
        accountFilter
      );

      if (openOrders && openOrders.orders && openOrders.orders.length > 0) {
        setErrorMessage(false);

        const slicedOrders = openOrders.orders.slice(offset, offset + perPage);

        setPageCount(Math.ceil(openOrders.orders.length / perPage));
        setOffset(0);
        setOrdersToShow(slicedOrders);
        setOpenOrders(openOrders.orders);
        setLoadingState(false);
        setErrorMessage(false);
      } else {
        setOpenOrders(0);
        setErrorMessage("Geen openstaande orders gevonden");
        setOrdersToShow(false);
        setLoadingState(false);
      }
    })();
  }, [auth.token, sortOrdersFilter, accountFilter]);

  const handlePageClick = async (e) => {
    let allCheckboxes = Array.from(
      document.querySelectorAll(".o-li-item input, .checkbox-all-orders  input")
    );
    allCheckboxes.map((checkbox) => {
      if (checkbox.checked === true) {
        checkbox.click();
      }
    });

    setCheckedOrders([]);
    setLoadingState("Bestellingen worden geladen");
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(offset);
    const slicedOrders = openOrders.slice(offset, offset + perPage);
    setOrdersToShow(slicedOrders);
    setLoadingState(false);
    setSearchQuery("");
  };

  const refetchOpenOrdersHandler = async () => {
    setLoadingState([
      "Nieuwe Bestellingen ophalen",
      <br />,
      <br />,
      "Dit kan enkele minuten duren",
    ]);

    let fetchedTime = Number(
      sessionStorage.getItem("refetchAllOpenOrdersTime")
    );
    if (fetchedTime && fetchedTime !== null) {
      if (Date.now() - fetchedTime > 60000) {
        setRefetchRemainingTime(false);
        sessionStorage.setItem("refetchAllOpenOrdersTime", Date.now());
        const refetch = await refetchOpenOrders(auth.token);
        if (refetch && refetch.status === 200) {
          window.location.reload(true);
        }
      } else {
        const remainingTime = 60 - (Date.now() - fetchedTime) / 1000;
        setRefetchRemainingTime(remainingTime.toFixed(0));
      }
      setLoadingState(false);
    } else {
      setRefetchRemainingTime(false);
      sessionStorage.setItem("refetchAllOpenOrdersTime", Date.now());
      const refetch = await refetchOpenOrders(auth.token);
      if (refetch && refetch.status === 200) {
        window.location.reload(true);
      }
      setLoadingState(false);
    }
  };

  useEffect(() => {
    if (!refetchRemainingTime) {
      return;
    }
    const interval = setInterval(() => {
      let fetchedTime = Number(
        sessionStorage.getItem("refetchAllOpenOrdersTime")
      );
      const remainingTime = 60 - (Date.now() - fetchedTime) / 1000;
      if (remainingTime >= 0) {
        setRefetchRemainingTime(remainingTime.toFixed(0));
      } else {
        setRefetchRemainingTime(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [refetchRemainingTime]);

  const selectionText = () => {
    if (checkedOrders.length === 0) {
      return "(0)";
    } else {
      return "(" + checkedOrders.length + ")";
    }
  };

  const handleCheckedAll = (e) => {
    let allCheckboxes = Array.from(
      document.querySelectorAll(".o-li-item input")
    );

    if (e.target.checked) {
      allCheckboxes.forEach((checkbox) => {
        if (checkbox.checked === false) {
          checkbox.click();
        }
      });
      setCheckedOrders(ordersToShow);
    } else if (!e.target.checked) {
      allCheckboxes.forEach((checkbox) => {
        if (checkbox.checked === true) {
          checkbox.click();
        }
      });
      setCheckedOrders([]);
    }
  };

  const handleChecked = (checked, order, orderItem) => {
    let newArr = [...checkedOrders];
    if (!checked) {
      let orderFound = newArr.find((o) => o.id === order.id);
      if (!orderFound) {
        let orderItemFound = order.orderItems.find(
          (oI) => oI.variant_id === orderItem.variant_id
        );
        if (orderItemFound) {
          orderItem.checked = true;
        }
        setCheckedOrders((prevArr) => [...prevArr, order]);
      } else {
        let orderItemFound = orderFound.orderItems.find(
          (oI) => oI.variant_id === orderItem.variant_id
        );
        if (orderItemFound) {
          orderItemFound.checked = true;
        }
        setCheckedOrders(newArr);
      }
    } else {
      let newArr = [...checkedOrders];

      let i = newArr.findIndex((o) => o.id === order.id);

      if (i !== -1) {
        let j = newArr[i].orderItems.findIndex(
          (oItem) => oItem.variant_id === orderItem.variant_id
        );

        if (newArr[i].orderItems.length <= 1) {
          newArr.splice(i, 1);
        } else {
          if (j !== -1) {
            newArr[i].orderItems[j].checked = false;
          }

          let allUnchecked = Object.values(newArr[i].orderItems).every(
            (item) => {
              return item.checked === false || item.fulfillable_quantity === 0;
            }
          );

          allUnchecked && newArr.splice(i, 1);
        }
        setCheckedOrders(newArr);
      }
    }
  };

  const handleCanceledOrderItem = async (order, orderItem) => {
    let newOpenOrders = [...openOrders];

    newOpenOrders.forEach((o, index) => {
      if (o.orderId === order.orderId) {
        o.orderItems.forEach((oI, indexJ) => {
          if (oI.variant_id === orderItem.variant_id) {
            newOpenOrders[index].orderItems.splice(indexJ, 1);
          }
        });
        if (o.orderItems.length === 0) {
          newOpenOrders.splice(index, 1);
        }
      }
    });

    const slicedOrders = newOpenOrders.slice(offset, offset + perPage);

    for (let order of slicedOrders) {
      if (order.orderItems && order.orderItems.length > 0) {
        for (let orderItem of order.orderItems) {
          let res = await getProductBySku(
            orderItem.offer.reference,
            auth.token
          );
          if (!res) {
            orderItem.productData = false;
          } else {
            orderItem.productData = res.productData;
          }
        }
      }
    }

    setPageCount(Math.ceil(newOpenOrders.length / perPage));
    setOffset(0);
    setOrdersToShow(slicedOrders);
    setOpenOrders(newOpenOrders);
    setRecountOrders(!recountOrders);
  };

  const cancellationRequestHandler = (order, orderItem) => {
    setCancelOrderItemModal({
      auth: auth,
      order: order,
      orderItem: orderItem,
    });
  };

  let cancelResponseHandler = async (response, order, orderItem) => {
    setCancelResponse(response);
    if (response === 202) {
      handleCanceledOrderItem(order, orderItem);
      setShowAlertText("Artikel geannuleerd");
      setTimeout(() => setShowAlertText(false), 3000);
      setCancelResponse(false);
      setLoadingPage(false);
    } else {
      setShowAlertText("Artikel annuleren mislukt");
      setTimeout(() => setShowAlertText(false), 3000);
      setCancelResponse(false);
      setLoadingPage(false);
    }
  };
  const closeCancelOrderItemModalHandler = (props) => {
    setCancelOrderItemModal(false);
    setLoadingPage(false);
  };

  const searchHandler = (e, array) => {
    let searchArray = [...array];
    let searchArrayString = searchArray.map((order, i) => {
      return (order = JSON.stringify(order));
    });
    const matchedArray = searchArrayString.filter((element) => {
      if (element.toLowerCase().includes(e.target.value.toLowerCase())) {
        return true;
      }
      return false;
    });
    let searchArrayResult = matchedArray.map((order, i) => {
      return (order = JSON.parse(order));
    });
    setSearchQuery(e.target.value);

    setOrdersToShow(searchArrayResult);
  };

  const shipOrdersHandler = (option, order) => {
    let orders = order ? [order] : checkedOrders;
    sessionStorage.setItem("checkedOrders", JSON.stringify(orders));
    sessionStorage.setItem("shippingMethod", JSON.stringify(option));
    navigate("/orders/shopify/ship/step-1");
  };

  return (
    <React.Fragment>
      <div className="title-header title-header-open-orders">
        <span>Bestellingen</span>
        <div>
          <div className="header-refetch-container">
            <Button
              className={refetchRemainingTime && "disableRefetchButton"}
              onClick={() => refetchOpenOrdersHandler()}
            >
              {refetchRemainingTime ? (
                <div className="header-refetch-timer">
                  {refetchRemainingTime}s
                </div>
              ) : (
                <FaSyncAlt />
              )}
            </Button>
          </div>
        </div>
      </div>
      <OrdersHeader recountOrders={recountOrders} />

      <div className="orders-sub-header">
        <div className="orders-sub-header-filter-count">
          ({openOrders ? openOrders.length : 0}){" "}
          <span>bestellingen gefilterd</span>
        </div>
        <div className="orders-search-wrapper">
          <label className="input-search-icon">
            <GoSearch />
          </label>
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="search-input"
            placeholder="Zoek bestelling..."
            value={searchQuery}
            onChange={(e) => searchHandler(e, openOrders)}
          />
        </div>
        <div className="orders-sub-header-filter-options">
          <DropdownButton
            className="filter-open-orders-dropdown"
            align="end"
            id="dropdown-filter-button"
            title={<BsShopWindow />}
            defaultValue={perPage}
            value={perPage}
            onSelect={(e) => {
              filterOrdersByAccount(e);
            }}
          >
            <Dropdown.Item key={-1} eventKey={`{"$exists":true}`}>
              ALLE ACCOUNTS
            </Dropdown.Item>
            {props.bolAccounts &&
              props.bolAccounts.map((bolAccount, i) => {
                return (
                  <Dropdown.Item
                    key={i}
                    eventKey={bolAccount.accountSaleNumber}
                  >
                    {bolAccount.accountName}
                  </Dropdown.Item>
                );
              })}
          </DropdownButton>
          <DropdownButton
            className="filter-open-orders-dropdown"
            align="end"
            id="dropdown-sorting-button"
            title={<AiOutlineSortAscending />}
            defaultValue={perPage}
            value={perPage}
            onSelect={(e) => {
              sortOrders(e);
            }}
          >
            <Dropdown.Item
              key={89}
              eventKey={'{"orderItems.offer.reference":1}'}
            >
              Productgroep (SKU)
            </Dropdown.Item>
            <Dropdown.Item key={90} eventKey={'{"orderPlacedDateTime":-1}'}>
              Besteldatum (Nieuw - Oud)
            </Dropdown.Item>
            <Dropdown.Item key={91} eventKey={'{"orderPlacedDateTime":1}'}>
              Besteldatum (Oud - Nieuw)
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>

      {accountFilter && accountFilter !== `{"$exists":true}` && (
        <div className="orders-sub-header-filters">
          <div className="orders-sub-header-filters-title">Filters:</div>
          {props &&
            props.bolAccounts &&
            props.bolAccounts.map((bolAccount) => {
              if (bolAccount.accountSaleNumber === accountFilter) {
                return (
                  <div className="orders-sub-header-filters-item">
                    <div>{bolAccount.accountName}</div>
                    <div className="orders-sub-header-filters-item-close">
                      <FaTimes
                        onClick={() => {
                          sessionStorage.removeItem(
                            "openOrdersFilterByAccount"
                          );
                          setAccountFilter(`{"$exists":true}`);
                        }}
                      />
                    </div>
                  </div>
                );
              }
            })}
        </div>
      )}
      {sortOrdersFilter &&
        sortOrdersFilter !== `{"orderPlacedDateTime":-1}` && (
          <div className="orders-sub-header-filters">
            <div className="orders-sub-header-filters-title">Sortering:</div>
            <div className="orders-sub-header-filters-item">
              <div>{sortingNameHandler(sortOrdersFilter)}</div>
              <div className="orders-sub-header-filters-item-close">
                <FaTimes
                  onClick={() => {
                    sessionStorage.removeItem("openOrdersSorting");
                    setSortOrdersFilter(`{"orderPlacedDateTime":-1}`);
                  }}
                />
              </div>
            </div>
          </div>
        )}

      {ordersToShow && (
        <div className="orders-container-header open-orders-container-header">
          <div className="checkbox-all-orders">
            <Form.Check
              type="checkbox"
              label={selectionText()}
              onClick={handleCheckedAll}
              disabled={!openOrders}
            />
          </div>
          {ordersToShow && checkedOrders.length > 0 && (
            <div className="orders-container-header-actions">
              <Button
                onClick={() => {
                  shipOrdersHandler("ALTERNATE-OPTION");
                }}
              >
                Andere verzendwijze
              </Button>
              <Button
                onClick={() => {
                  shipOrdersHandler("TNT_BRIEF");
                }}
              >
                PostNL Briefpost
              </Button>
            </div>
          )}
        </div>
      )}
      {errorMessage && (
        <div className="noOrdersContainer">
          <div className="noOrdersText">
            <div className="no-result-search">
              <GoSearch />
              {errorMessage}
            </div>
          </div>
        </div>
      )}
      {showAlertText && <div className="snackbar">{showAlertText}</div>}
      {loadingPage && (
        <div className="loadingStateContainer">
          <div>
            <div className="sweet-loading processing-page">
              <RotateLoader
                size={100}
                width={100}
                height={10}
                color={"#162954"}
                loading={loadingPage}
              />
              <span className="loadingText">{loadingPage}</span>
            </div>
          </div>
        </div>
      )}
      <div className="orders-container open-orders-container">
        {ordersToShow &&
          ordersToShow.length > 0 &&
          ordersToShow.map((order, i) => {
            return (
              <div
                key={i}
                className={
                  "o-li-item-container" +
                  (order.orderItems && order.orderItems.length > 1
                    ? " multiOrderItems"
                    : "")
                }
              >
                <OrderListItemOpen
                  key={i}
                  order={order}
                  handleChecked={handleChecked}
                  cancelOrderItem={handleCanceledOrderItem}
                  cancellationRequestHandler={cancellationRequestHandler}
                />
              </div>
            );
          })}
        {ordersToShow && ordersToShow.length === 0 && (
          <div className="noOrdersContainer">
            <div className="noOrdersText">
              {searchQuery === "" ? (
                "Alle orders zijn ingepakt, goed zo!"
              ) : (
                <div className="no-result-search">
                  <GoSearch />
                  Geen resultaat
                </div>
              )}
            </div>
          </div>
        )}
        {loadingState && (
          <div className="loading-container">
            <div className="loading-container-title">{loadingState}</div>
            <RotateLoader
              size={100}
              width={100}
              height={10}
              color={"#fff"}
              loading={loadingState}
            />
          </div>
        )}
      </div>
      {ordersToShow && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={5}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      )}
      <AssistantToggleButton />
    </React.Fragment>
  );
};

export default OrdersFbrDbr;
