import React, { useState, useContext, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { AuthContext } from "../../context/main/auth-context";
import getAllUsers from "../../api/mongodb/get-users";

import { RiCloseCircleLine } from "react-icons/ri";
import { GiStrong } from "react-icons/gi";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";

import "../../css/orders/header.css";

const AssistantModal = (props) => {
  const auth = useContext(AuthContext);
  const [assistant, setAssistant] = useState(false);
  const [users, setUsers] = useState(false);

  useEffect(() => {
    (async () => {
      let assistantStored = JSON.parse(sessionStorage.getItem("assistant"));
      if (assistantStored) {
        setAssistant(assistantStored);
      }
      let users = await getAllUsers(auth.token);
      users.users = users.users.filter((user) => user.id !== auth.userId);
      setUsers(users.users);
    })();
  }, [auth.token]);

  console.log(auth);

  const assistantHandler = (user) => {
    if (!user) {
      sessionStorage.removeItem("assistant");
    } else {
      sessionStorage.setItem("assistant", JSON.stringify(user));
    }
    setAssistant(user);
    props.updateAssistant(user);
    props.collapseAssistantModal(false);
  };

  return (
    <div className="assistant-modal">
      <div className="assistant-modal-container">
        <div className="assistant-modal-container-header">
          <GiStrong /> Samen staan we sterker <GiStrong />
          <span className="assistant-modal-close-button">
            <RiCloseCircleLine
              onClick={() => {
                props.collapseAssistantModal(false);
              }}
            />
          </span>
        </div>
        <div className="assistant-modal-body">
          <div className="assistant-modal-body-title">
            {!assistant ? "Je hebt geen assistent" : "Je assistent is"}
          </div>

          <DropdownButton
            id="filter-dropdown-button"
            title={
              assistant
                ? [
                    assistant.firstName,
                    " ",
                    assistant.surname,
                    <MdOutlineArrowDropDownCircle />,
                  ]
                : ["Geen assistent", <MdOutlineArrowDropDownCircle />]
            }
          >
            <Dropdown.Item
              eventKey={assistant}
              onClick={() => assistantHandler(false)}
            >
              Geen assistent
            </Dropdown.Item>
            {users &&
              users.map((user, e) => {
                // if (user.id !== auth.userId)
                return (
                  <Dropdown.Item
                    eventKey={user}
                    key={user + e}
                    value={user}
                    onClick={() => assistantHandler(user)}
                  >
                    {user.firstName + " " + user.surname}
                  </Dropdown.Item>
                );
              })}
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default AssistantModal;
