import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactImageMagnify from "react-image-magnify";
import moment from "moment";
import "moment/locale/nl";
import {
	FaExternalLinkAlt,
	FaRegEnvelope,
	FaRegClipboard,
	FaPencilAlt,
	FaTimes,
} from "react-icons/fa";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";

import { AuthContext } from "../../context/main/auth-context";
import { downloadBlobFile } from "../../utils/download-blob-file";
import getShippingLabelPdf from "./get-shipping-label-pdf";

import flagNetherlands from "../../images/main/flag-netherlands.svg";
import flagBelgium from "../../images/main/flag-belgium.svg";
import postnlBolMailbox from "../../images/shipping/POSTNL-BOL-MAILBOX.png";
import postnlBolParcel from "../../images/shipping/POSTNL-BOL-PARCEL.png";
import postnlBolParcelPickup from "../../images/shipping/POSTNL-BOL-PARCEL-PICKUP.png";
import postnlBolParcelNight from "../../images/shipping/POSTNL-BOL-PARCEL-NIGHT.png";
import dhlBolMailbox from "../../images/shipping/DHL-BOL-MAILBOX.png";
import dhlBolParcel from "../../images/shipping/DHL-BOL-PARCEL.png";
import dhlBolParcelPickup from "../../images/shipping/DHL-BOL-PARCEL-PICKUP.png";
import dhlBolParcelNight from "../../images/shipping/DHL-BOL-PARCEL-NIGHT.png";

import truckIcon from "../../images/main/delivery-truck.svg";
import deadline from "../../images/main/deadline.png";
import siren from "../../images/main/siren.svg";

const OrderListItemsBolShipStep2 = (props) => {
	if (
		props &&
		props.order &&
		props.order.orderItems &&
		props.order.orderItems.length > 0
	) {
		return (
			<div className='o-li-item o-li-item-ship-bol-step-2'>
				<div className='o-li-item-ship-bol-step-2-col1'>
					<div className='o-li-item-index'>{props.index + 1}</div>
					<div className='o-li-item-country-flag'>
						{(() => {
							if (props.order.shipmentDetails.countryCode === "NL") {
								return <img src={flagNetherlands} alt='NL' />;
							} else if (props.order.shipmentDetails.countryCode === "BE") {
								return <img src={flagBelgium} alt='BE' />;
							} else {
								return props.order.shipmentDetails.countryCode;
							}
						})()}
					</div>
				</div>
				<div className='o-li-item-content'>
					{props.order.frontEndChosenDeliveryOption.orderItems.map(
						(orderItem, i) => {
							if (orderItem.checked === false) {
								return false;
							}
							return (
								<OrderLayOutBolShipStep2
									order={props.order}
									handleChecked={props.handleChecked}
									handleShippingMethodChange={props.handleShippingMethodChange}
									orderItem={orderItem}
									key={i}
								/>
							);
						}
					)}
					{!props.order.frontEndChosenDeliveryOption.orderItems ||
						(props.order.frontEndChosenDeliveryOption.orderItems.length === 0 && (
							<div className='o-li-item-content-to-remove'>
								<div>
									<div className='background-alert-red'>
										Order is niet correct aangevinkt
										<br />
										Ga een stap terug en verwijder deze order
									</div>
								</div>
							</div>
						))}
				</div>
				<div className='o-li-item-actions'>
					<div className='o-li-item-actions-shipment-method'>
						{props.order.frontEndChosenDeliveryOption.displayName}
					</div>
					{props.order.inputOwnReference && (
						<div className='o-li-item-actions-own-reference'>
							<span>Eigen kenmerk: </span>
							{props.order.inputOwnReference}
						</div>
					)}
					{props.order.inputTrackAndTrace && (
						<div className='o-li-item-actions-track-and-trace'>
							<span>Track&Trace: </span>
							{props.order.inputTrackAndTrace}
						</div>
					)}
				</div>
			</div>
		);
	} else {
		return false;
	}
};

const OrderLayOutBolShipStep2 = (props) => {
	return (
		<React.Fragment>
			<div
				className={`o-li-item-data-step-2 ${
					props.orderItem.cancellationRequest ? " o-li-item-cancel-request" : ""
				}`}
			>
				<div className='o-li-item-product-image-step-2 o-li-item-data-item-order-image'>
					{props.orderItem.productData ? (
						<ReactImageMagnify
							{...{
								smallImage: {
									alt: "productimage",
									isFluidWidth: true,
									src:
										process.env.REACT_APP_ASSET_URL +
										"/s3/" +
										props.orderItem.productData.image,
									width: 80,
									height: 80,
								},
								largeImage: {
									isFluidWidth: true,
									src:
										process.env.REACT_APP_ASSET_URL +
										"/s3/" +
										props.orderItem.productData.image,
									width: 250,
									height: 325,
								},
								hoverDelayInMs: 10,
								hoverOffDelayInMs: 10,
								enlargedImagePosition: "beside",
								enlargedImageContainerDimensions: {
									width: "280%",
									height: "280%",
								},
							}}
						/>
					) : (
						""
					)}
				</div>

				<div className='o-li-item-product-title-step-2'>
					{props.orderItem.cancellationRequest && (
						<div className='cancel-request-warning'>Annulerinsverzoek</div>
					)}
					{!props.orderItem.cancellationRequest && (
						<a
							target='_blank'
							rel='noopener noreferrer'
							href={`https://www.bol.com/nl/s/?searchtext=${props.orderItem.product.ean}&searchContext=media_all&appliedSearchContextId=&suggestFragment=&adjustedSection=&originalSection=&originalSearchContext=&section=main&N=0&defaultSearchContext=media_all`}
						>
							<FaExternalLinkAlt />
						</a>
					)}
					{!props.orderItem.cancellationRequest && props.orderItem.product.title}
				</div>

				<div className='o-li-item-data-item-step-2 o-li-item-item-order-number-step-2'>
					<span>#{props.order.orderId}</span>
				</div>

				<div className='o-li-item-data-item-step-2 o-li-item-product-price-step-2'>
					<span>
						€{" "}
						{(props.orderItem.unitPrice * props.orderItem.quantity)
							.toFixed(2)
							.replace(".", ",")}
					</span>
				</div>
				<div className='o-li-item-data-item-step-2 o-li-item-product-quantity-step-2'>
					<span>{props.orderItem.quantity}x</span>
				</div>
				<div className='o-li-item-data-item-step-2 o-li-item-product-reference-step-2'>
					<span>
						{props.orderItem.productData && props.orderItem.productData.title
							? props.orderItem.productData.title
							: props.orderItem.offer.reference}{" "}
					</span>
				</div>
			</div>
		</React.Fragment>
	);
};

export default OrderListItemsBolShipStep2;
