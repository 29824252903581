import React, { useRef, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import noImgAvailable from "../../images/main/no-image-available.png";

const ImageUpload = (props) => {
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [previewUrlHover, setPreviewUrlHover] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    props.onInput(props.id, pickedFile, fileIsValid);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const previewUrlHoverHandler = () => {
    setPreviewUrlHover(!previewUrlHover);
  };
  console.log(props)

  return (
    <div className="form-control">
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept=".jpg,.png,.jpeg"
        onChange={pickedHandler}
      />
      <div
        className={`imageUpload-edit ${props.center && "center"}`}
        onMouseEnter={previewUrlHoverHandler}
        onMouseLeave={previewUrlHoverHandler}
      >
        {previewUrlHover && (
          <div className="imageUpload-edit-hover" onClick={pickImageHandler}>
            Wijzig
          </div>
        )}
        <div className="imageUploadPreview" onClick={pickImageHandler}>
          {previewUrl && <img src={previewUrl} alt="Preview" />}
          {!previewUrl && (
            <img
              src={
                process.env.REACT_APP_ASSET_URL + "/s3/" + props.accountImage
              }
              alt="Preview"
            />
          )}
        </div>
      </div>
      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default ImageUpload;
