import React, { useState, useEffect, useContext } from "react";
import { Form, NavLink, ListGroup, Button } from "react-bootstrap";

import { AuthContext } from "../../context/main/auth-context";
import "../../css/admin/admin.css";

import BolAccountListItem from "../../components/admin/bol-account-list-item";

const BolAccounts = () => {
	const auth = useContext(AuthContext);

	const [accounts, setAccounts] = useState();

	const getAccounts = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${auth.token}`,
			},
		};

		const responseData = await fetch(
			process.env.REACT_APP_ASSET_URL + "/bol/credentials",
			requestOptions
		);

		if (responseData.ok) {
			let bolAccounts = await responseData.json();
			setAccounts(bolAccounts.accounts);
			return bolAccounts;
		} else {
			let error = await responseData.json();
			console.log(error);
			// setErrorMessage(error.message);
		}
	};

	useEffect(() => {
		getAccounts();
	}, []);

	return (
		<React.Fragment>
			<div className='title-header'>Bol accounts</div>
			{accounts ? (
				<div className='bol-accounts-overview'>
					{accounts.map((account) => {
						return (
							<div>
								<BolAccountListItem key={account.id} account={account} />
							</div>
						);
					})}
				</div>
			) : (
				<div>Geen accounts gevonden</div>
			)}
		</React.Fragment>
	);
};

export default BolAccounts;
