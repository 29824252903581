import React, { useState, useEffect, useMemo, memo } from "react";
import {
	useDymoCheckService,
	useDymoFetchPrinters,
	useDymoOpenLabel,
	dymoRequestBuilder,
} from "react-dymo-hooks";

import { generateXml99010 } from "../dymo/labels/99010";
import { generateXml11352 } from "../dymo/labels/11352";
import { generateXml99010woo } from "../dymo/labels/99010-woo";
import { generateXml11352woo } from "../dymo/labels/11352-woo";

export function DymoPrintersList(props) {
	const statusDymoService = useDymoCheckService();
	const { statusFetchPrinters, printers } =
		useDymoFetchPrinters(statusDymoService);
	let printerList = printers;
	return printerList;
}

export const printLabels = async (
	printer,
	labelName,
	shipmentArr,
	twinTurboRoll
) => {
	for (let order of shipmentArr) {
		let woocommerceOrder = false;
		let shopifyOrder = false;
		let amazonOrder = false;

		if (
			order.origin &&
			order.origin.account.platform.toLowerCase() === "woocommerce"
		) {
			woocommerceOrder = true;
		} else if (
			order.origin &&
			order.origin.account.platform.toLowerCase() === "shopify"
		) {
			shopifyOrder = true;
		} else if (
			order.origin &&
			order.origin.account.platform.toLowerCase() === "amazon"
		) {
			amazonOrder = true;
		}

		let shipmentDetails = order.shipmentDetails;
		let labelXml;
		let printParams = `<LabelWriterPrintParams><TwinTurboRoll>${twinTurboRoll}</TwinTurboRoll></LabelWriterPrintParams>`;

		if (labelName === "label99010") {
			if (woocommerceOrder) {
				labelXml = generateXml99010woo(shipmentDetails);
			} else {
				labelXml = generateXml99010(shipmentDetails);
			}
		} else if (labelName === "label11352") {
			if (woocommerceOrder) {
				labelXml = generateXml11352woo(shipmentDetails);
			} else {
				labelXml = generateXml11352(shipmentDetails);
			}
		}

		const params = {
			data: `printerName=${encodeURIComponent(
				printer.modelName
			)}&printParamsXml=${encodeURIComponent(
				printParams
			)}&labelXml=${encodeURIComponent(labelXml)}&labelSetXml=`,
		};

		await dymoRequestBuilder({
			method: "POST",
			wsAction: "printLabel",
			axiosOtherParams: params,
		}).then((res) => {
			console.log(res);
			return res;
		});
	}
};

// let printParams = `<LabelWriterPrintParams><Copies>2</Copies><PrintQuality>Text</PrintQuality><TwinTurboRoll>Right</TwinTurboRoll></LabelWriterPrintParams>`;
