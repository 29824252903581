const countAccountOpenOrdersFbr = async (account, token) => {
    let accountData = {
      accountName: account.accountName,
      mongoDbId: account._id,
      accountSaleNumber: account.accountSaleNumber,
    };
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ account: accountData }),
    };
  
    const responseData = await fetch(
      process.env.REACT_APP_ASSET_URL + `/bol/orders/open/fbr/count-orders/account`,
      requestOptions
    );

    if (responseData.ok) {
      return await responseData.json();
    } else {
      let error = await responseData.json();
      console.log(error);
      return error;
    }
  };
  
  export default countAccountOpenOrdersFbr;
  