import React, { useState, Suspense, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
} from "react-router-dom";

import "@fontsource/lato"; // Defaults to weight 400.

import "./css/main/main.css";

import { useAuth } from "./hooks/main/auth-hook";
import { AuthContext } from "./context/main/auth-context";

import Header from "./components/main/header.js";
import Sidebar from "./components/main/sidebar.js";
import RegisterBolAccount from "./pages/admin/Register-bol-account";
import BolAccounts from "./pages/admin/Bol-accounts";

import countAccountOpenOrdersFbr from "./components/bol/count-account-open-orders-fbr";
import countAllOpenOrders from "./components/orders/count-all-open-orders";
// const OrdersOpenFbrDbb = React.lazy(() => import("./pages/bol/Orders-open-fbr-dbb"));
import OrdersOpenFbrDbb from "./pages/bol/Orders-open-fbr-dbb";
// const OrdersOpenFbrDbr = React.lazy(() => import("./pages/bol/Orders-open-fbr-dbr"));
import OrdersOpenFbrDbr from "./pages/bol/Orders-open-fbr-dbr";
import OrdersFbrDbrShipStep1 from "./pages/bol/Orders-fbr-dbr-ship-step-1";
import OrdersFbrDbrShipStep2 from "./pages/bol/Orders-fbr-dbr-ship-step-2";
import OrdersFbrDbrShipStep3 from "./pages/bol/Orders-fbr-dbr-ship-step-3";
import OrdersOpenManualAdded from "./pages/orders/Orders-open-manual-added";
import OrdersManualAddedShipStep1 from "./pages/orders/Orders-manual-added-ship-step-1";
import OrdersManualAddedShipStep2 from "./pages/orders/Orders-manual-added-ship-step-2";
import OrdersManualAddedShipStep3 from "./pages/orders/Orders-manual-added-ship-step-3";

import OrdersOpenWoocommerce from "./pages/woocommerce/Orders-open-woocommerce";
import OrdersWoocommerceShipStep1 from "./pages/woocommerce/Orders-woo-ship-step-1";
import OrdersWoocommerceShipStep2 from "./pages/woocommerce/Orders-woo-ship-step-2";
import OrdersWoocommerceShipStep3 from "./pages/woocommerce/Orders-woo-ship-step-3";

import OrdersOpenShopify from "./pages/shopify/Orders-open-shopify";
import OrdersShopifyShipStep1 from "./pages/shopify/Orders-shopify-ship-step-1";
import OrdersShopifyShipStep2 from "./pages/shopify/Orders-shopify-ship-step-2";
import OrdersShopifyShipStep3 from "./pages/shopify/Orders-shopify-ship-step-3";

const Login = React.lazy(() => import("./pages/main/Login"));
const Dashboard = React.lazy(() => import("./pages/main/Dashboard"));

const CreateOrderManually = React.lazy(() =>
  import("./pages/orders/Create-order-manually")
);
const OrdersShipped = React.lazy(() => import("./pages/orders/Orders-shipped"));
const UserProfile = React.lazy(() => import("./pages/user/Profile"));
const Users = React.lazy(() => import("./pages/admin/Users"));
const RegisterUser = React.lazy(() => import("./pages/admin/Register-user"));
const Products = React.lazy(() => import("./pages/products/Products"));
const ProductsBulkEditorV1 = React.lazy(() =>
  import("./pages/products/Products-bulk-editor-v1")
);
const ProductsBulkEditorV2 = React.lazy(() =>
  import("./pages/products/Products-bulk-editor-v2")
);
const RegisterProduct = React.lazy(() =>
  import("./pages/products/Register-product")
);
const Categories = React.lazy(() => import("./pages/products/Categories"));
const RegisterCategory = React.lazy(() =>
  import("./pages/products/Register-category")
);

const App = () => {
  const {
    userId,
    token,
    login,
    logout,
    surname,
    firstName,
    username,
    image,
    role,
  } = useAuth();

  const [sidebarStatus, setSidebarStatus] = useState(true);
  const closeSidebarHandler = (state) => {
    setSidebarStatus(state);
  };
  const openSidebarHandler = (state) => {
    setSidebarStatus(state);
  };

  const [accounts, setAccounts] = useState();
  const [accountsWithOrdersCountFbr, setAccountsWithOrdersCountFbr] = useState(
    []
  );
  const [allOpenOrdersCount, setAllOpenOrdersCount] = useState(0);

  useEffect(() => {
    (async () => {
      let openOrders = await countAllOpenOrders(token);
      if (
        openOrders &&
        openOrders.ordersCount &&
        openOrders.ordersCount !== null
      ) {
        setAllOpenOrdersCount(openOrders.ordersCount);
      }
    })();
  }, [token]);

  useEffect(() => {
    const getAccounts = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const responseData = await fetch(
        process.env.REACT_APP_ASSET_URL + "/bol/credentials",
        requestOptions
      );

      if (responseData.ok) {
        let bolAccounts = await responseData.json();
        setAccounts(bolAccounts.accounts);
        return bolAccounts;
      } else {
        let error = await responseData.json();
        console.log(error);
      }
    };

    if (token) {
      getAccounts();
    }
  }, [token]);

  useEffect(() => {
    (async () => {
      if (accounts) {
        for await (let account of accounts) {
          let openOrdersCountFbr = await countAccountOpenOrdersFbr(
            account,
            token
          );
          if (openOrdersCountFbr.ordersCount) {
            setAccountsWithOrdersCountFbr((prevState) => [
              ...prevState,
              {
                account: account,
                openOrdersCountFbr: openOrdersCountFbr.ordersCount,
              },
            ]);
          }
        }
      }
    })();
  }, [accounts, token]);

  let routes;
  if (!token) {
    routes = (
      <Routes>
        <Route exact="true" path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route exact="true" path="/" element={<Dashboard />} />
        {/* <Route exact path="/recovery-password" element={<RecoveryPassword />} /> */}
        <Route exact="true" path="/user-profile" element={<UserProfile />} />
        <Route exact="true" path="/users" element={<Users />} />
        <Route exact="true" path="/register-user" element={<RegisterUser />} />
        <Route exact="true" path="/bol-accounts" element={<BolAccounts />} />
        <Route
          exact="true"
          path="/orders/bol/fbr/dbr"
          element={<OrdersOpenFbrDbr bolAccounts={accounts} />}
        />
        <Route
          exact="true"
          path="/orders/bol/fbr/dbb"
          element={<OrdersOpenFbrDbb bolAccounts={accounts} />}
        />
        <Route
          exact="true"
          path="/orders/bol/fbr/dbr/ship/step-1"
          element={<OrdersFbrDbrShipStep1 />}
        />
        <Route
          exact="true"
          path="/orders/bol/fbr/dbr/ship/step-2"
          element={<OrdersFbrDbrShipStep2 />}
        />
        <Route
          exact="true"
          path="/orders/bol/fbr/dbr/ship/step-3"
          element={<OrdersFbrDbrShipStep3 />}
        />
        <Route
          exact="true"
          path="/orders/woocommerce"
          element={<OrdersOpenWoocommerce />}
        />
        <Route
          exact="true"
          path="/orders/woocommerce/ship/step-1"
          element={<OrdersWoocommerceShipStep1 />}
        />
        <Route
          exact="true"
          path="/orders/woocommerce/ship/step-2"
          element={<OrdersWoocommerceShipStep2 />}
        />
        <Route
          exact="true"
          path="/orders/woocommerce/ship/step-3"
          element={<OrdersWoocommerceShipStep3 />}
        />
        <Route
          exact="true"
          path="/orders/shopify"
          element={<OrdersOpenShopify />}
        />
        <Route
          exact="true"
          path="/orders/shopify/ship/step-1"
          element={<OrdersShopifyShipStep1 />}
        />
        <Route
          exact="true"
          path="/orders/shopify/ship/step-2"
          element={<OrdersShopifyShipStep2 />}
        />
        <Route
          exact="true"
          path="/orders/shopify/ship/step-3"
          element={<OrdersShopifyShipStep3 />}
        />

        <Route
          exact="true"
          path="/orders/manual-added"
          element={<OrdersOpenManualAdded />}
        />
        <Route
          exact="true"
          path="/orders/manual-added/ship/step-1"
          element={<OrdersManualAddedShipStep1 />}
        />
        <Route
          exact="true"
          path="/orders/manual-added/ship/step-2"
          element={<OrdersManualAddedShipStep2 />}
        />
        <Route
          exact="true"
          path="/orders/manual-added/ship/step-3"
          element={<OrdersManualAddedShipStep3 />}
        />
        <Route
          exact="true"
          path="/orders/shipped"
          element={<OrdersShipped bolAccounts={accounts} />}
        />
        <Route
          exact="true"
          path="/orders/create-order"
          element={<CreateOrderManually />}
        />
        <Route
          exact="true"
          path="/register-bol-account"
          element={<RegisterBolAccount />}
        />
        <Route exact="true" path="/products" element={<Products />} />
        <Route
          exact="true"
          path="/products/bulk-editor-v1"
          element={<ProductsBulkEditorV1 />}
        />
        <Route
          exact="true"
          path="/products/bulk-editor-v2"
          element={<ProductsBulkEditorV2 />}
        />
        <Route
          exact="true"
          path="/register-product"
          element={<RegisterProduct />}
        />
        <Route exact="true" path="/categories" element={<Categories />} />
        <Route
          exact="true"
          path="/register-category"
          element={<RegisterCategory />}
        />

        <Route path="*" element={<Dashboard />} />
      </Routes>
    );
  }

  return (
    <div className="App">
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          login: login,
          logout: logout,
          surname: surname,
          firstName: firstName,
          username: username,
          image: image,
          role: role,
        }}
      >
        <Router>
          <Sidebar
            sidebarState={sidebarStatus}
            closeSidebar={closeSidebarHandler}
            accountsWithOrdersCountFbr={accountsWithOrdersCountFbr}
            allOpenOrdersCount={allOpenOrdersCount}
          />
          <Header
            sidebarState={sidebarStatus}
            openSidebar={openSidebarHandler}
            accountsWithOrdersCountFbr={accountsWithOrdersCountFbr}
            allOpenOrdersCount={allOpenOrdersCount}
          />

          <Suspense fallback>
            <div className="page-container">{routes}</div>
          </Suspense>
        </Router>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
