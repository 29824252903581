import React, { useState } from "react";

import BolEditAccount from "./bol-edit-account";

const BolAccountListItem = (props) => {
  const [account, setAccount] = useState(props.account);
  const [hovered, setHovered] = useState(false);
  const [contentToShow, setContentToShow] = useState("bol-accounts-overview");

  const toggleHover = () => setHovered(!hovered);
  const switchContent = (content) => {
    setContentToShow(content);
  };

  return (
    <React.Fragment>
      {contentToShow === "bol-accounts-overview" && (
        <div
          className={
            hovered
              ? "bol-account-profile-card bol-account-profile-card-hovered"
              : "bol-account-profile-card"
          }
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          onClick={() => switchContent("edit-bol-account")}
        >
          <img
            className="bol-account-profile-card-picture"
            src={process.env.REACT_APP_ASSET_URL + "/s3/" + account.image}
            alt="pf"
          />
          <div className="bol-account-profile-card-meta">
            <div className="bol-account-profile-card-meta-field">
              <span>Account:</span>
              <span>{account.accountName}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>Bol verkoopnummer:</span>
              <span>{account.accountSaleNumber}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>Adres:</span>
              <span>{account.address}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>Huisnummer:</span>
              <span>{account.houseNumber}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>Postcode:</span>
              <span>{account.zipCode}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>Woonplaats:</span>
              <span>{account.city}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>Land:</span>
              <span>{account.country}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>BTW.nr.:</span>
              <span>{account.btw}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>KVK.nr.:</span>
              <span>{account.kvk}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>IBAN.nr.:</span>
              <span>{account.iban}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>E-mail:</span>
              <span>{account.email}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>Tel.:</span>
              <span>{account.phone}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>Aut. Factuur Emails:</span>
              <span>{account.invoiceEmail ? "Ja" : "Nee"}</span>
            </div>
            <div className="bol-account-profile-card-meta-field">
              <span>Aut. Review Emails:</span>
              <span>{account.reviewEmail ? "Ja" : "Nee"}</span>
            </div>
          </div>
        </div>
      )}
      {contentToShow === "edit-bol-account" && (
        <BolEditAccount account={account} />
      )}
    </React.Fragment>
  );
};

export default BolAccountListItem;
