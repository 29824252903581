import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../context/main/auth-context";

import { Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { AiOutlinePoweroff } from "react-icons/ai";
import { VscAccount } from "react-icons/vsc";

const Navigation = (props) => {
  const auth = useContext(AuthContext);
  const [accountsWithOrders, setAccountsWithOrders] = useState();

  useEffect(() => {
    // console.log(props.accountsWithOrders);
    setAccountsWithOrders(props.accountsWithOrders);
  }, [props.accountsWithOrders]);

  const logoutHandler = () => {
    auth.logout();
  };

  if (!auth.isLoggedIn) {
    return <Nav className="navigation-header"></Nav>;
  } else {
    return (
      <Nav className="navigation-header">
        <div className="navigation-header-left">
          {!props.sidebarState && (
            <React.Fragment>
              <NavLink to="/" className="nav-link" exact="true">
                Dashboard
              </NavLink>
              <NavDropdown
                title="Bol accounts"
                id="dropDownBasic"
                className="dropdown-accounts"
              >
                {accountsWithOrders &&
                  accountsWithOrders.map((accountWithOrder, i) => {
                    return (
                      <NavDropdown.Item key={i}>
                        <NavLink className="nav-link nav-link-accounts" to={``}>
                          <span>{accountWithOrder.account.accountName}</span>
                          <span className="badge">
                            {accountWithOrder.openOrdersFbr.length}
                          </span>
                        </NavLink>
                      </NavDropdown.Item>
                    );
                  })}
              </NavDropdown>
              <NavDropdown title="Voorraadbeheer" id="dropDownBasic">
                <NavDropdown.Item>
                  <NavLink className="nav-link" to={``}>
                    Artikelen
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink className="nav-link" to={``}>
                    Categorieën
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Admin" id="dropDownBasic">
                <NavDropdown.Item>
                  <NavDropdown title="Medewerkers" id="dropDownBasic">
                    <NavDropdown.Item>
                      <NavLink className="nav-link" to={``}>
                        Account 1
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink className="nav-link" to={``}>
                        Account 2
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Bol Accounts" id="dropDownBasic">
                    <NavDropdown.Item>
                      <NavLink className="nav-link" to={``}>
                        Account 1
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink className="nav-link" to={``}>
                        Account 2
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown.Item>
              </NavDropdown>
            </React.Fragment>
          )}
        </div>
        <div className="navigation-header-right">
          <NavLink to="/user-profile" className="nav-link" exact="true">
            <VscAccount />
          </NavLink>
          <AiOutlinePoweroff onClick={() => logoutHandler()} />
        </div>
      </Nav>
    );
  }
};

export default Navigation;
