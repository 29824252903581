import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { RotateLoader } from "react-spinners";

import { FaTimes } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { AiOutlineSortAscending } from "react-icons/ai";
import { AuthContext } from "../../context/main/auth-context";
import "../../css/orders/orders.css";

import getAllOpenOrdersManualAdded from "../../components/orders/get-all-open-orders-manual-added";
import OrdersHeader from "../../components/orders/orders-header";
import OrderListItemOpen from "../../components/orders/order-list-item-open";
import AssistantToggleButton from "../../components/main/assistant-toggle-button";

const OrdersOpenManualAdded = (props) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const sortingNameHandler = (input) => {
    if (input === `{"orderItems.product.productData.sku":1}`) {
      return "Productgroep (SKU)";
    } else if (input === `{"orderPlacedDateTime":1}`) {
      return "Besteldatum (Oud - Nieuw)";
    } else {
      return "Besteldatum (Nieuw - Oud)";
    }
  };
  const sortingHandler = () => {
    let sortingOrders = JSON.parse(sessionStorage.getItem("openOrdersSorting"));
    if (sortingOrders) {
      return sortingOrders;
    } else {
      return `{"orderPlacedDateTime":-1}`;
    }
  };

  const sortOrders = (sorting) => {
    if (sorting) {
      sessionStorage.setItem("openOrdersSorting", JSON.stringify(sorting));
    }
    setSortOrdersFilter(sorting);
  };

  const [errorMessage, setErrorMessage] = useState(false);
  const [openOrders, setOpenOrders] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [ordersToShow, setOrdersToShow] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [recountOrders, setRecountOrders] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showAlertText, setShowAlertText] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrdersFilter, setSortOrdersFilter] = useState(sortingHandler);

  useEffect(() => {
    (async () => {
      setLoadingState("Bestellingen worden geladen");

      let openOrders = await getAllOpenOrdersManualAdded(
        auth.token,
        sortOrdersFilter
      );

      if (openOrders && openOrders.orders && openOrders.orders.length > 0) {
        setErrorMessage(false);

        const slicedOrders = openOrders.orders.slice(offset, offset + perPage);

        setPageCount(Math.ceil(openOrders.orders.length / perPage));
        setOffset(0);
        setOrdersToShow(slicedOrders);
        setOpenOrders(openOrders.orders);
        setLoadingState(false);
        setErrorMessage(false);
      } else {
        setOpenOrders(0);
        setErrorMessage("Geen openstaande orders gevonden");
        setOrdersToShow(false);
        setLoadingState(false);
      }
    })();
  }, [auth.token, sortOrdersFilter]);

  const handlePageClick = async (e) => {
    let allCheckboxes = Array.from(
      document.querySelectorAll(".o-li-item input, .checkbox-all-orders  input")
    );
    allCheckboxes.map((checkbox) => {
      if (checkbox.checked === true) {
        checkbox.click();
      }
    });

    setCheckedOrders([]);
    setLoadingState("Bestellingen worden geladen");
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(offset);
    const slicedOrders = openOrders.slice(offset, offset + perPage);
    setOrdersToShow(slicedOrders);
    setLoadingState(false);
    setSearchQuery("");
  };

  const selectionText = () => {
    if (checkedOrders.length === 0) {
      return "(0)";
    } else {
      return "(" + checkedOrders.length + ")";
    }
  };

  const handleCheckedAll = (e) => {
    let allCheckboxes = Array.from(
      document.querySelectorAll(".o-li-item input")
    );

    if (e.target.checked) {
      allCheckboxes.forEach((checkbox) => {
        if (checkbox.checked === false) {
          checkbox.click();
        }
      });
      setCheckedOrders(ordersToShow);
    } else if (!e.target.checked) {
      allCheckboxes.forEach((checkbox) => {
        if (checkbox.checked === true) {
          checkbox.click();
        }
      });
      setCheckedOrders([]);
    }
  };

  const handleChecked = (checked, order, orderItem) => {
    let newArr = [...checkedOrders];
    if (!checked) {
      let orderFound = newArr.find((o) => o.id === order.id);
      if (!orderFound) {
        let orderItemFound = order.orderItems.find(
          (oI) => oI.product._id === orderItem.product._id
        );
        if (orderItemFound) {
          orderItem.checked = true;
        }
        setCheckedOrders((prevArr) => [...prevArr, order]);
      } else {
        let orderItemFound = orderFound.orderItems.find(
          (oI) => oI.product._id === orderItem.product._id
        );
        if (orderItemFound) {
          orderItemFound.checked = true;
        }
        setCheckedOrders(newArr);
      }
    } else {
      let newArr = [...checkedOrders];

      let i = newArr.findIndex((o) => o.id === order.id);

      if (i !== -1) {
        let j = newArr[i].orderItems.findIndex(
          (oItem) => oItem.product._id === orderItem.product._id
        );

        if (newArr[i].orderItems.length <= 1) {
          newArr.splice(i, 1);
        } else {
          if (j !== -1) {
            newArr[i].orderItems[j].checked = false;
          }

          let allUnchecked = Object.values(newArr[i].orderItems).every(
            (item) => {
              return item.checked === false;
            }
          );

          allUnchecked && newArr.splice(i, 1);
        }
        setCheckedOrders(newArr);
      }
    }
  };

  const searchHandler = (e, array) => {
    let searchArray = [...array];
    let searchArrayString = searchArray.map((order, i) => {
      return (order = JSON.stringify(order));
    });
    const matchedArray = searchArrayString.filter((element) => {
      if (element.toLowerCase().includes(e.target.value.toLowerCase())) {
        return true;
      }
      return false;
    });
    let searchArrayResult = matchedArray.map((order, i) => {
      return (order = JSON.parse(order));
    });
    setSearchQuery(e.target.value);

    setOrdersToShow(searchArrayResult);
  };

  const shipOrdersHandler = (option, order) => {
    let orders = order ? [order] : checkedOrders;
    sessionStorage.setItem("checkedOrders", JSON.stringify(orders));
    sessionStorage.setItem("shippingMethod", JSON.stringify(option));
    navigate("/orders/manual-added/ship/step-1");
  };

  return (
    <React.Fragment>
      <div className="title-header title-header-open-orders">
        <span>Bestellingen</span>
        <div>
          <Button
            onClick={() => {
              sessionStorage.removeItem("createOrder");
              navigate("/orders/create-order");
            }}
          >
            Nazending aanmaken
          </Button>
        </div>
      </div>
      <OrdersHeader recountOrders={recountOrders} />

      <div className="orders-sub-header">
        <div className="orders-sub-header-filter-count">
          ({openOrders ? openOrders.length : 0}){" "}
          <span>bestellingen gefilterd</span>
        </div>
        <div className="orders-search-wrapper">
          <label className="input-search-icon">
            <GoSearch />
          </label>
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="search-input"
            placeholder="Zoek bestelling..."
            value={searchQuery}
            onChange={(e) => searchHandler(e, openOrders)}
          />
        </div>
        <div className="orders-sub-header-filter-options">
          <DropdownButton
            className="filter-open-orders-dropdown"
            align="end"
            id="dropdown-sorting-button"
            title={<AiOutlineSortAscending />}
            defaultValue={perPage}
            value={perPage}
            onSelect={(e) => {
              sortOrders(e);
            }}
          >
            <Dropdown.Item
              key={89}
              eventKey={'{"orderItems.offer.reference":1}'}
            >
              Productgroep (SKU)
            </Dropdown.Item>
            <Dropdown.Item key={90} eventKey={'{"orderPlacedDateTime":-1}'}>
              Besteldatum (Nieuw - Oud)
            </Dropdown.Item>
            <Dropdown.Item key={91} eventKey={'{"orderPlacedDateTime":1}'}>
              Besteldatum (Oud - Nieuw)
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>

      {sortOrdersFilter && sortOrdersFilter !== `{"orderPlacedDateTime":-1}` && (
        <div className="orders-sub-header-filters">
          <div className="orders-sub-header-filters-title">Sortering:</div>
          <div className="orders-sub-header-filters-item">
            <div>{sortingNameHandler(sortOrdersFilter)}</div>
            <div className="orders-sub-header-filters-item-close">
              <FaTimes
                onClick={() => {
                  sessionStorage.removeItem("openOrdersSorting");
                  setSortOrdersFilter(`{"orderPlacedDateTime":-1}`);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {ordersToShow && (
        <div className="orders-container-header open-orders-container-header">
          <div className="checkbox-all-orders">
            <Form.Check
              type="checkbox"
              label={selectionText()}
              onClick={handleCheckedAll}
              disabled={!openOrders}
            />
          </div>
          {ordersToShow && checkedOrders.length > 0 && (
            	<div className='orders-container-header-actions'>
							<Button
								onClick={() => {
									shipOrdersHandler("TNT_BRIEF");
								}}
							>
								PostNL Briefpost
							</Button>
							<Button
								onClick={() => {
									shipOrdersHandler("POSTNL_3085");
								}}
							>
								PostNL Pakket Standaard
							</Button>
							<Button
								onClick={() => {
									shipOrdersHandler("POSTNL_2928");
								}}
							>
								PostNL Brievenbuspakje
							</Button>
						</div>
          )}
        </div>
      )}
      {errorMessage && (
        <div className="noOrdersContainer">
          <div className="noOrdersText">
            <div className="no-result-search">
              <GoSearch />
              {errorMessage}
            </div>
          </div>
        </div>
      )}
      {showAlertText && <div className="snackbar">{showAlertText}</div>}
      {loadingPage && (
        <div className="loadingStateContainer">
          <div>
            <div className="sweet-loading processing-page">
              <RotateLoader
                size={100}
                width={100}
                height={10}
                color={"#162954"}
                loading={loadingPage}
              />
              <span className="loadingText">{loadingPage}</span>
            </div>
          </div>
        </div>
      )}
      <div className="orders-container open-orders-container">
        {ordersToShow &&
          ordersToShow.length > 0 &&
          ordersToShow.map((order, i) => {
            return (
              <div
                key={i}
                className={
                  "o-li-item-container" +
                  (order.orderItems && order.orderItems.length > 1
                    ? " multiOrderItems"
                    : "")
                }
              >
                <OrderListItemOpen
                  key={i}
                  order={order}
                  handleChecked={handleChecked}
                />
              </div>
            );
          })}
        {ordersToShow && ordersToShow.length === 0 && (
          <div className="noOrdersContainer">
            <div className="noOrdersText">
              {searchQuery === "" ? (
                "Alle orders zijn ingepakt, goed zo!"
              ) : (
                <div className="no-result-search">
                  <GoSearch />
                  Geen resultaat
                </div>
              )}
            </div>
          </div>
        )}
        {loadingState && (
          <div className="loading-container">
            <div className="loading-container-title">{loadingState}</div>
            <RotateLoader
              size={100}
              width={100}
              height={10}
              color={"#fff"}
              loading={loadingState}
            />
          </div>
        )}
      </div>
      {ordersToShow && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={5}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      )}
      <AssistantToggleButton />
    </React.Fragment>
  );
};

export default OrdersOpenManualAdded;
