import dayjs from "dayjs";
import("dayjs/locale/nl");

const generatePdfPackingSlip = async (token, orders) => {
	if (token && orders) {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				orders: orders,
			}),
		};
		try {
			await fetch(
				process.env.REACT_APP_ASSET_URL + `/bol/orders/create-packing-slip`,
				requestOptions
			)
				.then(async (res) => ({
					filename: `Paklijst-${dayjs().format("DD/MM/YYYY-H:m:s")}`,
					blob: await res.blob(),
				}))
				.then((resObj) => {
					const newBlob = new Blob([resObj.blob], { type: "application/pdf" });
					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
						window.navigator.msSaveOrOpenBlob(newBlob);
					} else {
						const objUrl = window.URL.createObjectURL(newBlob);
						let link = document.createElement("a");
						link.href = objUrl;
						link.download = resObj.filename;
						link.click();
						setTimeout(() => {
							window.URL.revokeObjectURL(objUrl);
						}, 250);
					}
				});
		} catch (err) {
			console.log(err);
			return err;
		}
	}
};
export default generatePdfPackingSlip;
