export function generateXml11352woo(shipment) {
	var labelXml;

	if (shipment.company) {
		labelXml = `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
    <PaperOrientation>Landscape</PaperOrientation>
    <Id>LargeAddress</Id>
    <PaperName>11352 Return Address Int</PaperName>
    <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="1440" Height="3060" Rx="180" Ry="180"/>
    </DrawCommands>
    <ObjectInfo>
      <TextObject>
        <Name>TEXT</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>AlwaysFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>${shipment.company && shipment.company}
${shipment.first_name + " " + shipment.last_name}
${shipment.address_1}${shipment.address_2 ? " " + shipment.address_2 : ""}
${shipment.postcode + " " + shipment.city}
${(() => {
	if (shipment.country === "NL") {
		return "Nederland";
	} else if (shipment.country === "BE") {
		return "België";
	} else {
		return shipment.country;
	}
})()}</String>
            <Attributes>
              <Font Family="Verdana" Size="18" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="129.5999" Y="57.59995" Width="2846.4" Height="1300.8"/>
      </ObjectInfo>
  </DieCutLabel> `;
		return labelXml;
	} else {
		labelXml = `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
    <PaperOrientation>Landscape</PaperOrientation>
    <Id>LargeAddress</Id>
    <PaperName>11352 Return Address Int</PaperName>
    <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="1440" Height="3060" Rx="180" Ry="180"/>
    </DrawCommands>
    <ObjectInfo>
      <TextObject>
        <Name>TEXT</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>AlwaysFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
    <String>${shipment.first_name + " " + shipment.last_name}
${shipment.address_1}${shipment.address_2 ? " " + shipment.address_2 : ""}
${shipment.postcode + " " + shipment.city}
${(() => {
	if (shipment.country === "NL") {
		return "Nederland";
	} else if (shipment.country === "BE") {
		return "België";
	} else {
		return shipment.country;
	}
})()}</String>
            <Attributes>
              <Font Family="Verdana" Size="18" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="129.5999" Y="57.59995" Width="2846.4" Height="1300.8"/>
      </ObjectInfo>
  </DieCutLabel> `;
	}
	return labelXml;
}

// <String>${dymoName}</String>
// <String>${dymoAddress}</String>
// <String>${dymoZip + dymoCity}</String>
// <String>${dymoCountry}</String>
// <String>${dymoZip}</String>
