const getMultipleShippingLabelsPdfPostNL = async (orders, token) => {
 
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      responseType: "blob",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      orders: orders
    }),
  };

  const responseData = await fetch(
    process.env.REACT_APP_ASSET_URL + `/orders/get-multiple-shipping-labels-pdf-postnl`,
    requestOptions
  );

  if (responseData.ok) {
    return await responseData.json();
  } else {
    let error = await responseData.json();
    console.log(error);
    return error;
  }
};

export default getMultipleShippingLabelsPdfPostNL;
