import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactImageMagnify from "react-image-magnify";
import moment from "moment";
import "moment/locale/nl";
import {
  FaExternalLinkAlt,
  FaRegEnvelope,
  FaRegClipboard,
  FaPencilAlt,
  FaTimes,
} from "react-icons/fa";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";

import UpdateDeliveryOptionFbrDbb from "../../api/mongodb/update-chosen-delivery-option-fbr-dbb";

import { AuthContext } from "../../context/main/auth-context";
import { downloadBlobFile } from "../../utils/download-blob-file";
// import getShippingLabelPdf from "./get-shipping-label-pdf";

import flagNetherlands from "../../images/main/flag-netherlands.svg";
import flagBelgium from "../../images/main/flag-belgium.svg";
import postnlBolMailbox from "../../images/shipping/POSTNL-BOL-MAILBOX.png";
import postnlBolParcel from "../../images/shipping/POSTNL-BOL-PARCEL.png";
import postnlBolParcelPickup from "../../images/shipping/POSTNL-BOL-PARCEL-PICKUP.png";
import postnlBolParcelNight from "../../images/shipping/POSTNL-BOL-PARCEL-NIGHT.png";
import dhlBolMailbox from "../../images/shipping/DHL-BOL-MAILBOX.png";
import dhlBolParcel from "../../images/shipping/DHL-BOL-PARCEL.png";
import dhlBolParcelPickup from "../../images/shipping/DHL-BOL-PARCEL-PICKUP.png";
import dhlBolParcelNight from "../../images/shipping/DHL-BOL-PARCEL-NIGHT.png";

import truckIcon from "../../images/main/delivery-truck.svg";
import deadline from "../../images/main/deadline.png";
import siren from "../../images/main/siren.svg";

const OrderListItemsShopfiyOrderStep1 = (props) => {
  const [inputOwnReference, setInputOwnReference] = useState(false);
  const [inputTrackAndTrace, setInputTrackAndTrace] = useState(false);

  const shippingMethodHandler = (params) => {
    let method = JSON.parse(params);
    props.handleShippingMethodChange(method, props.index);
  };

  const removeOrderFromCheckedOrdersHandler = (orderX, orderXItem) => {
    props.handleRemoveOrderFromChecked(orderX, orderXItem);
  };

  props.order.inputOwnReference = inputOwnReference;
  props.order.inputTrackAndTrace = inputTrackAndTrace;

  if (
    props &&
    props.order &&
    props.order.orderItems &&
    props.order.orderItems.length > 0 &&
    props.order.frontEndChosenDeliveryOption
  ) {
    // soms wordt een checkbox niet aangevinkt, hier gaan we ervanuit dat het wel de bedoeling is om de zending te verwerken, aangezien we al bij stap 1 zijn. Daarom checken we of het enkel om 1 orderitem gaat en die zetten we automatisch op check
    if (
      !props.order.frontEndChosenDeliveryOption.orderItems ||
      props.order.frontEndChosenDeliveryOption.orderItems.length === 0
    ) {
      if (props.order.orderItems.length === 1) {
        props.order.orderItems[0].checked = true;
        props.order.frontEndChosenDeliveryOption.orderItems =
          props.order.orderItems;
      }
    }

    return (
      <div className="o-li-item o-li-item-ship-bol-step-1">
        <div className="o-li-item-ship-bol-step-1-col1">
          <div className="o-li-item-index">{props.index + 1}</div>
          <div className="o-li-item-country-flag">
            {(() => {
              if (
                props.order.shipmentDetails.country === "NL" ||
                props.order.shipmentDetails.country.toLowerCase() ===
                  "nederland" ||
                props.order.shipmentDetails.country.toLowerCase() ===
                  "netherlands"
              ) {
                return <img src={flagNetherlands} alt="NL" />;
              } else if (
                props.order.shipmentDetails.country === "BE" ||
                props.order.shipmentDetails.country.toLowerCase() ===
                  "belgie" ||
                props.order.shipmentDetails.country.toLowerCase() ===
                  "belgië" ||
                props.order.shipmentDetails.country.toLowerCase() === "belgium"
              ) {
                return <img src={flagBelgium} alt="BE" />;
              } else {
                return props.order.shipmentDetails.country;
              }
            })()}
          </div>
        </div>

        <div className="o-li-item-content">
          {props.order.frontEndChosenDeliveryOption.orderItems &&
            props.order.frontEndChosenDeliveryOption.orderItems.map(
              (orderItem, i) => {
                console.log(orderItem);
                if (orderItem.checked === false) {
                  return false;
                }
                return (
                  <OrderLayOutShopifyOrderShipStep1
                    order={props.order}
                    handleChecked={props.handleChecked}
                    handleShippingMethodChange={
                      props.handleShippingMethodChange
                    }
                    orderItem={orderItem}
                    key={i + orderItem.id}
                    removeOrderFromCheckedOrdersHandler={(ox, oxItem) => {
                      removeOrderFromCheckedOrdersHandler(ox, oxItem);
                    }}
                  />
                );
              }
            )}
          {!props.order.frontEndChosenDeliveryOption.orderItems ||
            (props.order.frontEndChosenDeliveryOption.orderItems.length ===
              0 && (
              <div className="o-li-item-content-to-remove">
                <div>
                  <FaTimes
                    onClick={() => {
                      removeOrderFromCheckedOrdersHandler(
                        props.order,
                        props.orderItem
                      );
                    }}
                  />
                  <div className="background-alert-red">
                    Order is niet correct aangevinkt
                    <br />
                    Verwijder deze order
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="o-li-item-actions">
          <DropdownButton
            align="end"
            id="dropdown-menu-align-end"
            title={(() => {
              let title = [<MdOutlineArrowDropDownCircle />];
              if (
                props.order.frontEndChosenDeliveryOption &&
                props.order.frontEndChosenDeliveryOption.transporterCode
              ) {
                if (
                  props.order.frontEndChosenDeliveryOption.transporterCode ===
                    "ALTERNATE-OPTION" ||
                  props.order.frontEndChosenDeliveryOption.transporterCode ===
                    "BOL-SHIPPING-LABEL"
                ) {
                  title.unshift("Kies verzendmethode");
                } else if (
                  props.order.frontEndChosenDeliveryOption.transporterCode ===
                  "TNT_BRIEF"
                ) {
                  title.unshift("PostNL - Briefpost");
                } else {
                  title.unshift(
                    props.order.frontEndChosenDeliveryOption.displayName
                  );
                }
                return title;
              }
            })()}
            onSelect={(e) => {
              shippingMethodHandler(e);
            }}
          >
            {props.transporterCodes &&
              props.transporterCodes.map((tc) => {
                return (
                  <Dropdown.Item
                    key={JSON.stringify(tc)}
                    eventKey={JSON.stringify(tc)}
                  >
                    {tc.displayName}
                  </Dropdown.Item>
                );
              })}
          </DropdownButton>
          <div>
            <input
              value={inputOwnReference ? inputOwnReference : ""}
              placeholder={"Eigen kenmerk"}
              onInput={(e) => setInputOwnReference(e.target.value)}
            />
          </div>
          {props.shippingMethodBulk !== "BOL-SHIPPING-LABEL" && (
            <div>
              <input
                value={inputTrackAndTrace ? inputTrackAndTrace : ""}
                placeholder="Track&Trace"
                onInput={(e) => setInputTrackAndTrace(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

const OrderLayOutShopifyOrderShipStep1 = (props) => {
  console.log(props);
  return (
    <React.Fragment>
      <div
        className={`o-li-item-data-step-1 ${
          props.orderItem.cancellationRequest ? " o-li-item-cancel-request" : ""
        }`}
      >
        <div className="o-li-item-product-remove-step-1">
          <FaTimes
            onClick={() =>
              props.removeOrderFromCheckedOrdersHandler(
                props.order,
                props.orderItem
              )
            }
          />
        </div>
        <div className="o-li-item-product-image-step-1 o-li-item-data-item-order-image">
          {props.orderItem.productData ? (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "productimage",
                  isFluidWidth: true,
                  src:
                    process.env.REACT_APP_ASSET_URL +
                    "/s3/" +
                    props.orderItem.productData.image,
                  width: 80,
                  height: 80,
                },
                largeImage: {
                  isFluidWidth: true,
                  src:
                    process.env.REACT_APP_ASSET_URL +
                    "/s3/" +
                    props.orderItem.productData.image,
                  width: 250,
                  height: 325,
                },
                hoverDelayInMs: 10,
                hoverOffDelayInMs: 10,
                enlargedImagePosition: "beside",
                enlargedImageContainerDimensions: {
                  width: "280%",
                  height: "280%",
                },
              }}
            />
          ) : (
            ""
          )}
        </div>
        <div className="o-li-item-product-title-step-1">
          <span>
            €{" "}
            {props.orderItem.price
              ? Number(props.orderItem.price).toFixed(2).replace(".", ",")
              : 0}
          </span>
        </div>

        <div className="o-li-item-data-item-step-1 o-li-item-item-order-number-step-1">
          <span>
            #{props.order.orderIdName && props.order.orderIdName}
            {props.order.orderId &&
              props.order.orderId !== "false" &&
              !props.order.orderIdName &&
              props.order.orderId}
            {!props.order.orderId &&
              !props.order.orderIdName &&
              props.order.orderIdGG}
          </span>
        </div>

        <div className="o-li-item-data-item-step-1 o-li-item-product-price-step-1"></div>
        <div className="o-li-item-data-item-step-1 o-li-item-product-quantity-step-1">
          <span>{props.orderItem.quantity}x</span>
        </div>
        <div className="o-li-item-data-item-step-1 o-li-item-product-reference-step-1">
          <span>
            {props.orderItem.productData && props.orderItem.productData.title
              ? props.orderItem.productData.title
              : props.orderItem.title}
          </span>
        </div>
        {props.order.notes && props.order.notes.length > 0 && (
          <div className="o-li-item-data-item-step-1 o-li-item-data-item-order-notes">
            <label>Notities</label>
            <div className="o-li-item-data-item-order-notes-container">
              {props.order.notes.map((item) => {
                return (
                  <div>
                    <span>{item.user.firstName}:</span>
                    <p>{item.note}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default OrderListItemsShopfiyOrderStep1;
