import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { RotateLoader } from "react-spinners";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";

import { AuthContext } from "../../context/main/auth-context";
import "../../css/orders/orders.css";

import OrdersHeader from "../../components/orders/orders-header";
import getProductBySku from "../../api/mongodb/get-product-by-sku";
import OrderListItemWooOrderShipStep3 from "../../components/woocommerce/order-list-item-woo-order-ship-step-3";
import AssistantToggleButton from "../../components/main/assistant-toggle-button";

import {
	DymoPrintersList,
	printLabels,
} from "../../components/dymo/printHandler";

const OrdersWoocommerceShipStep3 = () => {
	const auth = useContext(AuthContext);
	const navigate = useNavigate();

	const [errorMessage, setErrorMessge] = useState(false);
	const [loadingState, setLoadingState] = useState(false);
	const [checkedOrders, setCheckedOrders] = useState(false);
	const [orderActionsAvailable, setOrderActionsAvailable] = useState(false);

	let printers = DymoPrintersList();
	const [currentPrinter, setCurrentPrinter] = useState(false);
	const [twinTurboRoll, setTwinTurboRoll] = useState("Left");
	const [currentLabel, setCurrentLabel] = useState("label99010");

	useEffect(() => {
		(async () => {
			setLoadingState("Verzendoverzicht wordt geladen");

			let checkedOrdersProp = JSON.parse(sessionStorage.getItem("checkedOrders"));
			let shippingMethodProp = JSON.parse(
				sessionStorage.getItem("shippingMethod")
			);

			setCheckedOrders(checkedOrdersProp);

			if (
				checkedOrdersProp &&
				checkedOrdersProp.length > 0 &&
				shippingMethodProp
			) {
				setOrderActionsAvailable(true);
			}

			setLoadingState(false);
		})();
	}, []);

	useEffect(() => {
		if (printers && printers.length > 0) {
			printers.forEach((printer) => {
				if (printer.modelName === "DYMO LabelWriter 450 Twin Turbo") {
					console.log(printer);
					setCurrentPrinter(printer);
				} else {
					setCurrentPrinter(printers[0]);
				}
			});
		}
	}, [printers]);

	const handlePrinterChoice = (printer) => {
		setCurrentPrinter(printer);
	};

	const handleTwinTurboRollChoice = (twinTurboRoll) => {
		setTwinTurboRoll(twinTurboRoll);
	};

	const handleLabelChoice = (label) => {
		if (label === "label99010") {
			setCurrentLabel("label99010");
		} else if (label === "label11352") {
			setCurrentLabel("label11352");
		}
	};

	return (
		<React.Fragment>
			<div className='title-header title-header-ship-orders'>
				<span>Bestellingen verzonden</span>
				<div className='shipmentSteps'>
					<div className='shipmentOneStep shipmentFirstStep finished'>
						<div className='shipmentOneStepCircle'>1</div>
						<div className=''>Verzendmethode</div>
					</div>
					<div className='shipmentStepsLine'></div>
					<div className='shipmentOneStep finished'>
						<div className='shipmentOneStepCircle'>2</div>
						<div className=''>Overzicht</div>
					</div>
					<div className='shipmentStepsLine'></div>
					<div className='shipmentOneStep finished'>
						<div className='shipmentOneStepCircle'>3</div>
						<div className=''>Bevestiging</div>
					</div>
				</div>
			</div>
			{checkedOrders && (
				<div className='orders-container-header ship-orders-container-header'>
					{checkedOrders && checkedOrders.length > 0 && (
						<div className='orders-container-header-actions ship-orders-container-header-actions shipped-orders-container-header-actions'>
							<div className='confirmedOrderPrintOptions'>
								<Dropdown>
									<Dropdown.Toggle
										className={!currentPrinter ? "disabled" : ""}
										variant='secondary'
										id='dropdown-menu-align-right'
									>
										{currentPrinter.name || "Geen verbinding met printer"}
									</Dropdown.Toggle>
									{currentPrinter && (
										<Dropdown.Menu>
											{printers.map((printer) => {
												return (
													<Dropdown.Item
														printername={printer.name}
														value={printer.name}
														key={printer.modelName}
														onClick={() => handlePrinterChoice(printer)}
													>
														{printer.name}
													</Dropdown.Item>
												);
											})}
										</Dropdown.Menu>
									)}
								</Dropdown>
								{currentPrinter && currentPrinter.isTwinTurbo && (
									<Dropdown>
										<Dropdown.Toggle variant='secondary'>
											{(() => {
												return twinTurboRoll === "Left"
													? [<BsArrowBarLeft />, "Links"]
													: ["Rechts", <BsArrowBarRight />];
											})()}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => handleTwinTurboRollChoice("Left")}>
												<BsArrowBarLeft /> Links
											</Dropdown.Item>
											<Dropdown.Item onClick={() => handleTwinTurboRollChoice("Right")}>
												<BsArrowBarRight />
												Rechts
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								)}
								<Dropdown>
									<Dropdown.Toggle variant='secondary'>{currentLabel}</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item onClick={() => handleLabelChoice("label99010")}>
											99010 - 89x28mm
										</Dropdown.Item>
										<Dropdown.Item onClick={() => handleLabelChoice("label11352")}>
											11352 - 54x25mm
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<Button
								disabled={!currentPrinter}
								className='printLabelsButton'
								variant='primary'
								onClick={(e) => {
									printLabels(
										currentPrinter,
										currentLabel,
										checkedOrders,
										twinTurboRoll
									);
								}}
							>
								Print addressen
							</Button>
						</div>
					)}
				</div>
			)}
			{checkedOrders.length > 0 &&
				checkedOrders.map((order, index) => {
					return (
						<OrderListItemWooOrderShipStep3 order={order} key={index} index={index} />
					);
				})}
			<div className='orders-container-footer-actions ship-orders-container-footer-actions'>
				<Button
					onClick={() => {
						navigate("/orders/woocommerce");
						window.scrollTo(0, 0);
					}}
				>
					Terug naar bestellingen
				</Button>
				<Button
					disabled={!currentPrinter}
					className='printLabelsButton'
					variant='primary'
					onClick={(e) => {
						printLabels(currentPrinter, currentLabel, checkedOrders, twinTurboRoll);
					}}
				>
					Print addressen
				</Button>
			</div>

			<div>
				handmatig printen - hier komt dymo label preview - zelf tekst invoeren
			</div>

			{loadingState && (
				<div className='loading-container'>
					<div className='loading-container-title'>{loadingState}</div>
					<RotateLoader
						size={100}
						width={100}
						height={10}
						color={"#fff"}
						loading={loadingState}
					/>
				</div>
			)}
			<AssistantToggleButton />
		</React.Fragment>
	);
};

export default OrdersWoocommerceShipStep3;
