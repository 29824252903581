import React, { useState, useContext, useEffect } from "react";
import { Nav, NavDropdown, Button } from "react-bootstrap";
import { AuthContext } from "../../context/main/auth-context";
import AssistantModal from "./assistant-modal";
import "../../css/orders/header.css";

const AssistantToggleButton = (props) => {
	const auth = useContext(AuthContext);
	const [showAssistantModal, setShowAssistantModal] = useState(false);
	const [assistant, setAssistant] = useState(false);

	useEffect(() => {
		(async () => {
			let assistantStored = JSON.parse(sessionStorage.getItem("assistant"));
			if (assistantStored) {
				console.log(assistantStored);
				setAssistant(assistantStored);
			}
		})();
	}, [auth.token]);

	return (
		<React.Fragment>
			{showAssistantModal && (
				<AssistantModal
					collapseAssistantModal={(state) => {
						setShowAssistantModal(state);
					}}
					updateAssistant={(newAssistant) => {
						console.log(newAssistant);
						setAssistant(newAssistant);
					}}
				/>
			)}
			<div
				className='assistant-toggle-button'
				onClick={() => setShowAssistantModal(!showAssistantModal)}
			>
				<div>
					{!assistant
						? "Kies assistent"
						: ["Assistent:", <span>{assistant.firstName}</span>]}
				</div>
			</div>
		</React.Fragment>
	);
};

export default AssistantToggleButton;
