const getMultipleShippingLabelsPdf = async (labels, token) => {
 
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      responseType: "blob",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      labels: labels
    }),
  };

  const responseData = await fetch(
    process.env.REACT_APP_ASSET_URL + `/bol/orders/get-multiple-shipping-labels-pdf`,
    requestOptions
  );
  if (responseData.ok) {
    return await responseData.blob();
  } else {
    let error = await responseData.json();
    console.log(error);
    return error;
  }
};

export default getMultipleShippingLabelsPdf;
