import React, { useEffect, useState, useContext } from "react";
import { Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactImageMagnify from "react-image-magnify";

import moment from "moment";
import "moment/locale/nl";
import {
  FaExternalLinkAlt,
  FaRegEnvelope,
  FaRegClipboard,
  FaPencilAlt,
  FaTimes,
} from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RotateLoader } from "react-spinners";

import UpdateDeliveryOptionFbrDbb from "../../api/mongodb/update-chosen-delivery-option-fbr-dbb";

import { AuthContext } from "../../context/main/auth-context";
import { downloadBlobFile } from "../../utils/download-blob-file";
import getShippingLabelPdf from "../bol/get-shipping-label-pdf";
import CancelOrderItemModal from "../../components/bol/cancel-order-item-modal";

import flagNetherlands from "../../images/main/flag-netherlands.svg";
import flagBelgium from "../../images/main/flag-belgium.svg";
import postnlBolMailbox from "../../images/shipping/POSTNL-BOL-MAILBOX.png";
import postnlBolParcel from "../../images/shipping/POSTNL-BOL-PARCEL.png";
import postnlBolParcelPickup from "../../images/shipping/POSTNL-BOL-PARCEL-PICKUP.png";
import postnlBolParcelNight from "../../images/shipping/POSTNL-BOL-PARCEL-NIGHT.png";
import dhlBolMailbox from "../../images/shipping/DHL-BOL-MAILBOX.png";
import dhlBolParcel from "../../images/shipping/DHL-BOL-PARCEL.png";
import dhlBolParcelPickup from "../../images/shipping/DHL-BOL-PARCEL-PICKUP.png";
import dhlBolParcelNight from "../../images/shipping/DHL-BOL-PARCEL-NIGHT.png";

import truckIcon from "../../images/main/delivery-truck.svg";
import deadline from "../../images/main/deadline.png";
import siren from "../../images/main/siren.svg";

const OrderListItemOpen = (props) => {
  let alreadyRenderedOrderItem = false;
  if (
    props &&
    props.order &&
    props.order.orderItems &&
    props.order.orderItems.length > 0
  ) {
    return props.order.orderItems.map((orderItem, i) => {
      if (
        orderItem.quantityShipped > 0 &&
        orderItem.quantityShipped === orderItem.quantity
      ) {
        orderItem.checked = false;
        return false;
      }

      if (props.order.manualAdded) {
        return (
          <OrderLayOutManualAdded
            order={props.order}
            handleChecked={props.handleChecked}
            orderItem={orderItem}
            key={i}
          />
        );
      } else if (
        props.order.origin.account.platform.toLowerCase() === "shopify"
      ) {
        if (orderItem.fulfillable_quantity > 0) {
          return (
            <OrderLayOutShopify
              order={props.order}
              handleChecked={props.handleChecked}
              orderItem={orderItem}
              key={i}
            />
          );
        }
      } else if (
        props.order.origin.account.platform.toLowerCase() === "woocommerce"
      ) {
        return (
          <OrderLayOutWoocommerce
            order={props.order}
            handleChecked={props.handleChecked}
            orderItem={orderItem}
            key={i}
          />
        );
      } else if (
        props.order.origin.account.platform.toLowerCase() === "bol.com"
      ) {
        if (
          orderItem.fulfilment.distributionParty.toLowerCase() === "bol" &&
          props.distributionParty === "DBB"
        ) {
          if (!alreadyRenderedOrderItem) {
            alreadyRenderedOrderItem = true;
            return (
              <OrderLayOutBolOrderVvb
                order={props.order}
                handleChecked={props.handleChecked}
                cancelOrderItem={props.cancelOrderItem}
                setSingleShipOrderConfirmDetails={
                  props.setSingleShipOrderConfirmDetails
                }
                orderItem={orderItem}
                key={i}
                update={props.update}
              />
            );
          }
        } else if (
          orderItem.fulfilment.distributionParty.toLowerCase() === "retailer" &&
          props.distributionParty === "DBR"
        ) {
          return (
            <OrderLayOutBolOrder
              order={props.order}
              handleChecked={props.handleChecked}
              cancelOrderItem={props.cancelOrderItem}
              cancellationRequestHandler={props.cancellationRequestHandler}
              orderItem={orderItem}
              key={i}
            />
          );
        }
      } else if (
        props.order.origin.account.platform.toLowerCase() === "shopify"
      ) {
        if (orderItem.fulfillable_quantity > 0) {
          return (
            <OrderLayOutShopify
              order={props.order}
              handleChecked={props.handleChecked}
              orderItem={orderItem}
              key={i}
            />
          );
        }
      } else if (
        props.order.origin.account.platform.toLowerCase() === "amazon"
      ) {
        return (
          <OrderLayOutAmazon
            order={props.order}
            handleChecked={props.handleChecked}
            orderItem={orderItem}
            key={i}
          />
        );
      } else {
        return false;
      }
    });
  } else {
    return false;
  }
};

const OrderLayOutManualAdded = (props) => {
  const auth = useContext(AuthContext);

  const [checked, setChecked] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showAlertText, setShowAlertText] = useState(false);

  return (
    <div
      className={`o-li-item o-li-item-open${checked ? " checked" : ""} ${
        props.orderItem.cancellationRequest
          ? " o-li-item-open-cancel-request"
          : ""
      }`}
    >
      <div className="checkbox">
        <Form.Check
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            setChecked(!checked);
            props.handleChecked(checked, props.order, props.orderItem);
          }}
        />
      </div>
      <div
        className="o-li-item-content"
        onClick={(e) => {
          setChecked(!checked);
          e.target.checked = checked;
          props.handleChecked(checked, props.order, props.orderItem);
        }}
      >
        <div className="o-li-item-content-header-container">
          <div className="o-li-item-product-title">
            <span className="o-li-item-country-flag-open-order">
              {(() => {
                if (
                  props.order.shipmentDetails.countryCode === "NL" ||
                  props.order.shipmentDetails.countryCode.toLowerCase() ===
                    "nederland" ||
                  props.order.shipmentDetails?.country?.toLowerCase() ===
                    "netherlands"
                ) {
                  return <img src={flagNetherlands} alt="NL" />;
                } else if (
                  props.order.shipmentDetails.countryCode === "BE" ||
                  props.order.shipmentDetails.countryCode.toLowerCase() ===
                    "belgie" ||
                  props.order.shipmentDetails.countryCode.toLowerCase() ===
                    "belgië" ||
                  props.order.shipmentDetails?.country?.toLowerCase() ===
                    "belgium"
                ) {
                  return <img src={flagBelgium} alt="BE" />;
                } else {
                  return props.order.shipmentDetails.countryCode;
                }
              })()}
            </span>
            {props.orderItem.product.productData &&
            props.orderItem.product.productData.title
              ? props.orderItem.product.productData.title
              : null}
            {(!props.orderItem.product.productData ||
              !props.orderItem.product.productData.title) &&
            props.orderItem.product.title
              ? props.orderItem.product.title
              : null}
            <div>
              Klant: {props.order.billingDetails.firstName}{" "}
              {props.order.billingDetails.surname}
            </div>
          </div>

          <div className="o-li-item-origin">
            {props.order.additionalOrder && (
              <span className="o-li-item-additional-order-info">
                NAZENDING
                {props.order.additionalOrderReason &&
                  props.order.additionalOrderReason.label &&
                  " - " + props.order.additionalOrderReason.label}
              </span>
            )}
            <br />
            GG Bestelnr. {props.order.orderIdGG && props.order.orderIdGG}
          </div>
          <div className="o-li-item-product-title-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.bol.com/nl/s/?searchtext=${props.orderItem.product.ean}&searchContext=media_all&appliedSearchContextId=&suggestFragment=&adjustedSection=&originalSection=&originalSearchContext=&section=main&N=0&defaultSearchContext=media_all`}
            >
              <FaExternalLinkAlt />
            </a>
          </div>
        </div>
        <div className="o-li-item-data">
          <div className="o-li-item-data-item o-li-item-data-item-order-number">
            <label>Bestelnummer</label>
            <span>
              {props.order.orderId && props.order.orderId !== "false"
                ? props.order.orderId
                : props.order.orderIdGG}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-date">
            <label>Besteldatum</label>
            <span>
              {moment(props.order.orderPlacedDateTime).format("L")}
              <span>
                {moment(props.order.orderPlacedDateTime).format("LT")}
              </span>
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-delivery-date">
            <label>
              {props.order.latestDeliveryDate
                ? "Uiterste leverdatum"
                : "Exacte leverdatum"}
            </label>
            <span>
              {props.order.latestDeliveryDate
                ? moment(props.order.latestDeliveryDate).format("LL")
                : null}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-price">
            <label>Totaalprijs</label>
            <span>
              €{" "}
              {props.orderItem && props.orderItem.total
                ? Number(props.orderItem.total).toFixed(2).replace(".", ",")
                : 0}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-quantity">
            <label>Aantal</label>
            <span>{props.orderItem.quantity}x</span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-reference">
            <label>Referentie</label>
            <span>
              {props.orderItem.product.productData &&
              props.orderItem.product.productData.title
                ? props.orderItem.product.productData.title
                : null}
              {(!props.orderItem.product.productData ||
                !props.orderItem.product.productData.title) &&
              props.orderItem.product.title
                ? props.orderItem.product.title
                : null}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-image">
            {props.orderItem.product.productData ? (
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "productimage",
                    isFluidWidth: true,
                    src:
                      process.env.REACT_APP_ASSET_URL +
                      "/s3/" +
                      props.orderItem.product.productData.image,
                    width: 80,
                    height: 80,
                  },
                  largeImage: {
                    isFluidWidth: true,
                    src:
                      process.env.REACT_APP_ASSET_URL +
                      "/s3/" +
                      props.orderItem.product.productData.image,
                    width: 250,
                    height: 325,
                  },
                  hoverDelayInMs: 10,
                  hoverOffDelayInMs: 10,
                  enlargedImagePosition: "beside",
                  enlargedImageContainerDimensions: {
                    width: "280%",
                    height: "280%",
                  },
                }}
              />
            ) : (
              ""
            )}
          </div>
          {props.order.notes && props.order.notes.length > 0 && (
            <div className="o-li-item-data-item o-li-item-data-item-order-notes">
              <label>Notities</label>
              <div className="o-li-item-data-item-order-notes-container">
                {props.order.notes.map((item) => {
                  return (
                    <div>
                      <span>{item.user.firstName}:</span>
                      <p>{item.note}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {showAlertText && <div className="snackbar">{showAlertText}</div>}
      {loadingPage && (
        <div className="loadingStateContainer">
          <div>
            <div className="sweet-loading processing-page">
              <RotateLoader
                size={100}
                width={100}
                height={10}
                color={"#162954"}
                loading={loadingPage}
              />
              <span className="loadingText">{loadingPage}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const OrderLayOutBolOrder = (props) => {
  const auth = useContext(AuthContext);

  const [checked, setChecked] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showAlertText, setShowAlertText] = useState(false);

  return (
    <div
      className={`o-li-item o-li-item-open${checked ? " checked" : ""} ${
        props.orderItem.cancellationRequest
          ? " o-li-item-open-cancel-request"
          : ""
      }`}
    >
      <div className="checkbox">
        <Form.Check
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            setChecked(!checked);
            props.handleChecked(checked, props.order, props.orderItem);
          }}
        />
      </div>
      <div
        className="o-li-item-content"
        onClick={(e) => {
          setChecked(!checked);
          e.target.checked = checked;
          props.handleChecked(checked, props.order, props.orderItem);
        }}
      >
        <div className="o-li-item-content-header-container">
          <div className="o-li-item-product-title">
            <span className="o-li-item-country-flag-open-order">
              {(() => {
                if (props.order.shipmentDetails.countryCode === "NL") {
                  return <img src={flagNetherlands} alt="NL" />;
                } else if (props.order.shipmentDetails.countryCode === "BE") {
                  return <img src={flagBelgium} alt="BE" />;
                } else {
                  return props.order.shipmentDetails.countryCode;
                }
              })()}
            </span>
            {props.orderItem.product.title}
          </div>

          <div className="o-li-item-origin">
            {props.order.origin.account.platform +
              " - " +
              props.order.origin.account.accountName}
          </div>
          <div className="o-li-item-product-title-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.bol.com/nl/s/?searchtext=${props.orderItem.product.ean}&searchContext=media_all&appliedSearchContextId=&suggestFragment=&adjustedSection=&originalSection=&originalSearchContext=&section=main&N=0&defaultSearchContext=media_all`}
            >
              <FaExternalLinkAlt />
            </a>
          </div>
        </div>
        <div className="o-li-item-data">
          <div className="o-li-item-data-item o-li-item-data-item-order-number">
            <label>Bestelnummer</label>
            <span>{props.order.orderId}</span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-date">
            <label>Besteldatum</label>
            <span>
              {moment(props.order.orderPlacedDateTime).format("L")}
              <span>
                {moment(props.order.orderPlacedDateTime).format("LT")}
              </span>
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-delivery-date">
            <label>
              {props.orderItem.fulfilment.latestDeliveryDate
                ? "Uiterste leverdatum"
                : "Exacte leverdatum"}
            </label>
            <span>
              {props.orderItem.fulfilment.latestDeliveryDate
                ? moment(props.orderItem.fulfilment.latestDeliveryDate).format(
                    "LL"
                  )
                : moment(props.orderItem.fulfilment.exactDeliveryDate).format(
                    "L"
                  )}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-price">
            <label>Totaalprijs</label>
            <span>
              €{" "}
              {(props.orderItem.unitPrice * props.orderItem.quantity)
                .toFixed(2)
                .replace(".", ",")}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-quantity">
            <label>Aantal</label>
            <span>{props.orderItem.quantity}x</span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-reference">
            <label>Referentie</label>
            <span>
              {props.orderItem.productData && props.orderItem.productData.title
                ? props.orderItem.productData.title
                : props.orderItem.offer.reference}{" "}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-image">
            {props.orderItem.productData ? (
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "productimage",
                    isFluidWidth: true,
                    src:
                      process.env.REACT_APP_ASSET_URL +
                      "/s3/" +
                      props.orderItem.productData.image,
                    width: 80,
                    height: 80,
                  },
                  largeImage: {
                    isFluidWidth: true,
                    src:
                      process.env.REACT_APP_ASSET_URL +
                      "/s3/" +
                      props.orderItem.productData.image,
                    width: 250,
                    height: 325,
                  },
                  hoverDelayInMs: 10,
                  hoverOffDelayInMs: 10,
                  enlargedImagePosition: "beside",
                  enlargedImageContainerDimensions: {
                    width: "280%",
                    height: "280%",
                  },
                }}
              />
            ) : (
              ""
            )}
          </div>
          {props.orderItem.cancellationRequest && (
            <div className="o-li-item-data-item o-li-item-data-item-cancel-request">
              <Button
                className="cancellationRequest-open-order-button"
                onClick={(e) => {
                  e.stopPropagation();
                  props.cancellationRequestHandler(
                    props.order,
                    props.orderItem
                  );
                }}
              >
                Annulerinsverzoek
              </Button>
            </div>
          )}
        </div>
      </div>
      {showAlertText && <div className="snackbar">{showAlertText}</div>}
      {loadingPage && (
        <div className="loadingStateContainer">
          <div>
            <div className="sweet-loading processing-page">
              <RotateLoader
                size={100}
                width={100}
                height={10}
                color={"#162954"}
                loading={loadingPage}
              />
              <span className="loadingText">{loadingPage}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const OrderLayOutBolOrderVvb = (props) => {
  const auth = useContext(AuthContext);

  const [checked, setChecked] = useState(false);
  const [tempChosenDeliveryOption, setTempChosenDeliveryOption] =
    useState(false);
  const [chosenDeliveryOption, setChosenDeliveryOption] = useState(false);
  const [currentOrderItems, setCurrentOrderItems] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState(false);
  const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
  const [cancelOrderItemModal, setCancelOrderItemModal] = useState(false);
  const [cancelResponse, setCancelResponse] = useState();
  const [loadingPage, setLoadingPage] = useState(false);
  const [showAlertText, setShowAlertText] = useState(false);
  const [processWithCancelRequest, setProcessWithCancelRequest] =
    useState(false);

  useEffect(() => {
    if (
      props.order &&
      props.order.deliveryOptions &&
      props.order.deliveryOptions.length > 0
    ) {
      props.order.deliveryOptions.forEach((shipment) => {
        if (shipment && shipment.orderItemIds) {
          if (shipment.orderItemIds.includes(props.orderItem.orderItemId)) {
            if (shipment.options && shipment.options.length > 0) {
              setDeliveryOptions(shipment.options);
            }
            if (shipment.orderItemIds) {
              setCurrentOrderItems(shipment.orderItemIds);
            }
            if (shipment.chosenOption) {
              setChosenDeliveryOption(shipment.chosenOption);
            } else {
              shipment.options.forEach((option) => {
                if (option.recommended === true) {
                  setChosenDeliveryOption(option.recommended);
                }
              });
            }
          }
        }
      });
    }
    return;
  }, [props.order, props.orderItem.orderItemId]);

  const deliveryOptionHandler = async () => {
    let deliveryOption;
    tempChosenDeliveryOption
      ? (deliveryOption = tempChosenDeliveryOption)
      : (deliveryOption = chosenDeliveryOption);
    if (
      deliveryOption.labelDisplayName === chosenDeliveryOption.labelDisplayName
    ) {
      setShowDeliveryOptions(false);
      return;
    }
    if (props.order.id && currentOrderItems && deliveryOption) {
      await UpdateDeliveryOptionFbrDbb(
        auth.token,
        props.order.id,
        currentOrderItems,
        deliveryOption
      );
    }
    props.update(deliveryOption);
    setChosenDeliveryOption(deliveryOption);
    setShowDeliveryOptions(false);
  };

  const deliveryOptionImgHandler = (deliveryOption) => {
    switch (deliveryOption) {
      case "PostNL & bol.com - Brievenbus":
        return <img src={postnlBolMailbox} alt="PostNLBol Brievenbus" />;
      case "PostNL Premium & bol.com - Brievenbus":
        return <img src={postnlBolMailbox} alt="PostNLBol Brievenbus Premium" />;
      case "PostNL & bol.com - Pakket":
        return <img src={postnlBolParcel} alt="PostNLBol Pakket" />;
      case "PostNL Premium & bol.com - Pakket":
          return <img src={postnlBolParcel} alt="PostNLBol Pakket Premium" />;
      case "PostNL & bol.com - Avond pakket":
        return <img src={postnlBolParcelNight} alt="PostNLBol Pakket Avond" />;
      case "PostNL & bol.com - Afhaalpunt pakket":
        return (
          <img src={postnlBolParcelPickup} alt="PostNLBol Pakket Afhaalpunt" />
        );
      case "DHL & bol.com - Brievenbus":
        return <img src={dhlBolMailbox} alt="DhlBol Brievenbus" />;
      case "DHL & bol.com - Pakket":
        return <img src={dhlBolParcel} alt="DhlBol Pakket" />;
      case "DHL & bol.com - Avond pakket":
        return <img src={dhlBolParcelNight} alt="DhlBol Pakket Avond" />;
      case "DHL & bol.com - Afhaalpunt pakket":
        return <img src={dhlBolParcelPickup} alt="DhlBol Pakket Afhaalpunt" />;
      default:
      // code block
    }
  };

  const handoverDateHandler = (isoDateString) => {
    let handoverDate = isoDateString.substring(0, 10);
    let today = new Date().toISOString().substring(0, 10);

    if (today === handoverDate) {
      return "Vandaag";
    } else {
      return moment(handoverDate).locale("nl").format("dddd - DD MMMM");
    }
  };

  const handoverTimeHandler = (isoDateString) => {
    let handoverTime = moment(isoDateString).format("LT");
    return handoverTime;
  };

  const dateIconHandler = (isoDateString) => {
    let handoverDate = isoDateString.substring(0, 10);
    let today = new Date().toISOString().substring(0, 10);
    if (today === handoverDate) {
      return <img src={truckIcon} alt="truckIcon" />;
    } else if (today < handoverDate) {
      return <img src={deadline} alt="deadline" />;
    } else {
      return <img src={siren} alt="deadline" />;
    }
  };

  const downloadShippingLabelHandler = async (shippingLabelId) => {
    const fileName = `${props.order.orderId}-verzendlabel`;
    let account = props.order.origin.account;

    if (!account || !shippingLabelId) return;

    let label = await getShippingLabelPdf(account, auth.token, shippingLabelId);
    downloadBlobFile(label, fileName);
  };

  const cancellationRequestHandler = () => {
    setCancelOrderItemModal({
      auth: auth,
      order: props.order,
      orderItem: props.orderItem,
    });
  };
  let cancelResponseHandler = async (response) => {
    setCancelResponse(response);
    if (response === 202) {
      props.cancelOrderItem(props.order, props.orderItem);
      setShowAlertText("Artikel geannuleerd");
      setTimeout(() => setShowAlertText(false), 3000);
      setCancelResponse(false);
      setLoadingPage(false);
    } else {
      setShowAlertText("Artikel annuleren mislukt");
      setTimeout(() => setShowAlertText(false), 3000);
      setCancelResponse(false);
      setLoadingPage(false);
    }
  };
  const closeCancelOrderItemModalHandler = (props) => {
    setCancelOrderItemModal(false);
    setLoadingPage(false);
  };

  const animateAlertText = (text) => {
    setShowAlertText(text);
    setTimeout(() => setShowAlertText(false), 3000);
  };

  return (
    props.order && (
      <div className="o-li-item-container-vvb">
        <div
          className={`o-li-item o-li-item-open-vvb${checked ? " checked" : ""}`}
        >
          <div className="o-li-item-content-first">
            <div className="o-li-item-content-first-1">
              <div className="checkbox">
                <Form.Check
                  type="checkbox"
                  checked={checked}
                  onChange={(e) => {
                    setChecked(!checked);
                    props.handleChecked(checked, props.order, props.orderItem);
                  }}
                  disabled={
                    !chosenDeliveryOption || !chosenDeliveryOption.entityId
                      ? "true"
                      : ""
                  }
                />
              </div>
            </div>
            <div className="o-li-item-content-first-2">
              <div className="o-li-item-content-first-2-1">
                <div className="o-li-item-content-first-2-1-1">
                  <div className="o-li-item-content-first-2-1-1-1">
                    # {props.order.orderId}
                  </div>
                  <div className="o-li-item-content-first-2-1-1-2">
                    {props.order.origin.account.accountName}
                    <br />
                    {moment(props.order.orderPlacedDateTime).format("L")}
                    <span>
                      {moment(props.order.orderPlacedDateTime).format("LT")}
                    </span>
                  </div>
                  <div className="o-li-item-content-first-2-1-1-3">
                    {(() => {
                      if (props.order.shipmentDetails.countryCode === "NL") {
                        return <img src={flagNetherlands} alt="NL" />;
                      } else if (
                        props.order.shipmentDetails.countryCode === "BE"
                      ) {
                        return <img src={flagBelgium} alt="BE" />;
                      } else {
                        return props.order.shipmentDetails.countryCode;
                      }
                    })()}
                  </div>
                </div>
                <div className="o-li-item-content-first-2-1-2">
                  <div className="o-li-item-content-first-2-1-2-1">
                    {props.order.shipmentDetails.firstName +
                      " " +
                      props.order.shipmentDetails.surname}
                  </div>
                  <div className="o-li-item-content-first-2-1-2-2">
                    <span>
                      <a
                        target="_blank"
                        href={`mailto:${props.order.shipmentDetails}`}
                        rel="noreferrer"
                      >
                        <FaRegEnvelope />
                      </a>
                    </span>
                  </div>
                  <div className="o-li-item-content-first-2-1-2-3">
                    <span>
                      <CopyToClipboard
                        onCopy={() => {
                          animateAlertText("Gekopieerd");
                        }}
                        text={
                          props.order.shipmentDetails
                            ? props.order.shipmentDetails.email
                            : undefined
                        }
                      >
                        <FaRegClipboard />
                      </CopyToClipboard>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="o-li-item-content-first-3">
              {chosenDeliveryOption &&
                chosenDeliveryOption.labelDisplayName && (
                  <div
                    className="o-li-item-content-first-3-1"
                    onClick={() => setShowDeliveryOptions(!showDeliveryOptions)}
                  >
                    <div className="o-li-item-content-first-3-1-1">
                      {chosenDeliveryOption &&
                        chosenDeliveryOption.labelDisplayName &&
                        deliveryOptionImgHandler(
                          chosenDeliveryOption.labelDisplayName
                        )}
                    </div>
                    <div className="o-li-item-content-first-3-1-2">
                      {chosenDeliveryOption &&
                        chosenDeliveryOption.labelDisplayName &&
                        chosenDeliveryOption.labelDisplayName}

                      {<FaPencilAlt />}
                    </div>
                  </div>
                )}

              {chosenDeliveryOption && chosenDeliveryOption.entityId && (
                <div className="o-li-item-content-first-3-2">
                  <Button
                    onClick={() =>
                      downloadShippingLabelHandler(
                        chosenDeliveryOption.entityId
                      )
                    }
                  >
                    Download verzendzegel
                  </Button>
                </div>
              )}
              {(!chosenDeliveryOption || !chosenDeliveryOption.entityId) && (
                <Button disabled>Verzendzegel wordt aangemaakt</Button>
              )}
            </div>
            {props.order &&
              props.order.orderItems.map((orderItem) => {
                if (orderItem.fulfilment.distributionParty === "BOL")
                  return (
                    <div className="o-li-item-content-first-4">
                      <div className="o-li-item-content-first-4-1">
                        {orderItem.productData ? (
                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "productimage",
                                isFluidWidth: true,
                                src:
                                  process.env.REACT_APP_ASSET_URL +
                                  "/s3/" +
                                  orderItem.productData.image,
                                width: 80,
                                height: 80,
                              },
                              largeImage: {
                                isFluidWidth: true,
                                src:
                                  process.env.REACT_APP_ASSET_URL +
                                  "/s3/" +
                                  orderItem.productData.image,
                                width: 250,
                                height: 325,
                              },
                              hoverDelayInMs: 10,
                              hoverOffDelayInMs: 10,
                              enlargedImagePosition: "beside",
                              enlargedImageContainerDimensions: {
                                width: "280%",
                                height: "280%",
                              },
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="o-li-item-content-first-4-2">
                        <div className="o-li-item-content-first-4-1-1">
                          {orderItem.product.title}
                        </div>
                        <div className="o-li-item-content-first-4-1-2">
                          <div className="o-li-item-content-first-4-1-2-1">
                            <span>EAN:</span> {orderItem.product.ean}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://www.bol.com/nl/s/?searchtext=${orderItem.product.ean}&searchContext=media_all&appliedSearchContextId=&suggestFragment=&adjustedSection=&originalSection=&originalSearchContext=&section=main&N=0&defaultSearchContext=media_all`}
                            >
                              <FaExternalLinkAlt />
                            </a>
                          </div>
                          <div className="o-li-item-content-first-4-1-2-2">
                            {orderItem.productData &&
                            orderItem.productData.title
                              ? orderItem.productData.title
                              : orderItem.offer.reference}
                          </div>
                        </div>
                      </div>
                      <div className="o-li-item-content-first-4-3">
                        <div className="o-li-item-content-first-4-2-1">
                          {orderItem.quantity}x
                        </div>
                        <div className="o-li-item-content-first-4-2-2">
                          €{" "}
                          {(orderItem.unitPrice * orderItem.quantity)
                            .toFixed(2)
                            .replace(".", ",")}
                        </div>
                        <div className="o-li-item-content-first-4-2-3">
                          <DropdownButton
                            className="cancel-item-dropdown filter-open-orders-dropdown"
                            align="end"
                            id="dropdown-cancel-button"
                            title={<BsThreeDotsVertical />}
                          >
                            <Dropdown.Item
                              onClick={() => cancellationRequestHandler()}
                            >
                              Annuleer bestelling
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </div>
                    </div>
                  );
              })}
          </div>
          <div className="o-li-item-content-second">
            <div className="o-li-item-content-second-1">
              <div className="o-li-item-content-second-1-1">
                {chosenDeliveryOption &&
                  chosenDeliveryOption.handoverDetails &&
                  chosenDeliveryOption.handoverDetails.latestHandoverDateTime &&
                  !props.orderItem.cancellationRequest &&
                  dateIconHandler(
                    chosenDeliveryOption.handoverDetails.latestHandoverDateTime
                  )}
                {props.orderItem.cancellationRequest && <FaTimes />}
              </div>
              <div className="o-li-item-content-second-1-2">
                {chosenDeliveryOption &&
                  chosenDeliveryOption.handoverDetails &&
                  chosenDeliveryOption.handoverDetails.latestHandoverDateTime &&
                  !props.orderItem.cancellationRequest &&
                  handoverDateHandler(
                    chosenDeliveryOption.handoverDetails.latestHandoverDateTime
                  )}
                <br />
                {chosenDeliveryOption &&
                  chosenDeliveryOption.handoverDetails &&
                  chosenDeliveryOption.handoverDetails.latestHandoverDateTime &&
                  !props.orderItem.cancellationRequest &&
                  "Wegbrengen tot " +
                    handoverTimeHandler(
                      chosenDeliveryOption.handoverDetails
                        .latestHandoverDateTime
                    )}
              </div>
              <div className="o-li-item-content-second-1-3">
                {props.orderItem.cancellationRequest && (
                  <Button
                    className="cancellationRequest-open-order-button"
                    onClick={() => cancellationRequestHandler()}
                  >
                    Annulerinsverzoek
                  </Button>
                )}
                {props.orderItem.cancellationRequest &&
                  !processWithCancelRequest && (
                    <div
                      className="continue-processing-with-cancel-request"
                      onClick={() => {
                        setProcessWithCancelRequest(true);
                      }}
                    >
                      Alsnog verwerken?
                    </div>
                  )}
                {!props.orderItem.cancellationRequest && (
                  <Button
                    onClick={() => {
                      props.setSingleShipOrderConfirmDetails(props.order);
                    }}
                  >
                    verwerken
                  </Button>
                )}
                {props.orderItem.cancellationRequest &&
                  processWithCancelRequest && (
                    <Button
                      onClick={() => {
                        props.setSingleShipOrderConfirmDetails(props.order);
                      }}
                    >
                      verwerken
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </div>
        {showDeliveryOptions && (
          <div className="delivery-options-container">
            <div className="delivery-options">
              <div
                className="delivery-options-close-button"
                onClick={() => {
                  setShowDeliveryOptions(false);
                }}
              >
                <FaTimes />
              </div>
              <h1>Verzendzegel wijzigen</h1>
              <div className="delivery-optionsproduct">
                <div className="delivery-options-product-title">
                  {props.orderItem.product.title}
                </div>
                <div className="delivery-options-product-details">
                  <span>
                    EAN: {props.orderItem.product.ean}{" "}
                    <a
                      href={
                        "https://www.bol.com/nl/nl/s/?searchtext=" +
                        props.orderItem.product.ean
                      }
                      target="_noblank"
                    >
                      <FaExternalLinkAlt />
                    </a>
                  </span>
                  <span>|</span>
                  <span>
                    {props.orderItem.productData &&
                    props.orderItem.productData.title
                      ? props.orderItem.productData.title
                      : props.orderItem.offer.reference}{" "}
                  </span>
                  <span>|</span>
                  <span>
                    {props.orderItem.quantity}
                    {props.orderItem.quantity === 1 ? " stuk" : " stuks"}
                  </span>
                  <span>|</span>
                  <span>
                    €{" "}
                    {(props.orderItem.unitPrice * props.orderItem.quantity)
                      .toFixed(2)
                      .replace(".", ",")}
                  </span>
                </div>
              </div>
              <h1 className="label-text-h1">Labels</h1>
              <div className="delivery-options-labels">
                {deliveryOptions &&
                  deliveryOptions.map((deliveryOption, i) => {
                    return (
                      <div className={"radio-toolbar"}>
                        <Form.Check
                          type="radio"
                          id={deliveryOption.labelDisplayName}
                          name="chooseDeliveryOptions"
                          value={deliveryOption.labelDisplayName}
                          defaultChecked={(() => {
                            // if (
                            //   tempChosenDeliveryOption &&
                            //   tempChosenDeliveryOption.labelDisplayName ===
                            //     deliveryOption.labelDisplayName
                            // ) {
                            //   return true;
                            // } else
                            if (
                              chosenDeliveryOption &&
                              chosenDeliveryOption.labelDisplayName &&
                              chosenDeliveryOption.labelDisplayName ===
                                deliveryOption.labelDisplayName
                            ) {
                              return true;
                            }
                          })()}
                          onClick={() => {
                            setTempChosenDeliveryOption(deliveryOption);
                          }}
                          label={
                            <div className="delivery-option-label-info">
                              <div className="delivery-option-label-img">
                                {deliveryOptionImgHandler(
                                  deliveryOption.labelDisplayName
                                )}
                              </div>
                              <div className="delivery-option-data">
                                <div className="delivery-option-name">
                                  {deliveryOption.labelDisplayName}
                                </div>
                                <div className="delivery-option-measurements">
                                  <div>
                                    {"< " +
                                      deliveryOption.packageRestrictions
                                        .maxWeight}
                                  </div>
                                  <div className="divider">|</div>
                                  <div>
                                    {"< " +
                                      deliveryOption.packageRestrictions
                                        .maxDimensions}
                                  </div>
                                </div>
                              </div>
                              <div className="delivery-option-price">
                                €{" "}
                                {deliveryOption.labelPrice.totalPrice
                                  .toFixed(2)
                                  .replace(".", ",")}
                                *
                              </div>
                            </div>
                          }
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="delivery-option-label-extra-info">
                * Excl. eventueel verschuldigde btw. De zegelkosten verrekenen
                we automatisch met je opbrengst.
              </div>
              <div className="delivery-option-buttons">
                <Button
                  onClick={() => {
                    setShowDeliveryOptions(false);
                  }}
                >
                  Annuleer
                </Button>
                <Button
                  onClick={() => {
                    deliveryOptionHandler();
                  }}
                >
                  Wijzigingen opslaan
                </Button>
              </div>
            </div>
          </div>
        )}
        {cancelOrderItemModal && !cancelResponse && (
          <CancelOrderItemModal
            cancelData={cancelOrderItemModal}
            cancelResponseHandler={cancelResponseHandler}
            closeCancelOrderItemModal={closeCancelOrderItemModalHandler}
            loadingStateCancel={() => {
              setLoadingPage("Artikel wordt geannuleerd.");
              setCancelOrderItemModal(false);
            }}
          />
        )}
        {showAlertText && <div className="snackbar">{showAlertText}</div>}
        {loadingPage && (
          <div className="loadingStateContainer">
            <div>
              <div className="sweet-loading processing-page">
                <RotateLoader
                  size={100}
                  width={100}
                  height={10}
                  color={"#162954"}
                  loading={loadingPage}
                />
                <span className="loadingText">{loadingPage}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

const OrderLayOutWoocommerce = (props) => {
  const auth = useContext(AuthContext);

  const [checked, setChecked] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showAlertText, setShowAlertText] = useState(false);

  return (
    <div
      className={`o-li-item o-li-item-open${checked ? " checked" : ""} ${
        props.orderItem.cancellationRequest
          ? " o-li-item-open-cancel-request"
          : ""
      }`}
    >
      <div className="checkbox">
        <Form.Check
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            setChecked(!checked);
            props.handleChecked(checked, props.order, props.orderItem);
          }}
        />
      </div>
      <div
        className="o-li-item-content"
        onClick={(e) => {
          setChecked(!checked);
          e.target.checked = checked;
          props.handleChecked(checked, props.order, props.orderItem);
        }}
      >
        <div className="o-li-item-content-header-container">
          <div className="o-li-item-product-title">
            <span className="o-li-item-country-flag-open-order">
              {(() => {
                if (
                  props.order.shipmentDetails.country === "NL" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "nederland" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "netherlands"
                ) {
                  return <img src={flagNetherlands} alt="NL" />;
                } else if (
                  props.order.shipmentDetails.country === "BE" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "belgie" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "belgië" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "belgium"
                ) {
                  return <img src={flagBelgium} alt="BE" />;
                } else {
                  return props.order.shipmentDetails.country;
                }
              })()}
            </span>
            {props.orderItem.productData.title}
            <div>
              Klant: {props.order.billingDetails.firstName}{" "}
              {props.order.billingDetails.surname}
            </div>
          </div>

          <div className="o-li-item-origin">
            <span className="o-li-item-additional-order-info">Woocommerce</span>
            <br />
            GG Bestelnr. {props.order.orderIdGG && props.order.orderIdGG}
          </div>
          <div className="o-li-item-product-title-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.bol.com/nl/s/?searchtext=${props.orderItem.productData.ean}&searchContext=media_all&appliedSearchContextId=&suggestFragment=&adjustedSection=&originalSection=&originalSearchContext=&section=main&N=0&defaultSearchContext=media_all`}
            >
              <FaExternalLinkAlt />
            </a>
          </div>
        </div>
        <div className="o-li-item-data">
          <div className="o-li-item-data-item o-li-item-data-item-order-number">
            <label>Bestelnummer</label>
            <span>
              {props.order.orderId && props.order.orderId !== "false"
                ? props.order.orderId
                : props.order.orderIdGG}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-date">
            <label>Besteldatum</label>
            <span>
              {moment(props.order.orderPlacedDateTime).format("L")}
              <span>
                {moment(props.order.orderPlacedDateTime).format("LT")}
              </span>
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-delivery-date">
            <label>
              {props.order.latestDeliveryDate && "Uiterste leverdatum"}
            </label>
            <span>
              {props.order.latestDeliveryDate
                ? moment(props.order.latestDeliveryDate).format("LL")
                : null}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-price">
            <label>Totaalprijs</label>
            <span>
              €{" "}
              {props.orderItem && props.orderItem.total
                ? Number(props.orderItem.total).toFixed(2).replace(".", ",")
                : 0}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-quantity">
            <label>Aantal</label>
            <span>{props.orderItem.quantity}x</span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-reference">
            <label>Referentie</label>
            <span>
              {props.orderItem.productData && props.orderItem.productData.title
                ? props.orderItem.productData.title
                : null}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-image">
            {props.orderItem.productData ? (
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "productimage",
                    isFluidWidth: true,
                    src:
                      process.env.REACT_APP_ASSET_URL +
                      "/s3/" +
                      props.orderItem.productData.image,
                    width: 80,
                    height: 80,
                  },
                  largeImage: {
                    isFluidWidth: true,
                    src:
                      process.env.REACT_APP_ASSET_URL +
                      "/s3/" +
                      props.orderItem.productData.image,
                    width: 250,
                    height: 325,
                  },
                  hoverDelayInMs: 10,
                  hoverOffDelayInMs: 10,
                  enlargedImagePosition: "beside",
                  enlargedImageContainerDimensions: {
                    width: "280%",
                    height: "280%",
                  },
                }}
              />
            ) : (
              ""
            )}
          </div>
          {props.order.notes && props.order.notes.length > 0 && (
            <div className="o-li-item-data-item o-li-item-data-item-order-notes">
              <label>Notities</label>
              <div className="o-li-item-data-item-order-notes-container">
                {props.order.notes.map((item) => {
                  return (
                    <div>
                      <span>{item.user.firstName}:</span>
                      <p>{item.note}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {showAlertText && <div className="snackbar">{showAlertText}</div>}
      {loadingPage && (
        <div className="loadingStateContainer">
          <div>
            <div className="sweet-loading processing-page">
              <RotateLoader
                size={100}
                width={100}
                height={10}
                color={"#162954"}
                loading={loadingPage}
              />
              <span className="loadingText">{loadingPage}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const OrderLayOutShopify = (props) => {
  const auth = useContext(AuthContext);

  const [checked, setChecked] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showAlertText, setShowAlertText] = useState(false);

  return (
    <div
      className={`o-li-item o-li-item-open${checked ? " checked" : ""} ${
        props.orderItem.cancellationRequest
          ? " o-li-item-open-cancel-request"
          : ""
      }`}
    >
      <div className="checkbox">
        <Form.Check
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            setChecked(!checked);
            props.handleChecked(checked, props.order, props.orderItem);
          }}
        />
      </div>
      <div
        className="o-li-item-content"
        onClick={(e) => {
          setChecked(!checked);
          e.target.checked = checked;
          props.handleChecked(checked, props.order, props.orderItem);
        }}
      >
        <div className="o-li-item-content-header-container">
          <div className="o-li-item-product-title">
            <span className="o-li-item-country-flag-open-order">
              {(() => {
                if (
                  props.order.shipmentDetails.country === "NL" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "nederland" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "netherlands"
                ) {
                  return <img src={flagNetherlands} alt="NL" />;
                } else if (
                  props.order.shipmentDetails.country === "BE" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "belgie" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "belgië" ||
                  props.order.shipmentDetails.country.toLowerCase() ===
                    "belgium"
                ) {
                  return <img src={flagBelgium} alt="BE" />;
                } else {
                  return props.order.shipmentDetails.country;
                }
              })()}
            </span>
            {props.orderItem.productData && props.orderItem.productData.title
              ? props.orderItem.productData.title
              : props.orderItem.title}
            <div>
              Klant: {props.order.billingDetails.first_name}{" "}
              {props.order.billingDetails.last_name}
            </div>
          </div>

          <div className="o-li-item-origin">
            <span className="o-li-item-additional-order-info">Shopify</span>
            <br />
            GG Bestelnr. {props.order.orderIdGG && props.order.orderIdGG}
          </div>
          {props.orderItem.productData && (
            <div className="o-li-item-product-title-link">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.bol.com/nl/s/?searchtext=${props.orderItem.productData.ean}&searchContext=media_all&appliedSearchContextId=&suggestFragment=&adjustedSection=&originalSection=&originalSearchContext=&section=main&N=0&defaultSearchContext=media_all`}
              >
                <FaExternalLinkAlt />
              </a>
            </div>
          )}
        </div>
        <div className="o-li-item-data">
          <div className="o-li-item-data-item o-li-item-data-item-order-number">
            <label>Bestelnummer</label>
            <span>
              {props.order.orderIdName && props.order.orderIdName}
              {props.order.orderId &&
                props.order.orderId !== "false" &&
                !props.order.orderIdName &&
                props.order.orderId}
              {!props.order.orderId &&
                !props.order.orderIdName &&
                props.order.orderIdGG}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-date">
            <label>Besteldatum</label>
            <span>
              {moment(props.order.orderPlacedDateTime).format("L")}
              <span>
                {moment(props.order.orderPlacedDateTime).format("LT")}
              </span>
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-delivery-date">
            <label>
              {props.order.latestDeliveryDate && "Uiterste leverdatum"}
            </label>
            <span>
              {props.order.latestDeliveryDate
                ? moment(props.order.latestDeliveryDate).format("LL")
                : null}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-price">
            <label>Totaalprijs</label>
            <span>
              €{" "}
              {props.orderItem && props.orderItem.price
                ? Number(props.orderItem.price).toFixed(2).replace(".", ",")
                : 0}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-quantity">
            <label>Aantal</label>
            <span>{props.orderItem.quantity}x</span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-reference">
            {props.orderItem.productData && <label>Referentie</label>}
            <span>
              {props.orderItem.productData && props.orderItem.productData.title
                ? props.orderItem.productData.title
                : null}
            </span>
          </div>
          <div className="o-li-item-data-item o-li-item-data-item-order-image">
            {props.orderItem.productData ? (
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "productimage",
                    isFluidWidth: true,
                    src:
                      process.env.REACT_APP_ASSET_URL +
                      "/s3/" +
                      props.orderItem.productData.image,
                    width: 80,
                    height: 80,
                  },
                  largeImage: {
                    isFluidWidth: true,
                    src:
                      process.env.REACT_APP_ASSET_URL +
                      "/s3/" +
                      props.orderItem.productData.image,
                    width: 250,
                    height: 325,
                  },
                  hoverDelayInMs: 10,
                  hoverOffDelayInMs: 10,
                  enlargedImagePosition: "beside",
                  enlargedImageContainerDimensions: {
                    width: "280%",
                    height: "280%",
                  },
                }}
              />
            ) : (
              ""
            )}
          </div>
          {props.order.notes && props.order.notes.length > 0 && (
            <div className="o-li-item-data-item o-li-item-data-item-order-notes">
              <label>Notities</label>
              <div className="o-li-item-data-item-order-notes-container">
                {props.order.notes.map((item) => {
                  return (
                    <div>
                      <span>{item.user.firstName}:</span>
                      <p>{item.note}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {showAlertText && <div className="snackbar">{showAlertText}</div>}
      {loadingPage && (
        <div className="loadingStateContainer">
          <div>
            <div className="sweet-loading processing-page">
              <RotateLoader
                size={100}
                width={100}
                height={10}
                color={"#162954"}
                loading={loadingPage}
              />
              <span className="loadingText">{loadingPage}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const OrderLayOutAmazon = (props) => {
  return <div></div>;
};

export default OrderListItemOpen;
