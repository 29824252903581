// Gebruiker, gehele order en orderitem te verzenden
// Assistent wordt hier opgehaald indien aanwezig
const shipOrderItem = async (account, user, order, orderItem) => {
	let accountData = {
		accountName: account.accountName,
		mongoDbId: account.accountId,
		accountSaleNumber: account.accountSaleNumber,
	};

	let assistant = JSON.parse(sessionStorage.getItem("assistant"));
	if (!assistant || assistant === undefined) {
		assistant = false;
	}
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + user.token,
			"Retry-After": 10000,
		},
		body: JSON.stringify({
			account: accountData,
			order: order,
			orderItem: orderItem,
			user: user,
			assistant: assistant,
		}),
	};

	try {
		return await fetch(
			process.env.REACT_APP_ASSET_URL + `/bol/orders/ship-order-item`,
			requestOptions
		).then(async (response) => {
			console.log(response);
			let responseJson = await response.json();
			return { response, responseJson };
		});
	} catch (err) {
		console.log(err);
	}
};

export default shipOrderItem;
