import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { RotateLoader } from "react-spinners";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

import { AuthContext } from "../../context/main/auth-context";
import "../../css/orders/orders.css";

import OrdersHeader from "../../components/orders/orders-header";
import getProductBySku from "../../api/mongodb/get-product-by-sku";
import OrderListItemsBolShipStep2 from "../../components/bol/order-list-item-bol-ship-step-2";
import AssistantToggleButton from "../../components/main/assistant-toggle-button";
import shipOrderItem from "../../components/bol/ship-order-item";
import getMultipleShippingLabelsPdfPostNL from "../../components/bol/get-multiple-shipping-labels-pdf-postnl";

const OrdersFbrDbr = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState(false);
  const [orderActionsAvailable, setOrderActionsAvailable] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingState("Verzendoverzicht wordt geladen");
      let checkedOrdersProp = JSON.parse(
        sessionStorage.getItem("checkedOrders")
      );
      let transporterCodesProp = JSON.parse(
        sessionStorage.getItem("transporterCodes")
      );
      setCheckedOrders(checkedOrdersProp);
      setLoadingState(false);
    })();
  }, []);
  
  const returnToStep1Handler = () => {
    navigate("/orders/bol/fbr/dbr/ship/step-1");
    window.scrollTo(0, 0);
  };
  const confirmToStep3Handler = async () => {

    let checkedOrdersForLabels = []

    checkedOrders.forEach((order, i) => {
      if (order.frontEndChosenDeliveryOption.transporterCode !== "TNT_BRIEF") {
        checkedOrdersForLabels.push(order)
      }
    })
    

    let multipleLabelsPdf = await getMultipleShippingLabelsPdfPostNL(
      checkedOrdersForLabels,
      auth.token
    );

    if (multipleLabelsPdf && !Array.isArray(multipleLabelsPdf.ResponseShipments) || Array.isArray(multipleLabelsPdf.ResponseShipments) && multipleLabelsPdf.ResponseShipments.length !== checkedOrdersForLabels.length) {
        setErrorMessage("Er is iets misgegaan, probeer het opnieuw")
        setLoadingState(false)
        return
    }

    let handledOrderItems = 0;
    let orderItems = 0;
    checkedOrdersForLabels.forEach((order, i) => {
      orderItems += order.frontEndChosenDeliveryOption.orderItems.length;
      order.shipmentPostNL = multipleLabelsPdf.ResponseShipments[i];
      order.frontEndChosenDeliveryOption.transporterCode = "TNT";
      order.frontEndChosenDeliveryOption.inputTrackAndTrace = multipleLabelsPdf.ResponseShipments[i].Barcode;
      order.inputTrackAndTrace = multipleLabelsPdf.ResponseShipments[i].Barcode;

    });

    setLoadingState(`${handledOrderItems}/${orderItems} Bestelregels verwerkt`);

    for (let order of checkedOrders) {
      for (let orderItem of order.frontEndChosenDeliveryOption.orderItems) {
        orderItem.shippedSuccesful = false;
        let account = order.origin.account;
        if (!account) return;
        let res = await shipOrderItem(account, auth, order, orderItem);
        console.log(res);
        if (res.response.status === 202) {
          orderItem.shippedSuccesful = true;
          handledOrderItems += 1;
          setLoadingState(
            `${handledOrderItems}/${orderItems} Bestelregels verwerkt`
          );
        }
      }
    }

    navigate("/orders/bol/fbr/dbr/ship/step-3");
    sessionStorage.setItem("labelsPDF", JSON.stringify(multipleLabelsPdf));
    sessionStorage.setItem("checkedOrders", JSON.stringify(checkedOrders));
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <div className="title-header title-header-ship-orders">
        <span>Verzenden van bestellingen</span>
        <div className="shipmentSteps">
          <div className="shipmentOneStep shipmentFirstSte finished">
            <div className="shipmentOneStepCircle">1</div>
            <div className="">Verzendmethode</div>
          </div>
          <div className="shipmentStepsLine"></div>
          <div className="shipmentOneStep finished">
            <div className="shipmentOneStepCircle">2</div>
            <div className="">Overzicht</div>
          </div>
          <div className="shipmentStepsLine"></div>
          <div className="shipmentOneStep notActive">
            <div className="shipmentOneStepCircle">3</div>
            <div className="">Bevestiging</div>
          </div>
        </div>
      </div>
      {checkedOrders && (
        <div className="orders-container-header ship-orders-container-header">
          {checkedOrders && checkedOrders.length > 0 && (
            <div className="orders-container-header-actions ship-orders-container-header-actions">
              Overzicht
            </div>
          )}
        </div>
      )}
       {errorMessage && (
          <div className="errorMessageField">
                {errorMessage}
            </div>
        )}
      {checkedOrders.length > 0 &&
        checkedOrders.map((order, index) => {
          return (
            <OrderListItemsBolShipStep2
              order={order}
              key={index}
              index={index}
            />
          );
        })}
      <div className="orders-container-footer-actions ship-orders-container-footer-actions step2">
        <Button
          className="previousBtn"
          onClick={() => {
            returnToStep1Handler();
          }}
        >
          <GrLinkPrevious />
          Vorige stap
        </Button>
        <Button
          className="nextBtn"
          onClick={() => {
            confirmToStep3Handler();
          }}
        >
          Verwerken <GrLinkNext />
        </Button>
      </div>

      {loadingState && (
        <div className="loading-container">
          <div className="loading-container-title">{loadingState}</div>
          <RotateLoader
            size={100}
            width={100}
            height={10}
            color={"#fff"}
            loading={loadingState}
          />
        </div>
      )}
      <AssistantToggleButton />
    </React.Fragment>
  );
};

export default OrdersFbrDbr;
