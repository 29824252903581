const getAllUsers = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const responseData = await fetch(
    process.env.REACT_APP_ASSET_URL + "/users/",
    requestOptions
  );
  if (responseData.ok) {
    let result = await responseData.json();
    return result;
  } else {
    let error = await responseData.json();
    console.log(error);
  }
};

export default getAllUsers;
