import React, { useEffect, useState, useContext } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { RiMenu2Fill } from "react-icons/ri";

import Navigation from "./navigation";

import { AuthContext } from "../../context/main/auth-context";
import "../../css/main/header.css";

const Header = (props) => {
  const [sidebarState, setSidebarState] = useState(props.sidebarState);
  const [accountsWithOrders, setAccountsWithOrders] = useState(false);
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (props.accountsWithOrders) {
      setAccountsWithOrders(props.accountsWithOrders);
    }
  }, [props.accountsWithOrders]);

  const SidebarHandler = () => {
    props.openSidebar(true);
  };

  
  useEffect(() => {
    setSidebarState(props.sidebarState);
  }, [props]);

  return (
    auth.isLoggedIn && (
      <Navbar className="main-nav grand-main-header" expand="md">
        {!sidebarState && <RiMenu2Fill onClick={() => SidebarHandler()} />}
        <Navigation sidebarState={sidebarState} {...props} />
      </Navbar>
    )
  );
};

export default Header;
