import React, { useState, useContext, useEffect } from "react";
import { Button, Form } from "react-bootstrap";

import ImageUpload from "../admin/image-upload";
import { AuthContext } from "../../context/main/auth-context";

const BolEditAccount = (props) => {
  const auth = useContext(AuthContext);

  const [account, setAccount] = useState(props.account);
  const [errorMessage, setErrorMessage] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [updateAccountSuccess, setUpdateAccountSuccess] = useState(false);

  const [newAccountName, setNewAccountName] = useState(false);
  const [newAccountSaleNumber, setNewAccountSaleNumber] = useState(false);
  const [newAccountId, setNewAccountId] = useState(false);
  const [newAccountSecret, setNewAccountSecret] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [newBtw, setNewBtw] = useState(false);
  const [newCity, setNewCity] = useState(false);
  const [newCountry, setNewCountry] = useState(false);
  const [newEmail, setNewEmail] = useState(false);
  const [newHouseNumber, setNewHouseNumber] = useState(false);
  const [newIban, setNewIban] = useState(false);
  const [newImage, setNewImage] = useState(false);
  const [newKvk, setNewKvk] = useState(false);
  const [newPhone, setNewPhone] = useState(false);
  const [newZipCode, setNewZipcode] = useState(false);
  const [newInvoiceEmail, setNewInvoiceEmail] = useState(false);
  const [newReviewEmail, setNewReviewEmail] = useState(false);

  useEffect(() => {
    console.log(props.account);
    if (props.account.reviewEmail) {
      setNewReviewEmail(props.account.reviewEmail);
    }
    if (props.account.invoiceEmail) {
      setNewInvoiceEmail(props.account.invoiceEmail);
    }
  }, [props.account]);

  const imageHandler = (id, pickedFile, fileIsValid) => {
    if (fileIsValid === true) {
      setNewImage(pickedFile);
    }
  };

  const deleteAccountHandler = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    };

    const responseData = await fetch(
      process.env.REACT_APP_ASSET_URL + "/bol/credentials/" + account._id,
      requestOptions
    );
    if (responseData.ok) {
      setUpdateAccountSuccess("Bol.com account verwijderd");
    } else {
      let error = await responseData.json();
      setUpdateAccountSuccess(false);
      setErrorMessage(error.message);
    }
  };

  const updateBolAccount = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      if (newAccountSaleNumber && newAccountSaleNumber !== "") {
        formData.append("accountSaleNumber", newAccountSaleNumber);
      }
      if (newAccountId && newAccountId !== "") {
        formData.append("accountId", newAccountId);
      }
      if (newAccountSecret && newAccountSecret !== "") {
        formData.append("accountSecret", newAccountSecret);
      }
      if (newAddress && newAddress !== "") {
        formData.append("address", newAddress);
      }
      if (newBtw && newBtw !== "") {
        formData.append("btw", newBtw);
      }
      if (newCity && newCity !== "") {
        formData.append("city", newCity);
      }
      if (newCountry && newCountry !== "") {
        formData.append("country", newCountry);
      }
      if (newHouseNumber && newHouseNumber !== "") {
        formData.append("houseNumber", newHouseNumber);
      }
      if (newIban && newIban !== "") {
        formData.append("iban", newIban);
      }
      if (newKvk && newKvk !== "") {
        formData.append("kvk", newKvk);
      }
      if (newPhone && newPhone !== "") {
        formData.append("phone", newPhone);
      }
      if (newZipCode && newZipCode !== "") {
        formData.append("zipCode", newZipCode);
      }
      if (newEmail && newEmail !== "") {
        formData.append("email", newEmail);
      }
      formData.append("invoiceEmail", newInvoiceEmail);

      formData.append("reviewEmail", newReviewEmail);

      if (newImage) {
        formData.append("image", newImage);
        formData.append("oldImage", account.image);
        formData.append("imageAccount", account.accountName);
      }
      if (newAccountName && newAccountName !== "") {
        formData.append("accountName", newAccountName);
        formData.append("oldAccountName", account.accountName);
        let oldImageStr = account.image;
        let newImageStr = oldImageStr.replace(
          account.accountName,
          newAccountName
        );
        formData.append("image", newImageStr);
      }

      const requestOptions = {
        method: "PATCH",
        body: formData,
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      };

      const responseData = await fetch(
        process.env.REACT_APP_ASSET_URL + "/bol/credentials/" + account._id,
        requestOptions
      );
      if (responseData.ok) {
        setUpdateAccountSuccess("Bol.com account geüpdated");
      } else {
        let error = await responseData.json();
        setUpdateAccountSuccess(false);
        setErrorMessage(error.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      {!updateAccountSuccess && (
        <div className="edit-user-profile-container">
          <div className="subtitle-header">
            {props.account.accountName}
            {!deleteButton && (
              <div
                className="delete-user-button"
                onClick={() => {
                  setDeleteButton(true);
                }}
              >
                Bol account verwijderen?
              </div>
            )}
            {deleteButton && (
              <div className="delete-popup-container">
                <div className="delete-popup">
                  <div className="delete-popup-title">{account.title}</div>
                  <hr />
                  <div className="delete-popup-img-container">
                    {account.image ? (
                      <img
                        src={
                          process.env.REACT_APP_ASSET_URL +
                          "/s3/" +
                          account.image
                        }
                        alt=""
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="delete-popup-body-text">
                    Weet je zeker dat je dit account wilt verwijderen? Dit kan
                    niet meer ongedaan worden.
                  </div>
                  <hr />
                  <div className="delete-popup-buttons ">
                    <Button
                      onClick={() => setDeleteButton(false)}
                      className="delete-popup-button-cancel"
                    >
                      Ga terug
                    </Button>
                    <Button
                      onClick={deleteAccountHandler}
                      className="delete-popup-button-confirm"
                    >
                      Account verwijderen
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="edit-user-profile">
            <Form
              className="register-user-form edit-box"
              onSubmit={updateBolAccount}
            >
              <div
                className={`errorMessage${!errorMessage ? " disabled" : ""}`}
              >
                {errorMessage}
              </div>
              <Form.Group className="form-group-image">
                <ImageUpload
                  accountImage={account.image}
                  center
                  id="image"
                  onInput={imageHandler}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Account Bol API ID</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="*********"
                  onChange={(e) => setNewAccountId(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Account Bol API Secret</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="*********"
                  onChange={(e) => setNewAccountSecret(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Accountnaam</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.accountName}
                  onChange={(e) => setNewAccountName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Verkoopnummer</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.accountSaleNumber}
                  onChange={(e) => setNewAccountSaleNumber(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Adres</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.address}
                  onChange={(e) => setNewAddress(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>BTW nummer</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.btw}
                  onChange={(e) => setNewBtw(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Woonplaats</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.city}
                  onChange={(e) => setNewCity(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Land</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.country}
                  onChange={(e) => setNewCountry(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.email}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Huisnummer</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.houseNumber}
                  onChange={(e) => setNewHouseNumber(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>IBAN nummer</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.iban}
                  onChange={(e) => setNewIban(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>KVK nummer</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.kvk}
                  onChange={(e) => setNewKvk(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Telefoonnummer</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.phone}
                  onChange={(e) => setNewPhone(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={account.zipCode}
                  onChange={(e) => setNewZipcode(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Facturen automatisch mailen?</Form.Label>
                <div className="c-o-invoiceEmail-toggler">
                  <div className="c-o-invoiceEmail-toggler-button">
                    <div
                      onClick={() => {
                        props.account.invoiceEmail = true;
                        setNewInvoiceEmail(true);
                      }}
                      className={`c-o-invoiceEmail-toggler-button-left${
                        newInvoiceEmail
                          ? " c-o-invoiceEmail-toggler-button-active"
                          : ""
                      }`}
                    >
                      Ja
                    </div>
                    <div
                      onClick={() => {
                        props.account.invoiceEmail = false;
                        setNewInvoiceEmail(false);
                        setNewReviewEmail(false);
                      }}
                      className={`c-o-invoiceEmail-toggler-button-right${
                        !newInvoiceEmail
                          ? " c-o-invoiceEmail-toggler-button-active"
                          : ""
                      }`}
                    >
                      Nee
                    </div>
                  </div>
                </div>
              </Form.Group>
              {newInvoiceEmail && (
                <Form.Group className="form-group">
                  <Form.Label>Reviewverzoeken automatisch mailen?</Form.Label>
                  <div className="c-o-reviewEmail-toggler">
                    <div className="c-o-reviewEmail-toggler-button">
                      <div
                        onClick={() => {
                          props.account.reviewEmail = true;
                          setNewReviewEmail(true);
                        }}
                        className={`c-o-reviewEmail-toggler-button-left${
                          newReviewEmail
                            ? " c-o-reviewEmail-toggler-button-active"
                            : ""
                        }`}
                      >
                        Ja
                      </div>
                      <div
                        onClick={() => {
                          props.account.reviewEmail = false;
                          setNewReviewEmail(false);
                        }}
                        className={`c-o-reviewEmail-toggler-button-right${
                          !newReviewEmail
                            ? " c-o-reviewEmail-toggler-button-active"
                            : ""
                        }`}
                      >
                        Nee
                      </div>
                    </div>
                  </div>
                </Form.Group>
              )}
              <Button
                className="form-group-confirm-button"
                variant="primary"
                type="submit"
                disabled=""
              >
                Opslaan
              </Button>
            </Form>
          </div>
        </div>
      )}
      {updateAccountSuccess && (
        <div className="update-feedback-container">
          <h2>{updateAccountSuccess}</h2>
          <div className="subtitle-feedback">
            Terug naar bol.com account overzicht?
          </div>
          <Button>
            <a href="/bol-accounts">Alle bol accounts</a>
          </Button>
        </div>
      )}
      {!updateAccountSuccess && !account && (
        <div>Het account kon niet worden geladen</div>
      )}
    </React.Fragment>
  );
};

export default BolEditAccount;
