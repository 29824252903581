import React, { useState, useContext, useEffect } from "react";
import { Nav, NavDropdown, Button } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { AuthContext } from "../../context/main/auth-context";
import "../../css/orders/header.css";

import countAllOpenOrdersDbrFbr from "../../components/bol/count-all-open-orders-fbr-dbr";
import countAllOpenOrdersVvb from "../bol/count-all-open-orders-fbr-dbb";
import countAllOpenOrdersManualAdded from "../../components/orders/count-all-open-orders-manual-added";
import countAllOpenOrdersWoocommerce from "../../components/woocommerce/count-all-open-orders-woocommerce";
import countAllOpenOrdersShopify from "../../components/shopify/count-all-open-orders-shopify";

const OrdersHeader = (props) => {
  const auth = useContext(AuthContext);
  const [allOpenOrdersCount, setAllOpenOrdersCount] = useState(0);
  const [allOpenOrdersCountVvb, setAllOpenOrdersCountVvb] = useState(0);
  const [allOpenOrdersCountManualAdded, setAllOpenOrdersCountManualAdded] =
    useState(0);
  const [allOpenOrdersCountWoocommerce, setAllOpenOrdersCountWoocommerce] =
    useState(0);
  const [allOpenOrdersCountShopify, setAllOpenOrdersCountShopify] = useState(0);

  useEffect(() => {
    (async () => {
      let openOrders = await countAllOpenOrdersDbrFbr(auth.token);
      if (
        openOrders &&
        openOrders.ordersCount &&
        openOrders.ordersCount !== null
      ) {
        setAllOpenOrdersCount(openOrders.ordersCount);
      }
      let openOrdersVvb = await countAllOpenOrdersVvb(auth.token);
      if (
        openOrdersVvb &&
        openOrdersVvb.ordersCount &&
        openOrdersVvb.ordersCount !== null
      ) {
        setAllOpenOrdersCountVvb(openOrdersVvb.ordersCount);
      }
      let openOrdersManualAdded = await countAllOpenOrdersManualAdded(
        auth.token
      );
      if (
        openOrdersManualAdded &&
        openOrdersManualAdded.ordersCount &&
        openOrdersManualAdded.ordersCount !== null
      ) {
        setAllOpenOrdersCountManualAdded(openOrdersManualAdded.ordersCount);
      }
      let openOrdersWoocommerce = await countAllOpenOrdersWoocommerce(
        auth.token
      );
      if (
        openOrdersWoocommerce &&
        openOrdersWoocommerce.ordersCount &&
        openOrdersWoocommerce.ordersCount !== null
      ) {
        setAllOpenOrdersCountWoocommerce(openOrdersWoocommerce.ordersCount);
      }
      let openOrdersShopify = await countAllOpenOrdersShopify(auth.token);
      if (
        openOrdersShopify &&
        openOrdersShopify.ordersCount &&
        openOrdersShopify.ordersCount !== null
      ) {
        setAllOpenOrdersCountShopify(openOrdersShopify.ordersCount);
      }
    })();
  }, [auth.token, props.recountOrders]);

  return (
    <div>
      <Nav className="orders-header-navigation">
        <NavLink className="nav-link" to={`/orders/bol/fbr/dbr`}>
          Openstaande bol.com ({allOpenOrdersCount})
        </NavLink>
        <NavLink className="nav-link" to={`/orders/bol/fbr/dbb`}>
          Verzenden via bol.com ({allOpenOrdersCountVvb})
        </NavLink>
        <NavLink className="nav-link" to={`/orders/manual-added`}>
          Nazendingen ({allOpenOrdersCountManualAdded})
        </NavLink>
        <NavLink className="nav-link" to={`/orders/woocommerce`}>
          Woocommerce ({allOpenOrdersCountWoocommerce})
        </NavLink>
        <NavLink className="nav-link" to={`/orders/shopify`}>
          Shopify ({allOpenOrdersCountShopify})
        </NavLink>
        {/*
      
        <NavLink className="nav-link" to={`/`}>
          Amazon (-)
        </NavLink> */}

        <NavLink className="nav-link" to={`/orders/shipped`}>
          Verzonden
        </NavLink>
      </Nav>
    </div>
  );
};

export default OrdersHeader;
