// Gebruiker, gehele order en orderitem te verzenden
// Assistent wordt hier opgehaald indien aanwezig
const shipOrder = async (user, order) => {
  let assistant = JSON.parse(sessionStorage.getItem("assistant"));
  if (!assistant || assistant === undefined) {
    assistant = false;
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify({
      order: order,
      user: user,
      assistant: assistant,
    }),
  };

  return await fetch(
    process.env.REACT_APP_ASSET_URL + `/shopify/orders/ship-order`,
    requestOptions
  ).then(async (response) => {
    let responseJson = await response.json();
    return { response, responseJson };
  });
};

export default shipOrder;
