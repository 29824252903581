const getShippingLabelPdf = async (account, token, shippingLabelId) => {
	let accountData = {
		accountName: account.accountName,
		mongoDbId: account.accountId,
		accountSaleNumber: account.accountSaleNumber,
	};

	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			responseType: "blob",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({
			account: accountData,
			shippingLabelId: shippingLabelId,
		}),
	};

	const responseData = await fetch(
		process.env.REACT_APP_ASSET_URL + `/bol/orders/get-shipping-label-pdf`,
		requestOptions
	);
	if (responseData.ok) {
		return await responseData.blob();
	} else {
		let error = await responseData.json();
		console.log(error);
		return error;
	}
};

export default getShippingLabelPdf;
