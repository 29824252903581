const UpdateDeliveryOptionFbrDbb = async (
  token,
  id,
  orderItems,
  deliveryOption
) => {
  if (token && id && orderItems && deliveryOption) {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        id: id,
        orderItems: orderItems,
        deliveryOption: deliveryOption,
      }),
    };

    const responseData = await fetch(
      process.env.REACT_APP_ASSET_URL +
        `/bol/orders/open/fbr/dbb/order/delivery-option`,
      requestOptions
    );
    if (responseData.ok) {
      return await responseData.json();
    } else {
      let error = await responseData.json();
      console.log(error);
      return error;
    }
  } else {
    return false;
  }
};

export default UpdateDeliveryOptionFbrDbb;
