import React, { useState, useContext } from "react";
import { Form, Dropdown, Button } from "react-bootstrap";

import { AuthContext } from "../../context/main/auth-context";
import "../../css/admin/admin.css";
import ImageUpload from "../../components/main/image-upload";

const RegisterBolAccountForm = () => {
  const auth = useContext(AuthContext);

  const [accountName, setAccountName] = useState(false);
  const [clientID, setClientID] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  const [accountSaleNumber, setAcccountSaleNumber] = useState(false);
  const [saveAccountSuccess, setSaveAccountSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [address, setAddress] = useState(false);
  const [houseNumber, setHouseNumber] = useState(false);
  const [zipCode, setZipCode] = useState(false);
  const [city, setCity] = useState(false);
  const [country, setCountry] = useState(false);
  const [iban, setIban] = useState(false);
  const [kvk, setKvk] = useState(false);
  const [btw, setBtw] = useState(false);
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [image, setImage] = useState(null);
  const [invoiceEmail, setInvoiceEmail] = useState(true);
  const [reviewEmail, setReviewEmail] = useState(true);

  const accountNameHandler = (e) => {
    setAccountName(e.target.value);
    if (e.target.value === "") {
      setAccountName(false);
    }
  };
  const clientIDHandler = (e) => {
    setClientID(e.target.value);
    if (e.target.value === "") {
      setClientID(false);
    }
  };
  const clientSecretHandler = (e) => {
    setClientSecret(e.target.value);
    if (e.target.value === "") {
      setClientSecret(false);
    }
  };
  const accountSaleNumberHandler = (e) => {
    setAcccountSaleNumber(e.target.value);
    if (e.target.value === "") {
      setAcccountSaleNumber(false);
    }
  };
  const zipCodeHandler = (e) => {
    setZipCode(e.target.value);
    if (e.target.value === "") {
      setZipCode(false);
    }
  };
  const addresssHandler = (e) => {
    setAddress(e.target.value);
    if (e.target.value === "") {
      setAddress(false);
    }
  };
  const houseNumberHandler = (e) => {
    setHouseNumber(e.target.value);
    if (e.target.value === "") {
      setHouseNumber(false);
    }
  };
  const cityHandler = (e) => {
    setCity(e.target.value);
    if (e.target.value === "") {
      setCity(false);
    }
  };
  const countryHandler = (e) => {
    setCountry(e.target.value);
    if (e.target.value === "") {
      setCountry(false);
    }
  };
  const ibanHandler = (e) => {
    setIban(e.target.value);
    if (e.target.value === "") {
      setIban(false);
    }
  };
  const kvkHandler = (e) => {
    setKvk(e.target.value);
    if (e.target.value === "") {
      setKvk(false);
    }
  };
  const btwHandler = (e) => {
    setBtw(e.target.value);
    if (e.target.value === "") {
      setBtw(false);
    }
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
    if (e.target.value === "") {
      setPhone(false);
    }
  };
  const emailHandler = (e) => {
    setEmail(e.target.value);
    if (e.target.value === "") {
      setEmail(false);
    }
  };
  const imageHandler = (id, pickedFile, fileIsValid) => {
    if (fileIsValid === true) {
      setImage(pickedFile);
    }
  };

  const accountSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("accountName", accountName);
      formData.append("accountId", clientID);
      formData.append("accountSecret", clientSecret);
      formData.append("accountSaleNumber", accountSaleNumber);
      formData.append("address", address);
      formData.append("houseNumber", houseNumber);
      formData.append("zipCode", zipCode);
      formData.append("city", city);
      formData.append("country", country);
      formData.append("iban", iban);
      formData.append("kvk", kvk);
      formData.append("btw", btw);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("image", image);
      formData.append("reviewEmail", reviewEmail);
      formData.append("invoiceEmail", invoiceEmail);

      const requestOptions = {
        body: formData,
        method: "POST",
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      };

      const responseData = await fetch(
        process.env.REACT_APP_ASSET_URL + "/bol/credentials/save-credentials",
        requestOptions
      );
      if (responseData.ok) {
        setSaveAccountSuccess(true);
      } else {
        let error = await responseData.json();
        setSaveAccountSuccess(false);
        setErrorMessage(error.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!saveAccountSuccess) {
    return (
      <React.Fragment>
        <div className="title-header">Koppel bol account</div>
        <Form
          className="register-user-form login-box"
          onSubmit={accountSubmitHandler}
        >
          <div className={`errorMessage${!errorMessage ? " disabled" : ""}`}>
            {errorMessage}
          </div>
          <Form.Group className="form-group">
            <Form.Label>Accountnaam</Form.Label>
            <Form.Control
              className={accountName !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={accountNameHandler}
              onChange={accountNameHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Client ID</Form.Label>
            <Form.Control
              className={clientID !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={clientIDHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Client Secret</Form.Label>
            <Form.Control
              className={clientSecret !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={clientSecretHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Bol verkoopnummer</Form.Label>
            <Form.Control
              className={
                accountSaleNumber !== false ? "form-control-filled" : ""
              }
              type="text"
              placeholder=""
              onInput={accountSaleNumberHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Adres</Form.Label>
            <Form.Control
              className={address !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={addresssHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Huisnummer</Form.Label>
            <Form.Control
              className={houseNumber !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={houseNumberHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Postcode</Form.Label>
            <Form.Control
              className={zipCode !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={zipCodeHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Woonplaats</Form.Label>
            <Form.Control
              className={city !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={cityHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Land</Form.Label>
            <Form.Control
              className={country !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={countryHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>IBAN nummer</Form.Label>
            <Form.Control
              className={iban !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={ibanHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>KVK nummer</Form.Label>
            <Form.Control
              className={kvk !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={kvkHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>BTW nummer</Form.Label>
            <Form.Control
              className={btw !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={btwHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Telefoonnummer</Form.Label>
            <Form.Control
              className={phone !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={phoneHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              className={email !== false ? "form-control-filled" : ""}
              type="text"
              placeholder=""
              onInput={emailHandler}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Facturen automatisch mailen?</Form.Label>
            <div className="c-o-reviewEmail-toggler">
              <div className="c-o-reviewEmail-toggler-button">
                <div
                  onClick={() => {
                    setInvoiceEmail(true);
                  }}
                  className={`c-o-reviewEmail-toggler-button-left${
                    invoiceEmail ? " c-o-reviewEmail-toggler-button-active" : ""
                  }`}
                >
                  Ja
                </div>
                <div
                  onClick={() => {
                    setInvoiceEmail(false);
                    setReviewEmail(false);
                  }}
                  className={`c-o-reviewEmail-toggler-button-right${
                    !invoiceEmail
                      ? " c-o-reviewEmail-toggler-button-active"
                      : ""
                  }`}
                >
                  Nee
                </div>
              </div>
            </div>
          </Form.Group>
          {invoiceEmail && (
            <Form.Group className="form-group">
              <Form.Label>Reviewverzoeken automatisch mailen?</Form.Label>
              <div className="c-o-reviewEmail-toggler">
                <div className="c-o-reviewEmail-toggler-button">
                  <div
                    onClick={() => {
                      setReviewEmail(true);
                    }}
                    className={`c-o-reviewEmail-toggler-button-left${
                      reviewEmail
                        ? " c-o-reviewEmail-toggler-button-active"
                        : ""
                    }`}
                  >
                    Ja
                  </div>
                  <div
                    onClick={() => {
                      setReviewEmail(false);
                    }}
                    className={`c-o-reviewEmail-toggler-button-right${
                      !reviewEmail
                        ? " c-o-reviewEmail-toggler-button-active"
                        : ""
                    }`}
                  >
                    Nee
                  </div>
                </div>
              </div>
            </Form.Group>
          )}

          <Form.Group className="form-group-image">
            <ImageUpload center id="image" onInput={imageHandler} />
          </Form.Group>
          <Button
            className="form-group-confirm-button"
            variant="primary"
            type="submit"
            disabled={
              !accountName ||
              !clientID ||
              !clientSecret ||
              !accountSaleNumber ||
              !image ||
              !address ||
              !houseNumber ||
              !zipCode ||
              !city ||
              !country ||
              !kvk ||
              !btw ||
              !email ||
              !phone ||
              !reviewEmail ||
              !invoiceEmail
            }
          >
            TOEVOEGEN
          </Button>
        </Form>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="update-feedback-container bol-accounts">
          <h2>Successvol bol account opgeslagen in database.</h2>
          <div className="subtitle-feedback">
            Terug naar accounts overzicht?
          </div>
          <Button>
            <a href="/bol-accounts">Alle accounts</a>
          </Button>
          <div className="subtitle-feedback">
            Nog een bol.com account toevoegen?
          </div>
          <Button>
            <a href="/register-bol-account">Account toevoegen</a>
          </Button>
        </div>
      </React.Fragment>
    );
  }
};

export default RegisterBolAccountForm;
