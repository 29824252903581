const getAllOpenOrdersFbrDbb = async (
	token,
	sorting,
	accountFilter,
	dateFilter
) => {
	if (token && sorting && accountFilter) {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				sorting: sorting,
				accountFilter: accountFilter,
				dateFilter: dateFilter,
			}),
		};

		const responseData = await fetch(
			process.env.REACT_APP_ASSET_URL + `/bol/orders/open/fbr/dbb`,
			requestOptions
		);
		if (responseData.ok) {
			return await responseData.json();
		} else {
			let error = await responseData.json();
			console.log(error);
			return error;
		}
	}
};

export default getAllOpenOrdersFbrDbb;
