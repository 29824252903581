import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { RotateLoader } from "react-spinners";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";

import { AuthContext } from "../../context/main/auth-context";
import "../../css/orders/orders.css";

import OrdersHeader from "../../components/orders/orders-header";
import getProductBySku from "../../api/mongodb/get-product-by-sku";
import OrderListItemsBolShipStep3 from "../../components/bol/order-list-item-bol-ship-step-3";
import AssistantToggleButton from "../../components/main/assistant-toggle-button";
import { PDFDocument } from "pdf-lib";
import { useReactToPrint } from 'react-to-print';

import {
  DymoPrintersList,
  printLabels,
} from "../../components/dymo/printHandler";

const OrdersFbrDbr = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState(false);
  const [labelsPDF, setLabelsPDF] = useState(false);
  const [mergedPdfBase64, setMergedPdfBase64] = useState(false);
  const [orderActionsAvailable, setOrderActionsAvailable] = useState(false);

  let printers = DymoPrintersList();
  const [currentPrinter, setCurrentPrinter] = useState(false);
  const [twinTurboRoll, setTwinTurboRoll] = useState("Left");
  const [currentLabel, setCurrentLabel] = useState("label99010");

  useEffect(() => {
    (async () => {
      setLoadingState("Verzendoverzicht wordt geladen");

      let labelsPDFProp = JSON.parse(
        sessionStorage.getItem("labelsPDF")
      );

      setLabelsPDF(labelsPDFProp)

      let checkedOrdersProp = JSON.parse(
        sessionStorage.getItem("checkedOrders")
      );
      let shippingMethodProp = JSON.parse(
        sessionStorage.getItem("shippingMethod")
      );

      setCheckedOrders(checkedOrdersProp);

      if (
        checkedOrdersProp &&
        checkedOrdersProp.length > 0 &&
        shippingMethodProp
      ) {
        setOrderActionsAvailable(true);
      }

      setLoadingState(false);
    })();
  }, []);

  useEffect(() => {
    if (printers && printers.length > 0) {
      printers.forEach((printer) => {
        if (printer.modelName === "DYMO LabelWriter 450 Twin Turbo") {
          console.log(printer);
          setCurrentPrinter(printer);
        } else {
          setCurrentPrinter(printers[0]);
        }
      });
    }
  }, [printers]);

  const handlePrinterChoice = (printer) => {
    setCurrentPrinter(printer);
  };

  const handleTwinTurboRollChoice = (twinTurboRoll) => {
    setTwinTurboRoll(twinTurboRoll);
  };

  const handleLabelChoice = (label) => {
    if (label === "label99010") {
      setCurrentLabel("label99010");
    } else if (label === "label11352") {
      setCurrentLabel("label11352");
    }
  };

const uint8ArrayToBase64 = (bytes) => {
  let binary = '';
  const chunkSize = 8192; // Process in 8KB chunks
  for (let i = 0; i < bytes.length; i += chunkSize) {
      const chunk = bytes.subarray(i, i + chunkSize);
      binary += String.fromCharCode(...chunk);
  }
  return btoa(binary);
}

const mergePDFs = async () => {
  const mergedPDF = await PDFDocument.create();
  let base64PDFs = [];

  if (labelsPDF && labelsPDF.ResponseShipments && labelsPDF.ResponseShipments.length > 0) {
      labelsPDF.ResponseShipments.forEach((shipment) => {
          if (shipment.Labels && shipment.Labels.length > 0) {
              shipment.Labels.forEach((label) => {
                  base64PDFs.push(label.Content);
              });
          }
      });
  }

  for (const base64PDF of base64PDFs) {
      const pdfBytes = Uint8Array.from(atob(base64PDF), c => c.charCodeAt(0));
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPDF.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach(page => mergedPDF.addPage(page));
  }

  // Save the merged PDF and convert it to base64 in chunks
  const mergedPDFBytes = await mergedPDF.save();
  const mergedPDFBase64 = uint8ArrayToBase64(new Uint8Array(mergedPDFBytes));

  openPdfInNewTab(mergedPDFBase64);
}

const handlePrint = async () => {

  const mergedPDF = await PDFDocument.create();
  let base64PDFs = [];

  if (labelsPDF && labelsPDF.ResponseShipments && labelsPDF.ResponseShipments.length > 0) {
      labelsPDF.ResponseShipments.forEach((shipment) => {
          if (shipment.Labels && shipment.Labels.length > 0) {
              shipment.Labels.forEach((label) => {
                  base64PDFs.push(label.Content);
              });
          }
      });
  }

  for (const base64PDF of base64PDFs) {
      const pdfBytes = Uint8Array.from(atob(base64PDF), c => c.charCodeAt(0));
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPDF.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach(page => mergedPDF.addPage(page));
  }

  // Save the merged PDF and convert it to base64 in chunks
  const mergedPDFBytes = await mergedPDF.save();
  const mergedPDFBase64 = uint8ArrayToBase64(new Uint8Array(mergedPDFBytes));


  // Step 1: Convert base64 to a Blob
  const byteCharacters = atob(mergedPDFBase64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // Step 2: Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Step 3: Open the PDF in a new tab and trigger print
  const newWindow = window.open(url);
  if (newWindow) {
    newWindow.onload = () => {
      newWindow.focus();
      newWindow.print();
    };
  } else {
    alert("Please allow popups for this website.");
  }
};


function openPdfInNewTab(base64Data) {
  // Convert base64 to ArrayBuffer
  var arrBuffer = base64ToArrayBuffer(base64Data);
  
  // Create a Blob from the ArrayBuffer
  var newBlob = new Blob([arrBuffer], { type: "application/pdf" });
  
  // Check for MS Edge or Internet Explorer (they handle Blob differently)
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // Create a URL from the Blob
  var data = window.URL.createObjectURL(newBlob);

  // Open the PDF in a new tab
  window.open(data);

  // Optional: Clean up the URL after the PDF is opened
  setTimeout(function() {
    window.URL.revokeObjectURL(data);
  }, 100);
}



const base64ToArrayBuffer = (data) => {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};


  return (
    <React.Fragment>
      <div className="title-header title-header-ship-orders">
        <span>Bestellingen verzonden</span>
        <div className="shipmentSteps">
          <div className="shipmentOneStep shipmentFirstStep finished">
            <div className="shipmentOneStepCircle">1</div>
            <div className="">Verzendmethode</div>
          </div>
          <div className="shipmentStepsLine"></div>
          <div className="shipmentOneStep finished">
            <div className="shipmentOneStepCircle">2</div>
            <div className="">Overzicht</div>
          </div>
          <div className="shipmentStepsLine"></div>
          <div className="shipmentOneStep finished">
            <div className="shipmentOneStepCircle">3</div>
            <div className="">Bevestiging</div>
          </div>
        </div>
      </div>
      {checkedOrders && (
        <div className="orders-container-header ship-orders-container-header">
          {checkedOrders && checkedOrders.length > 0 && (
            <div className="orders-container-header-actions ship-orders-container-header-actions shipped-orders-container-header-actions">
              {/* <div className="confirmedOrderPrintOptions">
                <Dropdown>
                  <Dropdown.Toggle
                    className={!currentPrinter ? "disabled" : ""}
                    variant="secondary"
                    id="dropdown-menu-align-right"
                  >
                    {currentPrinter.name || "Geen verbinding met printer"}
                  </Dropdown.Toggle>
                  {currentPrinter && (
                    <Dropdown.Menu>
                      {printers.map((printer) => {
                        return (
                          <Dropdown.Item
                            printername={printer.name}
                            value={printer.name}
                            key={printer.modelName}
                            onClick={() => handlePrinterChoice(printer)}
                          >
                            {printer.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                {currentPrinter && currentPrinter.isTwinTurbo && (
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary">
                      {(() => {
                        return twinTurboRoll === "Left"
                          ? [<BsArrowBarLeft />, "Links"]
                          : ["Rechts", <BsArrowBarRight />];
                      })()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleTwinTurboRollChoice("Left")}
                      >
                        <BsArrowBarLeft /> Links
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleTwinTurboRollChoice("Right")}
                      >
                        <BsArrowBarRight />
                        Rechts
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                <Dropdown>
                  <Dropdown.Toggle variant="secondary">
                    {currentLabel}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleLabelChoice("label99010")}
                    >
                      99010 - 89x28mm
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleLabelChoice("label11352")}
                    >
                      11352 - 54x25mm
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                disabled={!currentPrinter}
                className="printLabelsButton"
                variant="primary"
                onClick={(e) => {
                  printLabels(
                    currentPrinter,
                    currentLabel,
                    checkedOrders,
                    twinTurboRoll
                  );
                }}
              >
                Print addressen
              </Button>
              </div> */}
              <div class="labelActionsContainer">
              {/* <Button
                disabled={!labelsPDF}
                className="viewLabelsButton"
                variant="primary"
                onClick={(e) => {
                  mergePDFs(e)
                }}
              >
                Bekijk Labels
              </Button> */}
              <div class="confirmedOrderPrintOptions">
              <Button
                disabled={!labelsPDF}
                className="viewLabelsButton"
                variant="primary"
                onClick={() => {
                  handlePrint()
                }}
              >
                Print Labels
              </Button>
            </div>
            </div>
            </div>
          )}
        </div>
      )}
      {checkedOrders.length > 0 &&
        checkedOrders.map((order, index) => {
          return (
            <OrderListItemsBolShipStep3
              order={order}
              key={index}
              index={index}
            />
          );
        })}
      <div className="orders-container-footer-actions ship-orders-container-footer-actions">
        <Button
          onClick={() => {
            navigate("/orders/bol/fbr/dbr/");
            window.scrollTo(0, 0);
          }}
        >
          Terug naar bestellingen
        </Button>
        {/* <Button
          disabled={!currentPrinter}
          className="printLabelsButton"
          variant="primary"
          onClick={(e) => {
            printLabels(
              currentPrinter,
              currentLabel,
              checkedOrders,
              twinTurboRoll
            );
          }}
        >
          Print addressen
        </Button> */}
         <Button
                disabled={!labelsPDF}
                className="viewLabelsButton"
                variant="primary"
                onClick={() => {
                  handlePrint()
                }}
              >
                Print Labels
              </Button>
      </div>

      {loadingState && (
        <div className="loading-container">
          <div className="loading-container-title">{loadingState}</div>
          <RotateLoader
            size={100}
            width={100}
            height={10}
            color={"#fff"}
            loading={loadingState}
          />
        </div>
      )}
      <AssistantToggleButton />
    </React.Fragment>
  );
};

export default OrdersFbrDbr;
