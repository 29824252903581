const GetProductBySku = async (sku, token) => {
  let result = false;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  try {
    const responseData = await fetch(
      process.env.REACT_APP_ASSET_URL + `/products/sku/${sku}`,
      requestOptions
    );
    if (responseData.ok) {
      result = await responseData.json();
      return { productData: result };
    }
  } catch (err) {
    console.log(err, "error line 22, get product by sku");
    return err;
  }
};

export default GetProductBySku;
