import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [email, setEmail] = useState(false);
  const [username, setUsername] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const [surname, setSurname] = useState(false);
  const [image, setImage] = useState(false);
  const [role, setRole] = useState(false);

  const login = useCallback(
    (
      uid,
      token,
      expirationDate,
      email,
      surname,
      firstName,
      username,
      image,
      role
    ) => {
      setUserId(uid);
      setToken(token);
      setEmail(email);
      setSurname(surname);
      setFirstName(firstName);
      setUsername(username);
      setImage(image);
      setRole(role);
      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 12);
      setTokenExpirationDate(tokenExpirationDate);

      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          token: token,
          expiration: tokenExpirationDate.toISOString(),
          emai: email,
          surname: surname,
          firstName: firstName,
          username: username,
          image: image,
          role: role,
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setEmail(null);
    setSurname(null);
    setFirstName(null);
    setUsername(null);
    setImage(null);
    setRole(null);
    localStorage.removeItem("userData");
    window.location.href = "/";
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [
    token,
    logout,
    tokenExpirationDate,
    userId,
    email,
    surname,
    firstName,
    username,
    image,
    role,
  ]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        new Date(storedData.expiration),
        storedData.email,
        storedData.surname,
        storedData.firstName,
        storedData.username,
        storedData.image,
        storedData.role
      );
    }
  }, [login, email, surname, firstName, username, image, role]);

  return {
    token,
    login,
    logout,
    userId,
    email,
    surname,
    firstName,
    username,
    image,
    role,
  };
};
