import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { RotateLoader } from "react-spinners";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

import { AuthContext } from "../../context/main/auth-context";
import "../../css/orders/orders.css";

import OrdersHeader from "../../components/orders/orders-header";
import OrderListItemsShopifyOrderShipStep1 from "../../components/shopify/order-list-item-shopify-order-ship-step-1";
import AssistantToggleButton from "../../components/main/assistant-toggle-button";

const OrdersShopifyShipStep1 = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessge] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState(false);
  const [orderActionsAvailable, setOrderActionsAvailable] = useState(false);
  const [shippingMethodBulk, setShippingMethodBulk] = useState(false);
  const [newShippingMethodBulk, setNewShippingMethodBulk] = useState(false);
  const [transporterCodesBolLabels, setTransporterCodesBolLabels] =
    useState(false);
  const transporterCodes = [
    { transporterCode: "BRIEFPOST", displayName: "Briefpost" },
    { transporterCode: "UPS", displayName: "UPS" },
    { transporterCode: "TNT", displayName: "PostNL" },
    { transporterCode: "TNT-EXTRA", displayName: "PostNL Extra@Home" },
    { transporterCode: "TNT_BRIEF", displayName: "PostNL Briefpost" },
    { transporterCode: "TNT-EXPRESS", displayName: "TNT Express" },
    { transporterCode: "DHL", displayName: "DHL Parcel" },
    { transporterCode: "DYL", displayName: "Dynalogic" },
    { transporterCode: "DPD-NL", displayName: "DPD Nederland" },
    { transporterCode: "DPD-BE", displayName: "DPD België" },
    { transporterCode: "BPOST_BE", displayName: "Bpost België" },
    { transporterCode: "BPOST_BRIEF", displayName: "Bpost Briefpost" },
    { transporterCode: "DHLFORYOU", displayName: "DHLForYou" },
    { transporterCode: "GLS", displayName: "GLS" },
    { transporterCode: "FEDEX_NL", displayName: "FedEx Nederland" },
    { transporterCode: "FEDEX_BE", displayName: "FedEx België" },
    { transporterCode: "OTHER", displayName: "Anders" },
    { transporterCode: "DHL_DE", displayName: "DHL Germany" },
    {
      transporterCode: "DHL-GLOBAL-MAIL",
      displayName: "DHL Global Mail",
    },
    {
      transporterCode: "TSN",
      displayName: "Transportservice Nederland",
    },
    {
      transporterCode: "TRANSMISSION",
      displayName: "Transmission",
    },
    {
      transporterCode: "FIEGE",
      displayName: "FIEGE",
    },
    {
      transporterCode: "PARCEL-NL",
      displayName: "Parcel.nl",
    },
    {
      transporterCode: "LOGOIX",
      displayName: "LogoiX",
    },

    {
      transporterCode: "PACKS",
      displayName: "Packsacks",
    },
    {
      transporterCode: "RJP",
      displayName: "Red je pakketje",
    },
  ];

  useEffect(() => {
    (async () => {
      setLoadingState("Verzendoverzicht wordt geladen");

      let checkedOrdersProp = JSON.parse(
        sessionStorage.getItem("checkedOrders")
      );
      let shippingMethodProp = JSON.parse(
        sessionStorage.getItem("shippingMethod")
      );

      if (shippingMethodBulk) {
        checkedOrdersProp.forEach((order) => {
          console.log(shippingMethodBulk);
          if (!order.frontEndChosenDeliveryOption) {
            let transporter = transporterCodes.find(
              (tc) => tc.transporterCode === shippingMethodBulk
            );

            order.frontEndChosenDeliveryOption = {
              transporterCode: shippingMethodBulk,
              displayName:
                transporter &&
                transporter.displayName &&
                transporter.displayName,
              orderItems: [],
            };
            order.orderItems.forEach((item) => {
              if (item.checked) {
                order.frontEndChosenDeliveryOption.orderItems.push(item);
              }
            });
          }
        });
      }

      setCheckedOrders(checkedOrdersProp);
      setShippingMethodBulk(shippingMethodProp);

      if (
        checkedOrdersProp &&
        checkedOrdersProp.length > 0 &&
        shippingMethodProp
      ) {
        setOrderActionsAvailable(true);
      }

      setLoadingState(false);
    })();
  }, [shippingMethodBulk]);

  const bulkShippingMethodHandler = (params) => {
    let method = JSON.parse(params);
    let orders = [...checkedOrders];

    if (method.shippingLabelOfferId) {
      orders.forEach((order) => {
        order.deliveryOptions.map((dO) => {
          return dO.options.map((option) => {
            if (option.shippingLabelOfferId === method.shippingLabelOfferId) {
              let orderItems = order.frontEndChosenDeliveryOption.orderItems;
              order.frontEndChosenDeliveryOption = option;
              order.frontEndChosenDeliveryOption.displayName =
                method.displayName;
              order.frontEndChosenDeliveryOption.orderItems = orderItems;
            }
          });
        });
      });
    } else {
      orders.forEach((order) => {
        order.frontEndChosenDeliveryOption.displayName = method.displayName;
        order.frontEndChosenDeliveryOption.transporterCode =
          method.transporterCode;
      });
    }

    setCheckedOrders(orders);
    setNewShippingMethodBulk(method);
  };

  const shippingMethodHandler = (method, index) => {
    let updateCheckedOrders = [...checkedOrders];
    updateCheckedOrders[index].frontEndChosenDeliveryOption.transporterCode =
      method.transporterCode;
    updateCheckedOrders[index].frontEndChosenDeliveryOption.displayName =
      method.displayName;
    setCheckedOrders(updateCheckedOrders);
  };

  const confirmToStep2Handler = () => {
    setLoadingState("Bezig met verzenden van orders");
    sessionStorage.setItem("checkedOrders", JSON.stringify(checkedOrders));
    navigate("/orders/shopify/ship/step-2");
    window.scrollTo(0, 0);
  };

  const handleRemoveOrderFromChecked = (order, orderItem) => {
    let newArr = [...checkedOrders];

    let i = newArr.findIndex((o) => o.id === order.id);

    console.log(newArr);
    console.log(order);
    console.log(orderItem);
    if (i !== -1) {
      let j = newArr[i].orderItems.findIndex(
        (oItem) => oItem.variant_id === orderItem.variant_id
      );

      if (newArr[i].orderItems.length <= 1) {
        newArr.splice(i, 1);
      } else {
        if (j !== -1) {
          newArr[i].orderItems[j].checked = false;
        }

        let allUnchecked = Object.values(newArr[i].orderItems).every((item) => {
          return item.checked === false;
        });

        allUnchecked && newArr.splice(i, 1);
      }
      setCheckedOrders(newArr);
      sessionStorage.setItem("checkedOrders", JSON.stringify(newArr));
    }
  };

  return (
    <React.Fragment>
      <div className="title-header title-header-ship-orders">
        <span>Verzenden van bestellingen</span>
        <div className="shipmentSteps">
          <div className="shipmentOneStep shipmentFirstStep finished">
            <div className="shipmentOneStepCircle">1</div>
            <div className="">Verzendmethode</div>
          </div>
          <div className="shipmentStepsLine"></div>
          <div className="shipmentOneStep notActive">
            <div className="shipmentOneStepCircle">2</div>
            <div className="">Overzicht</div>
          </div>
          <div className="shipmentStepsLine"></div>
          <div className="shipmentOneStep notActive">
            <div className="shipmentOneStepCircle">3</div>
            <div className="">Bevestiging</div>
          </div>
        </div>
      </div>
      {checkedOrders && (
        <div className="orders-container-header ship-orders-container-header">
          {checkedOrders && checkedOrders.length > 0 && (
            <div className="orders-container-header-actions ship-orders-container-header-actions step-1">
              <div>Bestellingen: {checkedOrders.length}</div>
              <DropdownButton
                align="end"
                id="dropdown-menu-align-end"
                className={!orderActionsAvailable && "disable"}
                title={(() => {
                  let title = [<MdOutlineArrowDropDownCircle />];

                  if (newShippingMethodBulk) {
                    title.unshift(newShippingMethodBulk.displayName);
                  } else {
                    if (
                      shippingMethodBulk === "ALTERNATE-OPTION" ||
                      shippingMethodBulk === "BOL-SHIPPING-LABEL"
                    ) {
                      title.unshift("Kies verzendmethode");
                    } else {
                      let transporter = transporterCodes.find(
                        (tc) => tc.transporterCode === shippingMethodBulk
                      );
                      title.unshift(transporter.displayName);
                    }
                  }

                  return title;
                })()}
                disabled={(() => {
                  return orderActionsAvailable ? false : true;
                })()}
                onSelect={(e) => bulkShippingMethodHandler(e)}
              >
                {shippingMethodBulk !== "BOL-SHIPPING-LABEL" &&
                  transporterCodes.map((tc) => {
                    return (
                      <Dropdown.Item eventKey={JSON.stringify(tc)}>
                        {tc.displayName}
                      </Dropdown.Item>
                    );
                  })}
                {shippingMethodBulk === "BOL-SHIPPING-LABEL" &&
                  transporterCodesBolLabels &&
                  transporterCodesBolLabels.map((tc, i) => {
                    return (
                      <Dropdown.Item index={i} eventKey={JSON.stringify(tc)}>
                        {tc.displayName}
                      </Dropdown.Item>
                    );
                  })}
              </DropdownButton>
            </div>
          )}
        </div>
      )}
      {checkedOrders &&
        checkedOrders.length > 0 &&
        checkedOrders.map((order, index) => {
          return (
            <OrderListItemsShopifyOrderShipStep1
              order={order}
              key={index}
              index={index}
              transporterCodes={transporterCodes}
              shippingMethodBulk={shippingMethodBulk}
              handleShippingMethodChange={(method, index) => {
                shippingMethodHandler(method, index);
              }}
              handleRemoveOrderFromChecked={(orderX, orderXItem) => {
                handleRemoveOrderFromChecked(orderX, orderXItem);
              }}
            />
          );
        })}
      <div className="orders-container-footer-actions ship-orders-container-footer-actions">
        <Button
          className="previousBtn"
          onClick={() => {
            navigate("/orders/shopify");
            window.scrollTo(0, 0);
          }}
        >
          <GrLinkPrevious /> Terug naar bestellingen
        </Button>
        <Button
          className="nextBtn"
          onClick={() => {
            confirmToStep2Handler();
          }}
        >
          Volgende stap <GrLinkNext />
        </Button>
      </div>

      {loadingState && (
        <div className="loading-container">
          <div className="loading-container-title">{loadingState}</div>
          <RotateLoader
            size={100}
            width={100}
            height={10}
            color={"#fff"}
            loading={loadingState}
          />
        </div>
      )}
      <AssistantToggleButton />
    </React.Fragment>
  );
};

export default OrdersShopifyShipStep1;
