const storedData = JSON.parse(localStorage.getItem("userData"));

const cancelOrderItem = async (user, order, orderItemsArray) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify({
      orderItemsArray: orderItemsArray,
      order: order,
    }),
  };

  return await fetch(
    process.env.REACT_APP_ASSET_URL + `/bol/orders/cancel-order-item`,
    requestOptions
  ).then(async (response) => {
    console.log(response);
    let responseJson = await response.json();
    return { response, responseJson };
  });
};

export default cancelOrderItem;
