const getAllOpenOrdersWoocommerce = async (token, sortOrdersFilter) => {
  if (token && sortOrdersFilter) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        sorting: sortOrdersFilter,
      }),
    };

    const responseData = await fetch(
      process.env.REACT_APP_ASSET_URL + `/woocommerce/orders/open/`,
      requestOptions
    );
    if (responseData.ok) {
      return await responseData.json();
    } else {
      let error = await responseData.json();
      console.log(error);
      return error;
    }
  } else {
    return false;
  }
};

export default getAllOpenOrdersWoocommerce;
