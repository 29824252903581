import React, { useEffect, useState, useContext } from "react";
import {
	ProSidebar,
	SidebarHeader,
	SidebarContent,
	SidebarFooter,
	Menu,
	MenuItem,
	SubMenu,
} from "react-pro-sidebar";
import { RiMenuFoldLine, RiMenuUnfoldLine, RiAdminLine } from "react-icons/ri";
import { MdOutlineSpaceDashboard, MdManageAccounts } from "react-icons/md";
import { BsShopWindow } from "react-icons/bs";
import { CgArrowsMergeAltV } from "react-icons/cg";
import { IoPricetagsOutline } from "react-icons/io5";
import { GoInbox } from "react-icons/go";
import { VscAccount } from "react-icons/vsc";
import { AiOutlinePoweroff } from "react-icons/ai";

import "react-pro-sidebar/dist/css/styles.css";

import { AuthContext } from "../../context/main/auth-context";
import "../../css/main/sidebar.css";

import GrandGroupLogo from "../../images/main/grandgroup-logo.svg";

const Sidebar = (props) => {
	const auth = useContext(AuthContext);

	// set setCollapseSidebar(false), for standard open collapse sidebar
	const [collapseSidebar, setCollapseSidebar] = useState(true);
	const [closeSidebar, setCloseSidebar] = useState(false);
	const [accountsWithOrdersCountFbr, setAccountsWithOrdersCountFbr] =
		useState(false);
	const [allOpenOrdersCount, setAllOpenOrdersCount] = useState(false);

	useEffect(() => {
		// set setCollapseSidebar(false), for standard open collapse sidebar
		if (props.sidebarState) {
			setCloseSidebar(false);
			setCollapseSidebar(true);
		}
	}, [props.sidebarState, props.sidebarStatus]);

	useEffect(() => {
		if (props.accountsWithOrdersCountFbr) {
			setAccountsWithOrdersCountFbr(props.accountsWithOrdersCountFbr);
		}
	}, [props]);

	useEffect(() => {
		if (props.allOpenOrdersCount) {
			setAllOpenOrdersCount(props.allOpenOrdersCount);
		}
	}, [props.allOpenOrdersCount]);

	const sidebarHandler = () => {
		props.closeSidebar(false);
		setCloseSidebar(true);
		setCollapseSidebar(true);
	};

	const logoutHandler = () => {
		auth.logout();
	};

	return !closeSidebar && auth.isLoggedIn ? (
		<ProSidebar collapsed={collapseSidebar}>
			<SidebarHeader>
				{!collapseSidebar && (
					<a href='/'>
						<img className='sidebarLogo' src={GrandGroupLogo} alt='GrandGroup_Logo' />
					</a>
				)}
				{!collapseSidebar ? (
					<RiMenuFoldLine onClick={() => setCollapseSidebar(!collapseSidebar)} />
				) : (
					<RiMenuUnfoldLine onClick={() => setCollapseSidebar(!collapseSidebar)} />
				)}
			</SidebarHeader>
			<SidebarContent>
				<Menu>
					<MenuItem icon={<MdOutlineSpaceDashboard />}>
						<a href='/'>Dashboard</a>
					</MenuItem>
					<SubMenu title='Bestellingen' icon={<GoInbox />}>
						<MenuItem suffix={<span className='badge'>{allOpenOrdersCount}</span>}>
							<a href='/orders/bol/fbr/dbr'>Openstaande</a>
						</MenuItem>
						<MenuItem>
							<a href='/orders/shipped'>Verzonden</a>
						</MenuItem>
						<MenuItem>
							<a href='/orders/create-order'>Nazending maken</a>
						</MenuItem>
					</SubMenu>
					<SubMenu title='Bol.com' icon={<BsShopWindow />}>
						{accountsWithOrdersCountFbr.map((accountWithOrderCount, i) => {
							return (
								<MenuItem
									onClick={(e) => {
										console.log(e);
										console.log(accountWithOrderCount);
										sessionStorage.setItem(
											"openOrdersFilterByAccount",
											JSON.stringify(accountWithOrderCount.account.accountSaleNumber)
										);
									}}
									key={i}
									suffix={
										<span className='badge'>
											{accountWithOrderCount.openOrdersCountFbr}
										</span>
									}
								>
									<a href='/orders/bol/fbr/dbr'>
										{accountWithOrderCount.account.accountName}
									</a>
								</MenuItem>
							);
						})}
					</SubMenu>
					<SubMenu title='Voorraadbeheer' icon={<IoPricetagsOutline />}>
						<SubMenu title='Artikelen'>
							<MenuItem>
								<a href='/products'>Alle artikelen</a>
							</MenuItem>
							{auth.role >= 2 && (
								<>
									<MenuItem>
										<a href='/register-product'>Artikel Toevoegen</a>
									</MenuItem>
									<MenuItem>
										<a href='/products/bulk-editor-v2'>Bulk editor</a>
									</MenuItem>
									{/* <MenuItem>
                    <a href="/products/bulk-editor-v1">Bulk editor V1</a>
                  </MenuItem> */}
								</>
							)}
						</SubMenu>
						<SubMenu title='Categorieën'>
							<MenuItem>
								<a href='/categories'>Alle categorieën</a>
							</MenuItem>
							{auth.role >= 2 && (
								<MenuItem>
									<a href='/register-category'>Categorie Toevoegen</a>
								</MenuItem>
							)}
						</SubMenu>
					</SubMenu>
					{auth.role >= 5 && (
						<Menu>
							<SubMenu title='Admin' icon={<RiAdminLine />}>
								<SubMenu title='Medewerkers'>
									<MenuItem>
										<a href='/users'>Alle medewerkers</a>
									</MenuItem>
									<MenuItem>
										<a href='/register-user'>Medewerker Toevoegen</a>
									</MenuItem>
								</SubMenu>
								<SubMenu title='Bol Accounts'>
									<MenuItem>
										<a href='/bol-accounts'>Alle accounts</a>
									</MenuItem>
									<MenuItem>
										<a href='/register-bol-account'>account Toevoegen</a>
									</MenuItem>
								</SubMenu>
							</SubMenu>
						</Menu>
					)}
					<Menu>
						<SubMenu title='Mijn account' icon={<VscAccount />}>
							<MenuItem icon={<MdManageAccounts />}>
								<a href='/user-profile'>Mijn profiel</a>
							</MenuItem>
							<MenuItem
								title='Uitloggen'
								onClick={() => logoutHandler()}
								icon={<AiOutlinePoweroff />}
							>
								Uitloggen
							</MenuItem>
						</SubMenu>
					</Menu>
				</Menu>
			</SidebarContent>
			<SidebarFooter style={{ textAlign: "center" }}>
				<div
					className='sidebar-btn-wrapper'
					style={{
						padding: "20px 24px",
					}}
				>
					<Menu>
						{!closeSidebar && (
							<MenuItem onClick={() => sidebarHandler()} icon={<CgArrowsMergeAltV />}>
								SLUIT MENU
							</MenuItem>
						)}
					</Menu>
				</div>
			</SidebarFooter>
		</ProSidebar>
	) : (
		<div className='pro-sidebar-hidden'></div>
	);
};

export default Sidebar;
