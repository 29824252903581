import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

import cancelOrderItem from "./cancel-order-item";
import "../../css/orders/cancel-order-item-modal.css";

const submitCancellationOrderItem = async (props) => {
  let orderItemsArray = [
    {
      orderItemId: props.cancelData.orderItem.orderItemId,
      reasonCode: "REQUESTED_BY_CUSTOMER",
    },
  ];
  let result = await cancelOrderItem(
    props.cancelData.auth,
    props.cancelData.order,
    orderItemsArray
  );

  if (result.response.status === 200 || result.response.status === 202) {
    props.cancelResponseHandler(
      202,
      props.cancelData.order,
      props.cancelData.orderItem
    );
  } else {
    props.cancelResponseHandler(400);
  }
};

const cancelOrderItemModal = (props) => {
  return (
    <div className="cancelOrderItemModal">
      <div
        className="closeIcon"
        onClick={() => {
          props.closeCancelOrderItemModal(false);
        }}
      >
        <FaTimes />
      </div>
      <div className="cancelOrderItemModalContainer">
        <div className="cancelOrderItemModalTitle">
          Bestelling {props.cancelData.order.orderId} annuleren?
        </div>
        <div>
          <div>Bestelnummer</div>
          <div>{props.cancelData.order.orderId}</div>
        </div>
        <div>
          <div> Naam:</div>
          <div>
            {props.cancelData.order.billingDetails.firstName}{" "}
            {props.cancelData.order.billingDetails.surname}
          </div>
        </div>
        <div>
          <div>Straat: </div>
          <div>
            {props.cancelData.order.billingDetails.streetName}{" "}
            {props.cancelData.order.billingDetails.houseNumber}
            {props.cancelData.order.billingDetails.houseNumberExtension}
          </div>
        </div>
        <div>
          <div>Postcode</div>
          <div>{props.cancelData.order.billingDetails.zipCode}</div>
        </div>
        <div>
          <div>Stad</div>
          <div>{props.cancelData.order.billingDetails.city}</div>
        </div>
        <div>
          <div>EAN: </div>
          <div>{props.cancelData.orderItem.product.ean} </div>
        </div>
        <div>
          <div>Artikel: </div>
          <div>{props.cancelData.orderItem.product.title}</div>
        </div>
        <div>
          <div>Referentie:</div>
          <div>{props.cancelData.orderItem.offer.reference}</div>
        </div>
        <div>
          <div>Aantal:</div>
          <div>{props.cancelData.orderItem.quantity}</div>
        </div>

        <Button
          onClick={() => {
            props.loadingStateCancel();
            submitCancellationOrderItem(props);
          }}
        >
          Bevestig annulering
        </Button>
      </div>
    </div>
  );
};

export default cancelOrderItemModal;
