import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { RotateLoader } from "react-spinners";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

import { AuthContext } from "../../context/main/auth-context";
import "../../css/orders/orders.css";

import OrdersHeader from "../../components/orders/orders-header";
import getProductBySku from "../../api/mongodb/get-product-by-sku";
import OrderListItemWooOrderShipStep2 from "../../components/woocommerce/order-list-item-woo-order-ship-step-2";
import AssistantToggleButton from "../../components/main/assistant-toggle-button";
import shipWoocommerceOrder from "../../components/woocommerce/ship-order";

const OrdersWoocommerceShipStep2 = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  console.log(auth);

  const [errorMessage, setErrorMessge] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState(false);
  const [orderActionsAvailable, setOrderActionsAvailable] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingState("Verzendoverzicht wordt geladen");
      let checkedOrdersProp = JSON.parse(
        sessionStorage.getItem("checkedOrders")
      );
      let transporterCodesProp = JSON.parse(
        sessionStorage.getItem("transporterCodes")
      );
      setCheckedOrders(checkedOrdersProp);
      setLoadingState(false);
    })();
  }, []);

  const returnToStep1Handler = () => {
    navigate("/orders/woocommerce/ship/step-1");
    window.scrollTo(0, 0);
  };
  const confirmToStep3Handler = async () => {
    let handledOrderItems = 0;
    let orderItems = 0;
    checkedOrders.forEach((order) => {
      orderItems += order.frontEndChosenDeliveryOption.orderItems.length;
    });

    setLoadingState(`${handledOrderItems}/${orderItems} Bestelregels verwerkt`);

    for (let order of checkedOrders) {
      for (let orderItem of order.frontEndChosenDeliveryOption.orderItems) {
        orderItem.shippedSuccesful = false;
        let res = await shipWoocommerceOrder(auth, order);
        console.log(res);
        if (res.response.status === 202) {
          orderItem.shippedSuccesful = true;
          handledOrderItems += 1;
          setLoadingState(
            `${handledOrderItems}/${orderItems} Bestelregels verwerkt`
          );
        }
      }
    }

    navigate("/orders/woocommerce/ship/step-3");
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <div className="title-header title-header-ship-orders">
        <span>Verzenden van bestellingen</span>
        <div className="shipmentSteps">
          <div className="shipmentOneStep shipmentFirstSte finished">
            <div className="shipmentOneStepCircle">1</div>
            <div className="">Verzendmethode</div>
          </div>
          <div className="shipmentStepsLine"></div>
          <div className="shipmentOneStep finished">
            <div className="shipmentOneStepCircle">2</div>
            <div className="">Overzicht</div>
          </div>
          <div className="shipmentStepsLine"></div>
          <div className="shipmentOneStep notActive">
            <div className="shipmentOneStepCircle">3</div>
            <div className="">Bevestiging</div>
          </div>
        </div>
      </div>
      {checkedOrders && (
        <div className="orders-container-header ship-orders-container-header">
          {checkedOrders && checkedOrders.length > 0 && (
            <div className="orders-container-header-actions ship-orders-container-header-actions">
              Overzicht
            </div>
          )}
        </div>
      )}
      {checkedOrders.length > 0 &&
        checkedOrders.map((order, index) => {
          return (
            <OrderListItemWooOrderShipStep2
              order={order}
              key={index}
              index={index}
            />
          );
        })}
      <div className="orders-container-footer-actions ship-orders-container-footer-actions step2">
        <Button
          className="previousBtn"
          onClick={() => {
            returnToStep1Handler();
          }}
        >
          <GrLinkPrevious />
          Vorige stap
        </Button>
        <Button
          className="nextBtn"
          onClick={() => {
            confirmToStep3Handler();
          }}
        >
          Verwerken <GrLinkNext />
        </Button>
      </div>

      {loadingState && (
        <div className="loading-container">
          <div className="loading-container-title">{loadingState}</div>
          <RotateLoader
            size={100}
            width={100}
            height={10}
            color={"#fff"}
            loading={loadingState}
          />
        </div>
      )}
      <AssistantToggleButton />
    </React.Fragment>
  );
};

export default OrdersWoocommerceShipStep2;
