const refetchAllOpenOrders = async (token, query) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const responseData = await fetch(
    process.env.REACT_APP_ASSET_URL + `/orders/open/refetch`,
    requestOptions
  );
  if (responseData.ok) {
    return await responseData.json();
  } else {
    let error = await responseData.json();
    console.log(error);
    return error;
  }
};

export default refetchAllOpenOrders;
